<template>
  <div class="union-pages">
    <!-- :class="pFixed ? 'poflexd' : ''" -->
    <!-- <Header :startWidth="80" :startTop="50" :currentRouter="'/unionproduct'"></Header> -->

    <div class="header-img">
      <img v-if="banner_list.length > 0" :src="banner_list.length > 0 ? banner_list[0].ads_pic : ''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="header-search">
      <img :src="require('@/assets/search.png')" style="width:26px;height:26px;margin-left:10px;" />
      <input type="text" v-model="pages.search" placeholder="请输入要查询的内容" />
      <span @click="searchs">搜索</span>
    </div>

    <div class="lunbo">
      <!-- <img class="imgbtn" @click="turnLeft" :src="require('@/assets/newImg/s2.jpg')" alt /> -->
      <div class="lunbo-container" ref="imgBox">
        <div class="lunbo-container-box" v-for="(item, index) in typeList" :key="index"
          :style="{ marginLeft: index == 0 ? marginLeft + 'px' : '' }">
          <div class="lunbo-container-img-box">
            <img class="lunbo-container-img" :class="current === index ? 'checkedTabsImg' : ''" :src="item.imgs" alt
              @click="changeTabs(item, index)" />
          </div>
          <div>
            <span class="lunbo-container-title">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- <img class="imgbtn" @click="turnRight" :src="require('@/assets/newImg/s3.jpg')" alt /> -->
    </div>
    <div class="product-right" style="margin-top:50px">
      <div class="model-box-son2" v-for="(item, index) in product_listData" :key="index">
        <div class="model-box-left-img">
          <img :src="item.imgs" alt style="width: 100%;display: block" />
          <div class="mask">
            <div class="model-box-left-detail">
              <span @click="toDetail(item)">
                <img style="width:30px;height:30px" src="@/assets/newImg/aies.png" alt />了解更多
              </span>
            </div>
          </div>
        </div>

        <div class="model-box-left-title">
          <span>{{ item.title }}</span>
        </div>
        <div class="b-box">
          <div class="model-box-left-desc" v-html="item.desc"></div>
          <div class="model-box-left-btn">
            <span @click="toCreatOrder(item)">购买</span>
            <span>￥{{ item.price }}</span>
          </div>
        </div>

      </div>
    </div>

    <div style="margin-top: 20px; display: flex; justify-content: center;padding-bottom:80px;">
      <Page :total="total" :current="pages.page" show-total @on-change="pagesChange" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "@/components/Header/Header.vue";
import { ptype_list, product_list, banner_list } from "@/api/api";
export default {
  components: {
    Header
  },
  data() {
    return {
      banner_list: [],
      current: "",
      isData: false,
      oneData: {},
      twoData: [],
      sixData: [],
      tenData: {},
      product_listData: [],
      typeList: [],
      pFixed: false,
      marginLeft: 0,
      topCurrent: "top",
      bannerCurrent: 0,
      currentPage: 1,
      pages: {
        page: 1,
        pz: 9, //	否	string
        search: "", //		否	string	搜索关键字
        type: 1, //	是	string	类型 1物联网 2专业服务
        t_id: 0 //		否	string	标签id
      },
      total: 0
    };
  },
  mounted() {
    // window.scrollTo(0, 0);
    this.getptype_list();
    this.getproduct_list();
    this.getbanner_list();
  },
  destroyed() { },
  computed: {
    ...mapState(["isLogin"])
  },
  methods: {
    searchs() {
      this.getproduct_list();
    },
    getbanner_list() {
      banner_list(3).then(res => {
        // console.log("banner_list3", res);
        this.banner_list = res.data;
      });
    },
    //获取头部分类
    getptype_list() {
      let that = this;
      ptype_list(1).then(res => {
        // console.log("typeList", res);
        that.typeList = res.data;
      });
    },
    //获取列表
    getproduct_list() {
      let that = this;
      product_list(that.pages).then(res => {
        // console.log("product_list", res);
        if (res.code == 200) {
          that.total = res.data.total;
          that.product_listData = res.data.list;
        }
      });
    },
    //去创建订单
    toCreatOrder(item) {
      if (!this.isLogin) {
        this.$Message.error("请先登录");
        return false;
      }

      // this.$router.push({
      //   path: "/createOrder",
      //   query: {
      //     buy_str: JSON.stringify([{ car_id: 0, pid: item.id, num: 1 }])
      //   }
      // });

      const { href } = this.$router.resolve({
        path: "/createOrder", query: {
          buy_str: JSON.stringify([{ car_id: 0, pid: item.id, num: 1 }])
        }
      });
      window.open(href, "_blank");
    },
    //查看详情
    toDetail(item) {
      // this.$router.push({ path: "/productDetail", query: { id: item.id } });
      const { href } = this.$router.resolve({ path: "/productDetail", query: { id: item.id } });
      window.open(href, "_blank");
    },
    changeTabs(item, index) {
      this.current = index;
      this.pages.page = 1;
      this.pages.t_id = item.id;
      this.getproduct_list();
    },
    turnLeft() {
      let scrollwidth = this.$refs.imgBox.scrollWidth; //包括溢出的部分
      let offsetWidth = this.$refs.imgBox.offsetWidth; //不包括溢出的部分
      // this.current = this.current - 1;
      if (-this.marginLeft > 1) {
        this.marginLeft = this.marginLeft + offsetWidth;
      }
    },
    turnRight() {
      // this.current = this.current + 1;
      let scrollwidth = this.$refs.imgBox.scrollWidth; //包括溢出的部分
      let offsetWidth = this.$refs.imgBox.offsetWidth; //不包括溢出的部分
      // let boxscrollwidth = this.$refs["box"][0].scrollWidth; //包括溢出的部分
      // console.log("scrollwidth", scrollwidth);
      // console.log("offsetWidth", offsetWidth);
      if (scrollwidth > offsetWidth) {
        if (scrollwidth - offsetWidth > 0) {
          this.marginLeft = this.marginLeft - offsetWidth;
        }
      }
      // console.log("boxscrollwidth",boxscrollwidth);
    },
    pagesChange(num) {
      this.pages.page = num;
      this.getproduct_list();
    }
  }
};
</script>

<style lang="less" scoped>
.union-pages {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f7f7;
}

.header-img img {
  width: 100%;
  display: block;
  min-height: 200px;
}

.header-search {
  z-index: 2;
  margin-top: -25px;
  width: 65%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 2px #e2e2e2;
}

.header-search input {
  padding-left: 20px;
  height: 50px;
  border: none;
  width: calc(100% - 120px);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  outline: none;
}

.header-search span {
  display: inline-block;
  width: 120px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #5075ec;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 8px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.header-search span:hover {
  cursor: pointer;
}

.checkedTabsImg {
  width: 130px !important;
}

.lunbo {
  z-index: 1;
  width: 100%;
  height: 361px;
  display: flex;
  align-items: center;
  background: #ffffff;
  margin-top: -25px;
  padding: 0 80px;
}

.imgbtn {
  width: 40px;
  height: 40px;
  display: block;
}

.lunbo-container {
  width: calc(100%);
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow: hidden;
}

.lunbo-container-box {
  width: 14.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.6s;
}

.lunbo-container-img-box {
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
}

.lunbo-container-img {
  width: 110px;
  /* height: 128px; */
}

.lunbo-container-title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #03142c;
}

.product-right {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
}

.model-box-son2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
  margin: 0.65%;
  box-shadow: 0 0 20px #dcdcdc;
  border-radius: 10px;
  flex-shrink: 0;
}

.model-box-left-img {
  width: 100%;
  height: 17rem;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.model-box-left-img img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 17rem;
}

.model-box-left-img .mask {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.model-box-son2:hover .mask {
  height: 17rem;
  background: rgba(000, 000, 000, 0.3);
}

.model-box-son2:hover .model-box-left-btn span:nth-child(1) {
  background: #1c4492;
}

.poleft {
  width: 45px;
  height: 110px;
  position: absolute;
  top: calc(50% - 55px);
  left: -47px;
  /* box-shadow: 0 0 4px #f0f0f0; */
}

.poright {
  width: 45px;
  height: 110px;
  position: absolute;
  top: calc(50% - 55px);
  right: -47px;
  /* box-shadow: 0 0 4px #f0f0f0; */
}

.header-img-model {
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow: hidden;
}

.header-img-model-son-box {
  /* 超出滚动的关键，没有它元素会自动缩小，不会滚动 */
  flex-shrink: 0;
  transition: all 0.6s;
}

.header-img-model-son {
  margin-bottom: 20px;
  width: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #f5f5f5;
}

::-webkit-scrollbar {
  /* 隐藏滚动条 */

  /* display: none; */
}

.header-img-model-son-img {
  display: block;
  width: 100px;
  height: 100px;
}

.header-img-model-son-span {
  font-size: 18px;
  font-family: sanLight;
}

.checkedImg {
  color: #14508c;
}

.model-product {
  width: 100%;
  height: 630px;
}

.model-box {
  display: flex;
}

.product-left {
  width: 42%;
}

.model-box-left-title {
  width: 100%;
  font-size: 22px;
  font-family: sanMedium;
  color: #14508c;
  padding: 20px 20px 10px 20px;
}

.b-box {
  min-height: 140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.model-box-left-desc {
  // height: 70px;
  width: 100%;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 20px 0 20px;
  margin-bottom: 20px;
  color: #838383;
  font-size: 14px;
  font-family: sanRegular;

  // p {
  //   height: 70px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 3;
  //   -webkit-box-orient: vertical;
  // }
}

.model-box-left-btn {
  padding: 0 20px 20px 20px;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:nth-child(1) {
    display: inline-block;
    width: 87px;
    height: 32px;
    background: #547af8;
    border-radius: 5px;
    text-align: center;
    line-height: 32px;
    color: #ffffff;
  }

  span:nth-child(1):hover {
    cursor: pointer;
  }

  span:nth-child(2) {
    display: inline-block;
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ff3b48;
  }
}

.model-box-left-detail {
  text-align: center;
  margin-top: 10px;
}

.model-box-left-detail span {
  display: inline-block;
  /* width: 80px;
  height: 24px;
  line-height: 24px; */
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  width: 123px;
  height: 44px;
  line-height: 44px;
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #051c35;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.model-box-left-detail span:hover {
  cursor: pointer;
}

.model-box-left-detail1 {
  text-align: center;
  margin-top: 10px;
}

.model-box-left-detail1 span {
  color: #ffffff;
  background: #14508c;
  display: inline-block;
  width: 80px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 40px;
  font-size: 12px;
}
</style>