<template>
  <div class="mainpageboxs">
    <!-- <List :listData="listData"></List> -->
    <!-- <Scroll-list :listData="listData"></Scroll-list> -->
    <!-- <Header :startWidth="80" :startTop="50" :currentRouter="'/History'"></Header> -->

    <div class="header-img">
      <img v-if="banner_list.length>0" :src="banner_list.length>0?banner_list[0].ads_pic:''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="container-box">
      <div class="mainpageboxs-container">
        <div class="service-tabs-box" ref="tabsbox">
          <img class="left-img" @click="turnLeft" src="../../assets/leftL.png" alt />
          <div class="service-tabs" ref="tabs">
            <span
              class="tabs-span"
              :class="tabsCurrent == index ? 'tabsCheckeds' : ''"
              :style="index == 0 ? 'margin-left:' + marginLeft + 'px' : ''"
              v-for="(item, index) in oneType"
              :key="index"
              @click="tabsChange(item, index)"
            >{{ item.name }}</span>
          </div>
          <img class="right-img" @click="turnRight" src="../../assets/rightR.png" alt />
        </div>
        <div style="margin-top: 40px;height:256px;">
          <div
            class="span-box"
            v-for="(item, index) in lc_list"
            :key="index"
            @click="toDetail(item, index)"
          >
            <span
              class="yuandian"
              :style="{
                background: yuandianCurrent == index ? '#3a5cf9' : '#ffffff',
              }"
            ></span>
            <span class="wenzi">{{ item.title }}</span>
          </div>
        </div>
        <div style="text-align: right; margin-top: 20px">
          <Page
            @on-change="pageChange"
            :total="total"
            show-total
            :page-size="pages.pz"
            :current="pages.page"
          />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="box">
        <div class="title">
          <span>企业荣誉</span>
        </div>
        <div class="container">
          <!-- v-model="value1" -->
          <Carousel :autoplay-speed="5000" trigger="hover" easing loop class="Carousels">
            <CarouselItem class="CarouselsItem" v-for="(item, index) in banner_list1" :key="index">
              <!-- <div class="cell" v-for="(itemson, indexson) in 8" :key="indexson">
                <img src="@/assets/newImg/hisz.jpg" alt />
              </div>-->

              <!-- <img :src="item.ads_pic" /> -->
              <img :src="itemSon.ads_pic" v-for="(itemSon,indexSon) in item" :key="indexSon" />
            </CarouselItem>
          </Carousel>
        </div>
      </div>
    </div>
    <div class="bottom2">
      <div class="zhengshu" v-if="showt">
        <div class="title">
          <span>资质证书</span>
        </div>
        <carousel-3d
          :autoplay="true"
          :autoplayTimeout="3000"
          :perspective="35"
          :display="banner_list2.length"
          :animationSpeed="1000"
          :width="323"
          :height="428"
          controlsVisible
          :controlsHeight="60"
        >
          <slide v-for="(item, i) in banner_list2" :index="i" :key="i">
            <template slot-scope="obj">
              <img :src="item.ads_pic" @click="imgClick(item, obj)" />
            </template>
          </slide>
        </carousel-3d>
        <!-- <div class="desc">
          <span>质量管理体系认证证书</span>
        </div>-->
      </div>
      <div class="zhengshu" v-if="showt2">
        <div class="title">
          <span>软著证书</span>
        </div>
        <carousel-3d
          :autoplay="true"
          :autoplayTimeout="3000"
          :perspective="35"
          :display="banner_list3.length"
          :animationSpeed="1000"
          :width="323"
          :height="428"
          controlsVisible
          :controlsHeight="60"
        >
          <slide v-for="(item, i) in banner_list3" :index="i" :key="i">
            <template slot-scope="obj">
              <img :src="item.ads_pic" @click="imgClick(item, obj)" />
            </template>
          </slide>
        </carousel-3d>
        <!-- <div class="desc">
          <span>食品安全人工智能监管系统</span>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import Header from "@/components/Header/Header.vue";
import { lc_type_list, lc_list, banner_list } from "@/api/api";
export default {
  components: { Header, Carousel3d, Slide },
  data() {
    return {
      yuandianCurrent: "s",
      oneType: [],
      lc_list: [],
      marginLeft: 0,
      tabsCurrent: 0,
      pages: {
        page: 1, //	否	string	无
        pz: 5, //	否	string	无
        t_id: 0
      },
      total: 0,
      banner_list: [],
      banner_list1: [],
      banner_list2: [],
      banner_list3: [],
      showt: false,
      showt2: false,
      slides: [
        {
          title: "parent",
          slide: 23424234234234,
          ads_pic:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
        },
        {
          title: "parent",
          slide: 23424234234234,
          ads_pic:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
        },
        {
          title: "parent",
          slide: 23424234234234,
          ads_pic:
            "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg"
        },
        {
          title: "parent",
          slide: 23424234234234,
          ads_pic:
            "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg"
        },
        {
          title: "parent",
          slide: 23424234234234,
          ads_pic:
            "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg"
        }
      ]
    };
  },
  mounted() {
    this.getlc_type_list();
    this.getbanner_list();
    this.getbanner_list1();
    this.getbanner_list2();
    this.getbanner_list3();
  },
  methods: {
    imgClick(data, obj) {
    },
    //获取一级分类
    getlc_type_list() {
      let that = this;
      lc_type_list().then(res => {
        that.oneType = res.data.list;
        // that.total = res.data.total;
        that.pages.t_id = res.data.list[0].id;
        that.getlc_list();
      });
    },
    //列表
    getlc_list() {
      let that = this;
      lc_list(that.pages).then(res => {
        // console.log("lc_list", res);
        that.lc_list = res.data.list;
        that.total = res.data.total;
      });
    },
    toDetail(item, index) {
      // console.log("item",item)
      this.yuandianCurrent = index;
      if (item.w_link == "") {
        //跳内部富文本
        this.$router.push({ path: "/historyDetail", query: { id: item.id } });
        // let router = this.$router.resolve({
        //   path: "/historyDetail",
        //   query: { id: item.id },
        // });
        // window.open(router.href, "_blank");
      } else {
        window.open(item.w_link, "_blank");
        //跳外部链接
      }
    },
    //轮播
    getbanner_list() {
      banner_list(5).then(res => {
        this.banner_list = res.data;
      });
    },
    //荣誉证书
    getbanner_list1() {
      banner_list(12).then(res => {
        this.banner_list1 = res.data;
        // if(res.data.length>7){

        // }
      });
    },
    //资质证书
    getbanner_list2() {
      let that = this;
      banner_list(13).then(res => {
        that.banner_list2 = res.data;
        that.showt = true;
      });
    },
    //软著证书
    getbanner_list3() {
      banner_list(14).then(res => {
        this.banner_list3 = res.data;
        this.showt2 = true;
      });
    },
    turnLeft() {
      if (-this.marginLeft > 1) {
        this.marginLeft = this.marginLeft + 185;
      }
    },
    turnRight() {
      let scrollwidth = this.$refs.tabs.scrollWidth; //包括溢出的部分
      let offsetWidth = this.$refs.tabsbox.offsetWidth; //
      // let boxscrollwidth = this.$refs["box"][0].scrollWidth; //包括溢出的部分
      // console.log("scrollwidth", scrollwidth);
      // console.log("offsetWidth", offsetWidth);
      // console.log("boxscrollwidth",boxscrollwidth);

      if (scrollwidth - offsetWidth > 0) {
        this.marginLeft = this.marginLeft - 185;
      }
    },
    tabsChange(item, index) {
      let that = this;
      that.tabsCurrent = index;
      that.pages.t_id = item.id;
      that.pages.page = 1;
      that.getlc_list();
    },
    //分页的页数变化后触发
    pageChange(e) {
      let that = this;
      that.pages.page = e;
      that.getlc_list();
    }
  }
};
</script>

<style lang="less" scoped>
.mainpageboxs {
  width: 100%;
}
.header-img img {
  width: 100%;
  display: block;
  min-height: 200px;
}
.container-box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.mainpageboxs-container {
  width: 55%;
}
.service-tabs-box {
  width: 100%;
  position: relative;
}
.service-tabs {
  display: flex;
  width: 100%;
  height: 220px;
  overflow: hidden;
}
.left-img {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  left: -100px;
}
.right-img {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: -100px;
}
.tabs-span {
  display: inline-block;
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  padding: 5px 30px;
  /* border: 1px solid #c6c6c6; */
  margin-right: 10px;
  transition: all 0.5s;
  flex-shrink: 0;
  color: #7b7d85;
}
.span-box {
  padding: 10px 0;
  margin: 0 20px;
  font-family: sanRegular;
  display: flex;
  align-items: center;

  font-size: 22px;
  font-weight: 400;
  color: #2e2f34;
}
.span-box:hover {
  cursor: pointer;
}
.tabsCheckeds {
  font-size: 100px;
  color: #e0e3ef !important;
  /* background: #426197 !important; */
  /* border: 0 !important; */
}
.yuandian {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 2px solid #3a5cf9;
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
}
.wenzi {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //将对象作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; // 从上到下垂直排列子元素
  -webkit-line-clamp: 1; //显示的行数
}
.bottom {
  margin-top: 120px;
  padding-bottom: 100px;
  width: 100%;
  background: #f5f6fa;
  display: flex;
  justify-content: center;
  .box {
    width: 63%;
    .title {
      font-family: sanRegular;
      margin: 90px 0 60px 0;
      text-align: center;
      font-size: 29px;
      font-weight: 500;
      color: #2e2f34;
    }
    .container {
      .Carousels {
        .CarouselsItem {
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          img {
            width: 23%;
            display: block;
            margin: 1%;
          }

          .cell {
            margin-bottom: 1.5rem;
            width: 25%;
            display: flex;
            justify-content: center;
            img {
              // width: 90%;
              width: 10rem;
              height: 10rem;
              // min-height: 140px;
              background: #ffffff;
              display: block;
            }
          }
        }
      }
    }
  }
}
.bottom2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  .zhengshu {
    padding: 80px 0;
    width: 63%;
    .title {
      font-family: sanRegular;
      font-size: 29px;
      font-weight: 500;
      color: #2e2f34;
      text-align: center;
      margin-bottom: 70px;
    }
    .desc {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      font-family: sanRegular;
      color: #2e2f34;
    }
  }
}
</style>
<style >
.ivu-carousel-active button {
  background: #3255fa !important;
}
.ivu-carousel-dots-inside {
  bottom: -40px !important;
}
</style>