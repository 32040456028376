<template>
  <div class="history">
    <div class="history-content" v-html="content"></div>
  </div>
</template>

<script>
import { news_det } from "@/api/api";
export default {
  data() {
    return {
      content: "",
    };
  },
  mounted() {},
  activated() {
    this.getnews_det();
  },
  methods: {
    //获取详情
    getnews_det() {
      let that = this;
      news_det().then((res) => {
        // console.log("content", res);
        that.content = res.data.content;
      });
    },
  },
};
</script>

<style>
.history {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.history-content {
  width: 80%;
}
</style>