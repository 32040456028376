<template>
  <Modal v-model="showModel" :footer-hide="true" :title="title">
    <Form :model="formItem" :label-width="90">
      <Row>
        <Col span="12">
        <FormItem label="收货人:">
          <Input v-model="formItem.name" placeholder="请填写收货人姓名"></Input>
        </FormItem>
        </Col>
        <Col span="12">
        <FormItem label="手机号:">
          <Input v-model="formItem.tel" placeholder="请填写收货人手机号"></Input>
        </FormItem>
        </Col>
      </Row>
      <FormItem label="收货地址:">
        <el-cascader size="small" :options="options" v-model="selectedOptions" @change="handleChange"></el-cascader>
      </FormItem>
      <FormItem label>
        <Input v-model="formItem.address" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
          placeholder="填写地址详情"></Input>
      </FormItem>
      <FormItem label="默认并使用">
        <Checkbox v-model="formItem.state" :true-value="1" :false-value="2"></Checkbox>
      </FormItem>
      <div class="form-btn">
        <Button @click="cancel">取消</Button>
        <Button @click="okBtn">保存并使用</Button>
      </div>
    </Form>
  </Modal>
</template>

<script>
import { Myaddr_add, addr_det, edit } from "@/api/api";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  data() {
    return {
      title: "",
      formItem: {
        id: 0,
        name: "", //	张先生	是	string	收货人姓名
        tel: "", //	15011566143	是	string	电话
        province: "", //	四川	是	string	省
        city: "", //	成都	是	string	市
        area: "", //	成华	是	string	区
        address: "", //	双桥路	是	string	详细地址
        state: 1 //	2	是	string	1默认地址 2不是默认地址
      },
      showModel: false,
      options: regionData,
      selectedOptions: []
    };
  },
  methods: {
    cancel() {
      this.clearFrom();
    },
    handleChange(value) {
      this.formItem.province = CodeToText[value[0]];
      this.formItem.city = CodeToText[value[1]];
      this.formItem.area = CodeToText[value[2]];
    },
    clearFrom() {
      this.formItem = {
        id: 0,
        name: "", //	张先生	是	string	收货人姓名
        tel: "", //	15011566143	是	string	电话
        province: "", //	四川	是	string	省
        city: "", //	成都	是	string	市
        area: "", //	成华	是	string	区
        address: "", //	双桥路	是	string	详细地址
        state: 2 //	2	是	string	1默认地址 2不是默认地址
      };
      this.showModel = false;
    },
    //修改 回显
    getDetail() {
      let that = this;
      addr_det(that.formItem.id)
        .then(res => {
          if (res.code == 200) {
            that.formItem = res.data;
            let arr = [];
            let province = TextToCode[res.data.province].code;
            let city = TextToCode[res.data.province][res.data.city].code;
            let area =
              TextToCode[res.data.province][res.data.city][res.data.area].code;
            arr.push(province);
            arr.push(city);
            arr.push(area);
            that.selectedOptions = arr;
          } else {
            that.$Message.error(res.message);
          }
          that.$parent.getMyaddr_index();
        })
        .catch(err => {
          that.$Message.error(err);
        });
    },
    okBtn() {
      let formItem = this.formItem;
      if (formItem.name == "") {
        this.$Message.error("请填写收货人姓名");
        return false;
      }
      if (formItem.tel == "") {
        this.$Message.error("请填写手机号");
        return false;
      }
      if (formItem.tel.length != 11) {
        this.$Message.error("手机号位数不正确");
        return false;
      }
      if (formItem.province == "") {
        this.$Message.error("请选择收获地址");
        return false;
      }
      if (formItem.address == "") {
        this.$Message.error("请填写详细地址");
        return false;
      }
      if (this.formItem.id > 0) {
        this.postedit();
      } else {
        this.addr_add();
      }
    },
    addr_add() {
      let that = this;
      Myaddr_add(that.formItem)
        .then(res => {
          if (res.code == 200) {
            that.$Message.success(res.message);
          } else {
            that.$Message.error(res.message);
          }
          that.$parent.getMyaddr_index();
        })
        .catch(err => {
          that.$Message.error(err);
        });
      this.clearFrom();
    },
    postedit() {
      let that = this;
      edit(that.formItem)
        .then(res => {
          if (res.code == 200) {
            that.$Message.success(res.message);
          } else {
            that.$Message.error(res.message);
          }
          that.$parent.getMyaddr_index();
        })
        .catch(err => {
          that.$Message.error(err);
        });
      this.clearFrom();
    }
  }
};
</script>

<style lang="less" scoped>
.form-btn {
  display: flex;
  justify-content: center;

  button:nth-child(1) {
    width: 172px;
    height: 46px;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3a3a3a;
    margin-right: 10px;
  }

  button:nth-child(2) {
    width: 172px;
    height: 46px;
    background: #1c4492;
    border-radius: 2px;
    border: none;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
<style scoped>
.ivu-input {
  border: none !important;
}
.el-input__inner{
border-radius: 0 !important;
}
</style>