<template>
  <div class="minepages">
    <div class="header-img">
      <img v-if="banner_list.length > 0" :src="banner_list.length > 0 ? banner_list[0].ads_pic : ''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="tabs">
      <div class="cell" :class="tabsCurrent === index ? 'checkedTabs' : ''" @click="changeHeaderTabs(item, index)"
        v-for="(item, index) in platformType" :key="index">
        <span>{{ item.name }}</span>
      </div>
    </div>

    <div class="mine-box">
      <div class="mine-right-title">
        <span>选择需要进入的平台</span>
      </div>
      <div class="mine-right-container">
        <div class="mine-right-container-cell" :class="spanCurrent == index ? 'checkedPt' : ''"
          v-for="(item, index) in platform" :key="index" @click="changeSpan(index, item)">
          <div class="top">
            <span class="mine-right-span1">{{ item.title }}</span>
            <img src="@/assets/newImg/rights2.png" alt />
          </div>
          <span class="mine-right-span2">{{ item.desc }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_platform,
  get_acc_toekn,
  banner_list,
  get_platform_type
} from "@/api/api";
export default {
  data() {
    return {
      tabsCurrent: 0,
      current: 0,
      spanCurrent: "s",
      platform: [],
      platformType: [],
      platformId: 0,
      modal1: false,
      headerBar: [
        {
          id: 0,
          text: "首页",
          path: "/productStructure",
          cpath: ""
        },
        {
          id: 1,
          text: "集团介绍",
          path: "/aboutus",
          cpath: "/aboutusDetail"
        },
        {
          id: 2,
          text: "产品结构",
          path: "/productStructure",
          cpath: ""
        },
        {
          id: 3,
          text: "数字产品",
          path: "/digitalproducts",
          cpath: ""
        },
        {
          id: 4,
          text: "物联产品",
          path: "/unionproduct",
          cpath: "/productDetail"
        },
        {
          id: 5,
          text: "技术服务",
          path: "/professionalservices",
          cpath: "/serviceDetail"
        },

        {
          id: 6,
          text: "发展历程",
          path: "/History",
          cpath: "/historyDetail"
        },
        {
          id: 7,
          text: "企业动态",
          path: "/news",
          cpath: "/newDetail"
        },
        {
          id: 8,
          text: "投诉举报",
          path: "/complaint",
          cpath: ""
        },
        {
          id: 9,
          text: "联系我们",
          path: "/callme",
          cpath: "/callmeDetail"
        }
      ],
      banner_list: []
    };
  },
  created() { },
  mounted() {
    this.getbanner_list();
  },
  activated() {
    this.get_platform_types();
  },
  methods: {
    changeHeaderTabs(item, index) {

      if (!item.t_url) {
        this.tabsCurrent = index;
        this.platformId = item.id;
        this.get_platforms();
      } else {
        window.open(item.t_url,"_black")
      }
    },

    changeSpan(index, item) {
      this.spanCurrent = index;
      // console.log("item", item);
      this.get_acc_toekns(item.id);
    },
    //获取平台列表
    get_platforms() {
      let that = this;
      get_platform(that.platformId).then(res => {
        // console.log("platform", res);

        that.platform = res.data.list;
      });
    },
    //获取平台分类
    get_platform_types() {
      let that = this;
      get_platform_type().then(res => {
        console.log("platformType", res);

        that.platformType = res.data.list;
        that.platformId = res.data.list[0].id;
        that.get_platforms();
      });
    },
    //请求平台地址
    get_acc_toekns(id) {
      let that = this;
      const msg = this.$Message.loading({
        content: "请求平台中..",
        duration: 0
      });

      get_acc_toekn(id)
        .then(res => {
          setTimeout(msg, 100);
          // console.log("get_acc_toekn", res);
          if (res.code == 200) {
            // window.location.href=res.data.to_url
            window.open(res.data.to_url, "_blank");
          } else {
            that.$Message.error(res.message);
          }

          // that.platform = res.data.list;
        })
        .catch(err => {
          setTimeout(msg, 0);
        });
    },
    //平台入口
    getbanner_list() {
      banner_list(6).then(res => {
        this.banner_list = res.data;
      });
    },
    //退出登录
    outLogin() {
      this.modal1 = true;
    },
    //退出登录
    ok() {
      localStorage.removeItem("accessToken");
      this.$router.push({ path: "/login" });
    },
    cancel() { }
  }
};
</script>

<style lang="less" scoped>
.minepages {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.header-img img {
  width: 100%;
  display: block;
}

.tabs {
  width: 55%;
  display: flex;

  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 70px;

  .cell {
    font-size: 21px;
    font-family: sanMedium;
    font-weight: 400;
    color: #7b7979;
    padding: 20px 5px;
    margin-right: 50px;
  }

  .checkedTabs {
    color: #282828;
    font-size: 21px;
    font-weight: 500;
    border-bottom: 3px solid #27a3f3;
  }
}

.headerbox {
  width: 100%;
  display: flex;
  background: #1c4492;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 9999;
}

.tabChecked {
  border-bottom: 1px solid #ffffff;
  color: #2f4373;
}

.header-bar {
  /* background: #ffffff; */
  /* box-shadow: 0 0 5px #adadad; */
  overflow: hidden;
  color: #494949;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-not-box-right {
  width: 70%;
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-bar-span {
  display: flex;
  align-items: center;
  font-family: sanNormal;
  color: #ffffff;
  font-size: 1.21rem;
}

.header-bar-span:hover {
  cursor: pointer;
}

.goin {
  display: inline-block;
  text-align: center;

  color: #ffffff;
  transition: all 0.5s;

  width: 152px;
  height: 55px;
  display: flex;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 5px;
}

.mine-box {
  width: 55%;
}

.mine-right-title {
  font-family: sanMedium;
  font-size: 21px;
  color: #282828;
}

.mine-right-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* justify-content: space-between; */
  padding: 30px 0;

  .mine-right-container-cell {
    padding: 30px 20px;
    width: 31.5%;
    background: #ffffff;
    border: 2px solid #f0f0f0;

    border-radius: 3px;

    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .top {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .mine-right-span1 {
        font-family: sanRegular;
        font-size: 18px;
        color: #4a4a4a;
        margin-right: 10px;
      }

      img {
        margin-top: 2px;
        width: 20px;
      }
    }

    .mine-right-span2 {
      display: inline-block;
      font-family: sanRegular;
      overflow: hidden;
      /*内容超出后隐藏*/

      text-overflow: ellipsis;
      /* 超出内容显示为省略号 */

      white-space: nowrap;
      /* 文本不进行换行 */

      font-size: 16px;
      font-weight: 400;
      color: #808080;
    }
  }

  .checkedPt {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
}

.checkedBox {
  background: #144f8b !important;
}

.checkedSpan1 {
  color: #ffffff !important;
}

.checkedSpan2 {
  color: #144f8b !important;
}
</style>