<template>
  <div class="sbox">
    <div style="height:70px"></div>
    <div class="content" v-html="detail.content"></div>
  </div>
</template>

<script>
import { structure_det } from "@/api/api.js";
export default {
  data() {
    return {
      id: 0,
      detail: {}
    };
  },
  mounted() {},
  activated() {
    this.id = this.$route.query.id;
    this.getstructure_det();
  },
  methods: {
    getstructure_det() {
      let that = this;
      structure_det(that.id).then(res => {
        this.detail = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.sbox {
  // padding: 0 20% 80px 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  .content {
    width: 1000px;
  }
}
</style>