import { render, staticRenderFns } from "./Health.vue?vue&type=template&id=36b77ea5&scoped=true"
import script from "./Health.vue?vue&type=script&lang=js"
export * from "./Health.vue?vue&type=script&lang=js"
import style0 from "./Health.vue?vue&type=style&index=0&id=36b77ea5&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b77ea5",
  null
  
)

export default component.exports