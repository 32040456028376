<template>
  <div class="loginpages">
    <!-- <div class="headerbox" :style="{ top: 0 + 'px' }">
      <div class="header-bar" :style="{
        width:'100%',
        borderRadius:'0',
      }">
        <div class="header-not-box-left">
          <img
            style="width: 50px; height: 25px"
            src="../../assets/euticon.png"
            class="header-not-leftimg"
            alt="EUT"
          />
        </div>
        <div class="header-not-box-right">
          <span
            class="header-bar-span"
            v-for="(item, index) in headerBar"
            :key="index"
            @click="changeTab(item)"
          >{{ item.text }}</span>
          <span class="header-bar-span goin" @click="toMine">管理中台</span>
        </div>
      </div>
    </div>-->
    <div class="container-box">
      <div style="height:70px;width:100%;"></div>
      <div class="log-img">
        <img src="../../assets/loginlog.png" alt />
      </div>
      <div class="log-text">
        <span>修改密码</span>
      </div>
      <div class="login-form">
        <div style="margin-bottom: 20px">
          <Input size="large" v-model="formItem.phone" placeholder="请输入手机号" />
        </div>
        <div style="margin-bottom: 20px">
          <Input size="large" v-model="formItem.old_pwd" placeholder="旧密码" />
        </div>
        <div style="margin-bottom: 20px">
          <Input size="large" v-model="formItem.new_pwd" placeholder="新密码" />
        </div>
        <div style="margin-bottom: 20px">
          <Input size="large" v-model="formItem.new_pwd_t" placeholder="确认新密码" />
        </div>
      </div>
      <div class="btn-box" style="width: 465px;display:flex;">
        <Button size="large" type="primary" style="width:48%;border: 1px solid #D0D0D0;background:#ffffff;color:#000000;
border-radius: 5px;" long @click="toback">取消</Button>
        <Button size="large" type="primary" style="background-color: #1C4492;width:48%;border-radius: 5px;border:none;"
          long @click="query_pwd">确定</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { get_code, up_pwd } from "@/api/api";
export default {
  data() {
    return {
      current: 1,
      formItem: {
        phone: "",//	19160832579	是	string	手机号
        old_pwd: "",//		2	是	string	旧密码
        new_pwd: "",//		706128	否	string	新密码
        new_pwd_t: "",//			否	string	确认新密码
      },

      countDownText: "获取验证码"
    };
  },
  created() { },
  mounted() {
    let that = this;
    document.onkeydown = function (e) {
      if (e.keyCode == 13) {
        that.query_pwd();
      }
    };
  },

  methods: {
    //获取验证码
    get_codes() {
      let that = this;
      if (!that.formItem.phone) {
        that.$Message.error("未输入手机号码");
        return false;
      }
      if (
        that.countDownText == "重新获取" ||
        that.countDownText == "获取验证码"
      ) {
        get_code({ phone: that.formItem.phone, type: 3 }).then(res => {
          if (res.code == 200) {
            that.$Message.success(res.message);
          }
        });
        that.countDownText = 60;
        let time = setInterval(function () {
          that.countDownText = that.countDownText - 1;
          if (that.countDownText < 1) {
            clearInterval(time);
            that.countDownText = "重新获取";
          }
        }, 1000);
      }
    },
    toback() {
      this.$router.back();
    },
    query_pwd() {
      let that = this;
      if (!that.formItem.phone) {
        that.$Message.error("未输入手机号码");
        return false;
      }
      if (that.formItem.old_pwd == "") {
        that.$Message.error("请输入旧密码");
        return false;
      }
      if (that.formItem.new_pwd == "") {
        that.$Message.error("请输入新密码");
        return false;
      }
      if (that.formItem.new_pwd_t == "") {
        that.$Message.error("请确认新密码");
        return false;
      }
      if (that.formItem.new_pwd != that.formItem.new_pwd_t) {
        that.$Message.error("确认密码不相同");
        return false;
      }
      // if (!that.formItem.msg_code) {
      //   that.$Message.error("未输入验证码");
      //   return false;
      // }

      up_pwd(that.formItem).then(res => {
        if (res.code == 200) {
          that.$Message.success("修改成功,请重新登录");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          that.formItem = {
            phone: "",
            old_pwd: "",
            new_pwd: "",
            new_pwd_t: ""
          }
          setTimeout(() => {
            that.$router.push({ path: "/login" });
          }, 2000);
        }
      });
    },
    //跳转到个人信息
    toMine() {
      let that = this;
      that.$parent.isShow = false;
      that.$parent.getBanner_list("", "", ""); //
      that.$router.push({ path: "/mine" });
    }
  }
};
</script>

<style scoped>
.loginpages {
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
}

.headerbox {
  width: 100%;
  display: flex;
  background: #1c4492;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 9999;
}

.tabChecked {
  border-bottom: 1px solid #ffffff;
  color: #2f4373;
}

.header-bar {
  /* background: #ffffff; */
  /* box-shadow: 0 0 5px #adadad; */
  overflow: hidden;
  color: #494949;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-not-box-right {
  width: 70%;
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-bar-span {
  display: flex;
  align-items: center;
  font-family: sanNormal;
  color: #ffffff;
  font-size: 1.21rem;
}

.header-bar-span:hover {
  cursor: pointer;
}

.goin {
  display: inline-block;
  text-align: center;

  color: #ffffff;
  transition: all 0.5s;

  width: 152px;
  height: 55px;
  display: flex;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 5px;
}

.container-box {
  margin-top: 80px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.log-img {}

.log-img img {
  display: block;
  width: 130px;
}

.log-text {
  text-align: center;
  font-size: 35px;
  font-family: sanMedium;
  color: black;
  margin: 20px 0 0px 0;
}

.login-tabs span {
  display: inline-block;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  font-family: sanRegular;
  color: rgb(134, 134, 134);
  font-size: 16px;
  border-bottom: 3px solid #cecece;
}

.checkedTab {
  color: black !important;
  border-bottom: 3px solid #104c8b !important;
}

.login-form {
  width: 465px;
  padding: 30px 0;
}

.more {
  margin: 30px 0;
  width: 465px;
  font-size: 18px;
  font-weight: 400;
  color: #328fff;
  display: flex;
  justify-content: space-between;
}

.desc {
  font-size: 16px;
  font-weight: 400;
  color: #b1b1b1;
}

.btn-box {
  justify-content: space-between;
}
</style>
<style>
.ivu-input {
  border: none;
}
</style>