<template>
  <div class="carPages">
    <div class="pagesBox">
      <div class="header-title">
        <span class="header-title-span">我的购物车</span>
        <div class="header-title-line">
          <Steps :current="0" size="small">
            <Step title content="我的购物车"></Step>
            <Step title content="填写核对订单"></Step>
            <Step title content="成功提交订单"></Step>
          </Steps>
        </div>
      </div>

      <div class="container">
        <div class="car-list" v-if="listData.length>0">
          <div class="car-title">
            <div class="cell1">
              <Checkbox size="small" v-model="isAllChecked" @on-change="changeAll">全选</Checkbox>
            </div>
            <div class="cell2">
              <span>商品</span>
            </div>
            <div class="cell3">
              <span>单价</span>
            </div>
            <div class="cell4">
              <span>数量</span>
            </div>
            <div class="cell5">
              <span>小计</span>
            </div>
            <div class="cell6">
              <span>操作</span>
            </div>
          </div>
          <div class="car-contaier" v-for="(item,index) in carList" :key="index">
            <div class="cell1">
              <Checkbox
                size="small"
                v-model="carList[index].isChecked"
                :true-value="true"
                :false-value="false"
              ></Checkbox>
            </div>
            <div class="cell2">
              <img :src="item.imgs" alt />
              <span>{{item.title}}</span>
            </div>
            <div class="cell3">
              <span>￥{{item.price}}</span>
            </div>
            <div class="cell4">
              <span @click="edd(item,index)">-</span>
              <span>{{item.num}}</span>
              <span @click="add(item,index)">+</span>
            </div>
            <div class="cell5">
              <span>￥{{item.price*item.num}}</span>
            </div>
            <div class="cell6">
              <span @click="removeOne(item,index)">删除</span>
            </div>
          </div>
          <div class="car-footer">
            <div class="cell1">
              <Checkbox size="small" v-model="isAllChecked" @on-change="changeAll">全选</Checkbox>
              <span @click="removeAll">删除</span>
            </div>
            <div class="cell2">
              <div class="price">
                <div class="top">
                  <span>总计：</span>
                  <span>￥{{compAllPrice.price}}</span>
                </div>
                <div class="bottom">
                  <span class="span1">
                    已选择
                    <span class="span2">{{compAllPrice.num}}</span>件商品
                  </span>
                </div>
              </div>
              <div class="btn">
                <button @click="toPay">立即结算</button>
              </div>
            </div>
          </div>
        </div>
        <div class="noData" v-else>
          <img src alt />
          <span>您的购物车里什么也没有哦~</span>
          <button>去逛逛</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paycar_list, del_cart } from "@/api/api";
export default {
  data() {
    return {
      listData: [{}],
      isAllChecked: false, //是否全选
      carList: []
    };
  },
  mounted() {
    this.getpaycar_list();
  },
  computed: {
    compAllPrice() {
      let carList = this.carList;
      let price = 0;
      let num = 0;
      for (let i = 0; i < carList.length; i++) {
        if (carList[i].isChecked) {
          num++;
          price += parseFloat(carList[i].price) * parseFloat(carList[i].num);
        }
      }
      return { price, num };
    }
  },
  methods: {
    changeAll(e) {
      if (e) {
        for (let i = 0; i < this.carList.length; i++) {
          this.carList[i].isChecked = true;
        }
      } else {
        for (let i = 0; i < this.carList.length; i++) {
          this.carList[i].isChecked = false;
        }
      }
    },
    //删除指定的商品
    removeOne(item, index) {
      this.postdel_cart(item.id);
    },
    //删除所有选择的商品
    removeAll() {
      let that = this;
      let id = "";
      for (let i = 0; i < that.carList.length; i++) {
        if (that.carList[i].isChecked) {
          id += `${that.carList[i].id},`;
        }
      }
      id = id.substr(0, id.length - 1);
      if (id == "") {
        that.$Message.warning("请选择商品");
        return false;
      }
      // console.log("id", id);
      that.postdel_cart(id);
    },
    //删除商品
    postdel_cart(id) {
      let that = this;
      del_cart(id).then(res => {
        that.$Message.success(res.message);
        that.getpaycar_list();
      });
    },
    //立即结算
    toPay() {
      let that = this;
      let data = [];
      for (let i = 0; i < that.carList.length; i++) {
        if (that.carList[i].isChecked) {
          data.push({
            car_id: that.carList[i].id,
            pid: that.carList[i].pid,
            num: that.carList[i].num
          });
        }
      }
      if (data.length < 1) {
        that.$Message.warning("请选择商品");
        return false;
      }
      that.$router.push({
        path: "/createOrder",
        query: { buy_str: JSON.stringify(data) }
      });
    },
    //获取购物车列表
    getpaycar_list() {
      let that = this;
      paycar_list({ buy_str: JSON.stringify(that.buy_str) }).then(res => {
        // console.log("carList", res);
        if (res.code == 200) {
          let list = res.data.list;
          for (let i = 0; i < list.length; i++) {
            list[i].isChecked = false;
          }
          that.carList = list;
          that.count = res.data.count;
        }
      });
    },
    add(item, index) {
      this.carList[index].num = this.carList[index].num + 1;
    },
    edd(item, index) {
      this.carList[index].num = this.carList[index].num - 1;
    }
  }
};
</script>

<style lang="less" scoped>
.carPages {
  margin-bottom: 80px;
  background: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pagesBox {
    width: 60%;
    .header-title {
      padding: 0 30px;
      margin: 130px 0 30px 0;
      height: 60px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-title-span {
        font-size: 22px;
        font-weight: 400;
        color: #3a3a3a;
      }
      .header-title-line {
        width: 200px;
      }
    }
    .container {
      .car-list {
        .car-title {
          display: flex;
          align-items: center;

          height: 40px;
          background: #ffffff;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #a4a4a4;
          margin-bottom: 10px;
          div {
            flex-shrink: 0;
          }
          .cell1 {
            padding-left: 30px;
            display: flex;
            align-items: center;
            width: 10%;
          }
          .cell2 {
            text-align: center;
            width: 40%;
          }
          .cell3 {
            text-align: center;
            width: 15%;
          }
          .cell4 {
            text-align: center;
            width: 15%;
          }
          .cell5 {
            text-align: center;
            width: 10%;
          }
          .cell6 {
            text-align: center;
            width: 10%;
          }
        }
        .car-contaier {
          display: flex;
          align-items: center;
          height: 137px;
          background: #ffffff;
          font-size: 14px;
          font-family: Source Han Sans CN;
          margin-bottom: 10px;
          div {
            flex-shrink: 0;
          }
          .cell1 {
            padding-left: 30px;
            display: flex;
            align-items: center;
            width: 10%;
          }
          .cell2 {
            width: 40%;
            display: flex;
            align-items: center;

            img {
              width: 100px;
              height: 100px;
              margin-right: 50px;
            }
            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3a3a3a;
            }
          }
          .cell3 {
            width: 15%;
            text-align: center;
            font-weight: 400;
            color: #3a3a3a;
          }
          .cell4 {
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            span:hover {
              cursor: pointer;
            }
            span {
              display: inline-block;
            }
            span:nth-child(1) {
              width: 30px;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #bfbfbf;
            }
            span:nth-child(2) {
              width: 50px;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #bfbfbf;
              border-left: none;
              border-right: none;
            }
            span:nth-child(3) {
              width: 30px;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #bfbfbf;
            }
          }
          .cell5 {
            font-weight: bold;
            color: #1c4492;
            text-align: center;
            width: 10%;
          }
          .cell6 {
            font-weight: 400;
            color: #a4a4a4;
            text-align: center;
            width: 10%;
            span:hover{
              cursor: pointer;
            }
          }
        }
        .car-footer {
          height: 60px;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 30px;
          margin-bottom: 30px;
          .cell1 {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3a3a3a;
            span:nth-child(2) {
              margin: 0 20px 0 20px;
            }
            span:hover{
              cursor: pointer;
            }
          }
          .cell2 {
            display: flex;
            .price {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .top {
                span:nth-child(1) {
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #3a3a3a;
                }
                span:nth-child(2) {
                  font-size: 19px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  color: #1c4492;
                }
              }
              .bottom {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #a4a4a4;
                text-align: right;
                .span1 {
                }
                .span2 {
                  color: #1c4492;
                }
              }
            }
            .btn {
              margin-left: 10px;
              button {
                width: 180px;
                height: 60px;
                background: #1c4492;
                border: none;
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
      }
      .noData {
        margin-bottom: 30px;
        height: 497px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 100px;
          height: 100px;
        }
        span {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #989898;
          margin: 40px 0 20px 0;
        }
        button {
          width: 172px;
          height: 46px;
          background: #1c4492;
          border-radius: 2px;
          border: none;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
<style>
.ivu-steps-content {
  margin-left: -30px;
}
.ivu-steps-item {
  overflow: unset !important;
}
.ivu-steps-content {
  padding-left: 0 !important;
}
.ivu-steps-head-inner {
  width: 30px;
  height: 30px;
}
</style>