<template>
  <div class="service-detail">
    <div class="header-bimg">
      <img v-if="banner_list.length > 0" :src="banner_list.length > 0 ? banner_list[0].ads_pic : ''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="box">
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <div class="container">
        <div class="product-right" style="margin-top:50px">
          <div class="model-box-son2" v-for="(item, index) in product_listData" :key="index">
            <div class="model-box-left-img">
              <img :src="item.imgs" alt style="width: 100%;display: block" />
              <div class="mask">
                <div class="model-box-left-detail">
                  <!-- <span>购买</span> -->
                  <span @click="toDetail(item)">
                    <img style="width:30px;height:30px" src="@/assets/newImg/aies.png" alt />了解更多
                  </span>
                </div>
              </div>
            </div>
            <div class="model-box-left-title">
              <span>{{ item.title }}</span>
              <span v-html="item.desc"></span>
            </div>
            <div class="btnss">
              <button @click="toBuy(item)">点击购买</button>
              <span>￥{{ item.price }}</span>
            </div>
            <!-- <div class="model-box-left-desc" v-html="item.content "></div> -->
          </div>
        </div>
      </div>

      <div style="margin-top: 20px; display: flex; justify-content: center;padding-bottom:80px;">
        <Page :total="total" :current="pages.page" show-total @on-change="pagesChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { product_list, banner_list } from "@/api/api";
export default {
  data() {
    return {
      title: "",
      banner_list: [],
      sertwo_typeData: {},
      product_listData: [],
      marginLeft: 0,
      id: "",
      tabsCurrent: 0,
      pages: {
        page: 1,
        pz: 9, //	否	string
        search: "", //		否	string	搜索关键字
        type: 2, //	是	string	类型 1物联网 2专业服务
        t_id: 0 //		否	string	标签id
      },
      total: 0
    };
  },
  mounted() { },
  //使用keep-live 后使用该生命周期可每次进入都运行
  activated() {
    // console.log("gengxin");
    this.tabsCurrent = 0;
    this.marginLeft = 0;
    this.id = this.$route.query.id;
    this.pages.t_id = this.$route.query.id;
    this.pages.page = 1;
    this.title = this.$route.query.title;
    this.getproduct_list();
    this.getbanner_list();
    // console.log("id", this.id);
    // this.getsertwo_type();
    // this.getsertwo_type();
  },
  methods: {
    // //获取分类列表
    // getsertwo_type() {
    //   let that = this;
    //   sertwo_type(this.id).then(res => {
    //     // console.log("sertwo_typeData", res);
    //     that.sertwo_typeData = res.data;
    //     that.pages.type_two_id = res.data.list[0].id;
    //     that.getsergive_list();
    //   });
    // },
    // //获取内容列表
    // getsergive_list() {
    //   let that = this;
    //   sergive_list(this.pages).then(res => {
    //     // console.log("sergive_listData", res);
    //     that.sergive_listData = res.data.list;
    //   });
    // },

    //获取列表
    getproduct_list() {
      let that = this;
      product_list(that.pages).then(res => {
        // console.log("product_list", res);
        if (res.data.list.length > 0) {
          that.total = res.data.total;
          that.product_listData = res.data.list;
        }
      });
    },
    getbanner_list() {
      banner_list(11).then(res => {
        // console.log("banner_list3", res);
        this.banner_list = res.data;
      });
    },
    //立即购买
    toBuy(item) {
      const { href } = this.$router.resolve({
        path: "/createOrder",
        query: {
          buy_str: JSON.stringify([{ car_id: 0, pid: item.id, num: 1 }])
        }
      });
      window.open(href, "_blank");
    },
    //查看详情
    toDetail(item) {
      // this.$router.push({ path: "/productDetail", query: { id: item.id } });

      const { href } = this.$router.resolve({
        path: "/productDetail", query: { id: item.id }
      });
      window.open(href, "_blank");
    },
    changeTabs(item, index) {
      this.tabsCurrent = index;
      this.pages.type_two_id = item.id;
      this.getsergive_list();
    },
    turnLeft() {
      if (-this.marginLeft > 1) {
        this.marginLeft = this.marginLeft + 185;
      }
    },
    turnRight() {
      let scrollwidth = this.$refs.tabs.scrollWidth; //包括溢出的部分
      let offsetWidth = this.$refs.tabsbox.offsetWidth; //
      // let boxscrollwidth = this.$refs["box"][0].scrollWidth; //包括溢出的部分
      // console.log("scrollwidth", scrollwidth);
      // console.log("offsetWidth", offsetWidth);
      // console.log("boxscrollwidth",boxscrollwidth);

      if (scrollwidth - offsetWidth > 0) {
        this.marginLeft = this.marginLeft - 185;
      }
    },
    pagesChange(num) {
      this.pages.page = num;
      this.getproduct_list();
    }
  }
};
</script>

<style lang="less" scoped>
.service-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 130px;
}

.header-bimg {
  width: 100%;
  margin-bottom: 40px;

  img {
    width: 100%;
    display: block;
  }
}

.box {
  width: 50%;

  .title {
    text-align: center;
    font-size: 45px;
    font-weight: 500;
    color: #3a3a3a;
  }
}

.product-right {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.model-box-son2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
  margin: 0.6%;
  box-shadow: 0 0 20px #dcdcdc;
  border-radius: 10px;
  flex-shrink: 0;
}

.model-box-left-img {
  width: 100%;
  height: 10rem;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.model-box-left-img img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 10rem;
}

.model-box-left-img .mask {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.model-box-son2:hover .mask {
  height: 10rem;
  background: rgba(000, 000, 000, 0.3);
}

.poleft {
  width: 45px;
  height: 110px;
  position: absolute;
  top: calc(50% - 55px);
  left: -47px;
  /* box-shadow: 0 0 4px #f0f0f0; */
}

.poright {
  width: 45px;
  height: 110px;
  position: absolute;
  top: calc(50% - 55px);
  right: -47px;
  /* box-shadow: 0 0 4px #f0f0f0; */
}

.header-img-model {
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow: hidden;
}

.header-img-model-son-box {
  /* 超出滚动的关键，没有它元素会自动缩小，不会滚动 */
  flex-shrink: 0;
  transition: all 0.6s;
}

.header-img-model-son {
  margin-bottom: 20px;
  width: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #f5f5f5;
}

::-webkit-scrollbar {
  /* 隐藏滚动条 */

  /* display: none; */
}

.header-img-model-son-img {
  display: block;
  width: 100px;
  height: 100px;
}

.header-img-model-son-span {
  font-size: 18px;
  font-family: sanLight;
}

.checkedImg {
  color: #14508c;
}

.model-product {
  width: 100%;
  height: 630px;
}

.model-box {
  display: flex;
}

.product-left {
  width: 42%;
}

.model-box-left-title {
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 20px;

  span:nth-child(1) {
    font-size: 22px;
    font-family: sanMedium;
    font-size: 24px;
    font-weight: bold;
    color: #1c4492;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  span:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    color: #6f7987;
    height: 63px;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.btnss {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;

  button {
    border: none;
    width: 87px;
    height: 32px;
    background: #1c4492;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 400;
    color: #ffffff;
  }

  button:hover {
    cursor: pointer;
  }

  span {
    font-size: 17px;
    font-weight: 400;
    color: #ff3b48;
  }
}

.model-box-left-desc {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 0 20px 20px 20px;
  color: #838383;
  font-size: 14px;
  font-family: sanRegular;
}

.model-box-left-detail {
  text-align: center;
  margin-top: 10px;
}

.model-box-left-detail span {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 80px;
  height: 24px;
  line-height: 24px; */
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  width: 123px;
  height: 44px;
  line-height: 44px;
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #051c35;
  background: #fff;
}

.model-box-left-detail span:hover {
  cursor: pointer;
}

.model-box-left-detail1 {
  text-align: center;
  margin-top: 10px;
}

.model-box-left-detail1 span {
  color: #ffffff;
  background: #14508c;
  display: inline-block;
  width: 80px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 40px;
  font-size: 12px;
}
</style>