<template>
  <div class="CallMe-detail">
    <div class="callmeDetail-container" v-html="content"></div>
  </div>
</template>

<script>
import { calldet } from "@/api/api";
export default {
  data() {
    return {
      id: "",
      content: "",
    };
  },
  mounted() {},
  activated() {
    this.id = this.$route.query.id;
    this.getcalldet();
  },
  methods: {
    //获取详情
    getcalldet() {
      let that = this;
      calldet(that.id).then((res) => {
        // console.log("content", res);
        that.content = res.data.content;
      });
    },
  },
};
</script>

<style>
.CallMe-detail {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.callmeDetail-container {
  padding-top: 30px;
  width: 80%;
}
</style>