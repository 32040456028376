<template>
 
    <baidu-map class="maps" @ready="handler" :center="location" :zoom="14"></baidu-map>
  
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {
    handler({ BMap, map }) {
      var point = new BMap.Point(this.location.lng,this.location.lat);
      map.centerAndZoom(point, 14);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 1, {
        strokeColor: "Red",
        strokeWeight: 1,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03"
      });
      map.addOverlay(circle);
    }
  }
};
</script>

<style>
.maps {
  width: 100%;
  height: 300px;
}
</style>