<template>
  <div class="callmePages">
    <!-- <Header :startWidth="80" :startTop="50" :currentRouter="'/callme'"></Header> -->
    <div class="header-img">
      <img v-if="banner_list.length>0" :src="banner_list.length>0?banner_list[0].ads_pic:''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="header-search">
      <img
        :src="require('@/assets/search.png')"
        style="width: 26px; height: 26px; margin-left: 10px"
      />
      <input type="text" placeholder="请输入需要查询的内容" v-model="search" />
      <span @click="toSearch">搜索</span>
    </div>

    <div class="container">
      <div class="all" @click="toDetail(biz_listData1[0])">
        <div class="left">
          <span>总部</span>
        </div>
        <div class="center">
          <div>
            <span>地址：{{biz_listData1[0].addr}}</span>
          </div>
          <div>
            <span style="margin-right: 120px">咨询热线：{{biz_listData1[0].hot_tel}}</span>
            <span>举报专线：{{biz_listData1[0].hot_tel}}</span>
          </div>
          <div>
            <span>传真：{{biz_listData1[0].fax}}</span>
          </div>
        </div>
        <div class="right">
          <img :src="biz_listData1[0].imgs" alt />
        </div>
      </div>
      <div class="container-box">
        <div class="container-box-title">
          <span>运营中心</span>
        </div>
        <div class="container-model-box">
          <div
            class="container-model-cell"
            v-for="(item, index) in biz_listData2"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="container-model-cell-name">
              <span>{{item.mark}}</span>
            </div>
            <div class="container-model-cell-title">
              <span>{{item.title}}</span>
            </div>
            <div class="hengxian"></div>
            <div class="container-model-addr">
              <span>地址：{{item.addr}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container-box">
        <div class="container-box-title">
          <span>子公司</span>
        </div>
        <div class="container-model-box">
          <div
            class="container-model-cell"
            v-for="(item, index) in biz_listData3"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="container-model-cell-name">
              <span>{{item.mark}}</span>
            </div>
            <div class="container-model-cell-title">
              <span>{{item.title}}</span>
            </div>
            <div class="hengxian"></div>
            <div class="container-model-addr">
              <span>地址：{{item.addr}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <List :listData="listData" :isHorizontal="true"></List> -->
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import { biz_list, banner_list } from "@/api/api";
export default {
  components: {
    Header
  },
  data() {
    return {
      biz_listData1: [],
      biz_listData2: [],
      biz_listData3: [],
      banner_list: [],
      search: ""
    };
  },
  activated() {
    this.getbanner_list();
    this.getbiz_list1();
    this.getbiz_list2();
    this.getbiz_list3();
  },
  methods: {
    toSearch() {
      this.getbiz_list1();
      this.getbiz_list2();
      this.getbiz_list3();
    },
    getbanner_list() {
      banner_list(9).then(res => {
        this.banner_list = res.data;
      });
    },
    //总部
    getbiz_list1() {
      let that = this;
      biz_list({ type: 1}).then(res => {
        this.biz_listData1 = res.data.list;
      });
    },
    //运营中心
    getbiz_list2() {
      let that = this;
      biz_list({ type: 2, search: that.search }).then(res => {
        this.biz_listData2 = res.data.list;
      });
    },
    //子公司
    getbiz_list3() {
      let that = this;
      biz_list({ type: 3, search: that.search }).then(res => {
        this.biz_listData3 = res.data.list;
      });
    },
    toDetail(item) {
      this.$router.push({ path: "/callmeDetail", query: { id: item.id } });
    }
  }
};
</script>

<style lang="less" scoped>
.callmePages {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f7f7;
}
.header-img img {
  width: 100%;
  display: block;
  min-height: 200px;
}

.header-search {
  margin-top: -25px;
  width: 65%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 2px #e2e2e2;
}
.header-search input {
  padding-left: 20px;
  height: 50px;
  border: none;
  width: calc(100% - 120px);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  outline: none;
}
.header-search span {
  display: inline-block;
  width: 120px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #5075ec;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 8px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.header-search span:hover{
  cursor: pointer;
}
.container {
  width: 65%;
}
.all {
  background: #ffffff;
  margin-top: 96px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 144px;
  border: 2px solid #547af8;
  border-radius: 13px;
  .left {
    margin-left: -2px;
    width: 149px;
    height: 142px;
    line-height: 140px;
    background: #547af8;
    border: 2px solid #547af8;
    border-radius: 13px;
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
  }
  .center {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 0 0px 50px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2e2f34;
  }
  .right {
    width: 142px;
    height: 141px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 130px;
      height: 130px;
    }
  }
}
.container-box {
  width: 100%;
}
.container-box-title {
  margin: 92px 0 72px 0;
  text-align: center;
  font-size: 29px;
  font-weight: 500;
  color: #2e2f34;
}
.container-model-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  text-align: center;
}
.container-model-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.33%;
  flex-shrink: 0;
  margin-bottom: 70px;
  /* flex: 4; */
}
.container-model-cell-name span {
  display: inline-block;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  background: #547af8;
  font-size: 32px;
  text-align: center;
  line-height: 115px;
  color: #ffffff;
  margin-bottom: 38px;
}
.container-model-cell-name span:hover {
  background: #ffffff;
  color: #547af8;
  border: 1px solid #547af8;
}
.container-model-cell-title {
  font-size: 19px;
  font-weight: 400;
  color: #2e2f34;
}
.hengxian {
  width: 41px;
  height: 2px;
  background: #3255fa;
  margin: 21px 0 27px 0;
}
.container-model-addr {
  font-size: 14px;
  font-weight: 400;
  color: #7b7d85;
}

.list-box-imgbox {
  width: 888px;
  margin-top: 20px;
  display: flex;

  /* 设置超出滚动 */
  overflow-x: auto;
}
.list-box-imgbox-son {
  width: 377px;
  border-radius: 10px;
  box-shadow: 0 0 5px #5f5f5f;
  /* 超出滚动的关键，没有它元素会自动缩小，不会滚动 */
  flex-shrink: 0;
  margin-right: 12px;
}
::-webkit-scrollbar {
  /* 隐藏滚动条 */

  /* display: none; */
}
</style>