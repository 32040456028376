import { getRequest, postRequest, deleteRequest, postRequestDoc } from '@/config/requestgs.js';


// export const selectEnergyConsumptionByEnterprisePage = params => {
//   return getRequest(`/energy/consumption/selectByEnterprisePage`,params);
// };

//查询证书列表
export const queryCertificateList = (params)=>{
  return postRequest(`/pikachu/certificate/list`,params)
}

//修改证书
export const updateCertificate = (params)=>{
  return postRequest(`/pikachu/certificate/update`,params)
}

//上传证书
export const certificateSave = (params)=>{
  return postRequest(`/pikachu/certificate/save`,params)
}

//删除证书
export const delcertificate = (params)=>{
  return postRequest(`/pikachu/certificate/del`,params)
}

//上传文件
export const certificateUpload = (params)=>{
  return postRequestDoc(`/pikachu/certificate/upload`,params)
}
