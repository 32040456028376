<template>
  <div class="loginpages">
    <div class="container-box">
      <div style="height: 70px; width: 100%"></div>
      <div class="log-img">
        <img src="../../assets/loginlog.png" alt />
      </div>
      <div class="log-text">
        <span>浙江易尤特科技股份有限公司</span>
      </div>
      <div class="login-tabs">
        <!-- <span :class="formItem.type == 0 ? 'checkedTab' : ''" @click="loginType(0)"
          >帐号登录</span
        >-->
        <span
          :class="formItem.type == 1 ? 'checkedTab' : ''"
          @click="loginType(1)"
          >手机号码登录</span
        >
        <span
          :class="formItem.type == 2 ? 'checkedTab' : ''"
          @click="loginType(2)"
          >短信登录</span
        >
      </div>
      <div class="login-form" v-if="formItem.type == 0">
        <div style="margin-bottom: 30px">
          <Input
            size="large"
            v-model="formItem.accent"
            placeholder="请输入帐号"
          />
        </div>
        <div>
          <Input
            size="large"
            type="password"
            v-model="formItem.pwd"
            placeholder="密码"
          />
        </div>
      </div>
      <div class="login-form" v-else-if="formItem.type == 1">
        <div style="margin-bottom: 30px">
          <Input
            size="large"
            v-model="formItem.phone"
            placeholder="请输入手机号"
          />
        </div>
        <div>
          <Input
            size="large"
            type="password"
            v-model="formItem.pwd"
            placeholder="密码"
          />
        </div>
      </div>
      <div class="login-form" v-else-if="formItem.type == 2">
        <div style="margin-bottom: 30px">
          <Input
            size="large"
            v-model="formItem.phone"
            placeholder="请输入手机号"
            maxlength="11"
          />
        </div>
        <div style="display: flex">
          <Input
            size="large"
            v-model="formItem.msg_code"
            placeholder="验证码"
          />
          <Button
            size="large"
            type="primary"
            style="margin-left: 16px"
            :style="
              countDownText == '获取验证码' || countDownText == '重新获取'
                ? 'background-color: #1d4493'
                : 'background-color:#DCDCDC;color:#FFFFFF;border:0;'
            "
            @click="get_codes"
            >{{ countDownText }}</Button
          >
        </div>
      </div>

      <div style="width: 465px">
        <Button
          size="large"
          type="primary"
          style="background-color: #1d4493"
          long
          @click="toLogin"
          >登录</Button
        >
      </div>
      <div class="more">
        <div>
          <span class="hovers" @click="toAdd">立即注册</span>
        </div>
        <div>
          <span style="margin-right: 26px" class="hovers" @click="toQuery"
            >修改密码</span
          >
          <span class="hovers" @click="setPassword">忘记密码</span>
        </div>
      </div>
      <div class="desc">
        <span>注册或点击登录代表您同意《易尤特官网网络服务使用协议》</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { get_code, login, get_user } from "@/api/api";
export default {
  data() {
    return {
      formItem: {
        phone: "", //	19160832579	是	string	手机号
        type: 1, //	2	是	string	登录类型 1密码登录 2短信验证码登录
        msg_code: "", //	706128	否	string	当选择类型未短信验证码登录时必填
        pwd: "", //		否	string	当登录类型未密码登录 必填
      },
      countDownText: "获取验证码",
    };
  },
  created() {},
  mounted() {
    let that = this;
    document.onkeydown = function (e) {
      if (e.keyCode == 13) {
        that.toLogin();
      }
    };
  },
  computed: {
    ...mapState(["userInfo", "isLogin"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    //忘记密码
    setPassword() {
      this.$router.push({ path: "/forgetPassword" });
    },
    //去注册
    toAdd() {
      this.$router.push({ path: "/addPassWord" });
    },
    //去修改密码
    toQuery() {
      this.$router.push({ path: "/queryPassword" });
    },
    loginType(type) {
      this.formItem = {
        phone: "", //	19160832579	是	string	手机号
        type: type, //	2	是	string	登录类型 1密码登录 2短信验证码登录
        msg_code: "", //	706128	否	string	当选择类型未短信验证码登录时必填
        pwd: "", //		否	string	当登录类型未密码登录 必填
      };
    },
    //获取验证码
    get_codes() {
      let that = this;
      if (!that.formItem.phone) {
        that.$Message.error("未输入手机号码");
        return false;
      }
      if (
        that.countDownText == "重新获取" ||
        that.countDownText == "获取验证码"
      ) {
        get_code({ phone: that.formItem.phone, type: 2 }).then((res) => {
          if (res.code == 200) {
            that.$Message.success(res.message);
          }
        });
        that.countDownText = 60;
        let time = setInterval(function () {
          that.countDownText = that.countDownText - 1;
          if (that.countDownText < 1) {
            clearInterval(time);
            that.countDownText = "重新获取";
          }
        }, 1000);
      }
    },
    toLogin() {
      let that = this;
      if (!that.formItem.phone) {
        that.$Message.error("未输入手机号码");
        return false;
      }
      if (that.formItem.type == 2) {
        if (!that.formItem.msg_code) {
          that.$Message.error("未输入验证码");
          return false;
        }
      } else if (that.formItem.type == 1) {
        if (!that.formItem.pwd) {
          that.$Message.error("未输入密码");
          return false;
        }
      }

      login(that.formItem).then((res) => {
        if (res.code == 200) {
          that.$Message.success("登录成功");
          let mobile = res.data.mobile;
          res.data.phone = mobile.substr(0, 3) + "****" + mobile.substr(7);
          localStorage.setItem("accessToken", res.token);
          get_user().then((item) => {
            let datas={...res.data,integral:item.data.integral}
            localStorage.setItem("userInfo", JSON.stringify(datas));
            that.$store.commit("setUserInfo", datas);
            that.formItem = {
              phone: "", //	19160832579	是	string	手机号
              type: 1, //	2	是	string	登录类型 1密码登录 2短信验证码登录
              msg_code: "", //	706128	否	string	当选择类型未短信验证码登录时必填
              pwd: "", //		否	string	当登录类型未密码登录 必填
            };
            setTimeout(() => {
              that.$router.push({ path: "/mine" });
            }, 1600);
          });
        }
      });
    },
    //跳转到个人信息
    toMine() {
      let that = this;
      that.$parent.isShow = false;
      that.$parent.getBanner_list("", "", ""); //
      that.$router.push({ path: "/mine" });
    },
  },
};
</script>

<style scoped>
.hovers:hover {
  cursor: pointer;
}

.loginpages {
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
}

.headerbox {
  width: 100%;
  display: flex;
  background: #1c4492;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 9999;
}

.tabChecked {
  border-bottom: 1px solid #ffffff;
  color: #2f4373;
}

.header-bar {
  /* background: #ffffff; */
  /* box-shadow: 0 0 5px #adadad; */
  overflow: hidden;
  color: #494949;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-not-box-right {
  width: 70%;
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-bar-span {
  display: flex;
  align-items: center;
  font-family: sanNormal;
  color: #ffffff;
  font-size: 1.21rem;
}

.header-bar-span:hover {
  cursor: pointer;
}

.goin {
  display: inline-block;
  text-align: center;

  color: #ffffff;
  transition: all 0.5s;

  width: 152px;
  height: 55px;
  display: flex;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 5px;
}

.container-box {
  margin-top: 80px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.log-img {
}

.log-img img {
  display: block;
  width: 130px;
}

.log-text {
  text-align: center;
  font-size: 35px;
  font-family: sanMedium;
  color: black;
  margin: 30px 0;
}

.login-tabs span {
  display: inline-block;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  font-family: sanRegular;
  color: rgb(134, 134, 134);
  font-size: 16px;
  border-bottom: 3px solid #cecece;
}

.checkedTab {
  color: black !important;
  border-bottom: 3px solid #104c8b !important;
}

.login-form {
  width: 465px;
  padding: 40px 0;
}

.more {
  margin: 30px 0;
  width: 465px;
  font-size: 18px;
  font-weight: 400;
  color: #328fff;
  display: flex;
  justify-content: space-between;
}

.desc {
  font-size: 16px;
  font-weight: 400;
  color: #b1b1b1;
}
</style>