<template>
  <div class="indexpages">
    <div class="header-img">
      <img v-if="banner_list.length > 0" :src="banner_list.length > 0 ? banner_list[0].ads_pic : ''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="scroll-box">
      <div class="boxs">
        <span class="title">签约用户：</span>
        <!-- <vue-seamless-scroll :data="scrollData" :class-option="optionLeft" class="seamless-warp2">
          <ul class="item">
            <li v-for="(item, index) in scrollData" v-text="item.content" :key="index"></li>
          </ul>
        </vue-seamless-scroll> -->
        <div class="messageBox">
          <bar :sendVal="scrollData"></bar>
        </div>
      </div>
    </div>


    <!-- //产品结构 -->
    <div class="containers" ref="jgModel"
      :style="{ paddingTop: `${jgShow ? '100' : '200'}px`, opacity: `${jgShow ? '1' : '0'}` }">
      <div class="title">
        <div class="span">产品结构</div>
        <div class="line"></div>
        <div class="text">
          <span>{{ type_descData1.content }}</span>
        </div>
      </div>
      <div class="model-box">
        <div class="cell" v-for="(item, index) in type_contentData.jg" :key="index">
          <div class="top">
            <img :src="item.imgs" alt />
          </div>
          <div class="bottom">
            <div class="text" v-html="item.desc">
              <!-- <span>{{item.desc}}</span> -->
            </div>
            <div class="btn">
              <button @click="toSDetail">了解详情</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 我们的优势 -->
    <div class="myBest">
      <div class="container" ref="ysModel"
        :style="{ paddingTop: `${ysShow ? '0' : '100'}px`, opacity: `${ysShow ? '1' : '0'}` }">
        <div class="title">
          <div class="span">我们的优势</div>
          <div class="line"></div>
          <div class="text">
            <span>{{ type_descData2.content }}</span>
          </div>
        </div>
        <div class="model-box">
          <div class="cell" v-for="(item, index) in advantageData" :key="index" @mousemove="mousemoveYS(index)"
            @mouseleave="ysCurrent = ''" @click="toYsDetail(item)">
            <div class="left">
              <img :src="ysCurrent === index ? item.imgs : item.imgs_two" alt />
            </div>
            <div class="right">
              <span>{{ item.title }}</span>
              <span v-html="item.desc"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 荣誉证书 -->
    <div class="card" ref="ryModel"
      :style="{ paddingTop: `${ryShow ? '0' : '100'}px`, opacity: `${ryShow ? '1' : '0'}` }">
      <div class="cell" v-for="(item, index) in advantage2Data" :key="index">
        <div class="top">
          <img :src="item.imgs" alt />
          <div class="text">
            <span class="t1">{{ item.title }}</span>
            <span class="t2">{{ item.desc }}</span>
          </div>
        </div>
        <img class="imgs" :src="item.imgs_two" alt />
      </div>
    </div>

    <!-- 主营产品 -->
    <div class="containers3" ref="zyModel"
      :style="{ paddingTop: `${zyShow ? '100' : '200'}px`, opacity: `${zyShow ? '1' : '0'}` }">
      <div class="title">
        <div class="span">主营产品</div>
        <div class="line"></div>
        <div class="text">
          <span>{{ type_descData3.content }}</span>
        </div>
      </div>
      <div class="model-box">
        <div class="cell" v-for="(item, index) in type_contentData.zy" :key="index">
          <div class="top">
            <img :src="item.imgs" alt />
          </div>
          <div class="bottom">
            <div class="text">
              <span class="t1">{{ item.title }}</span>
              <span class="t2" v-html="item.desc"></span>
            </div>
            <div class="btn">
              <div class="button" @click="tozDetail">
                <span>了解详情</span>
                <img src="@/assets/newImg/lo1.jpg" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 集团介绍 -->

    <div class="jt-Info" ref="jtModel"
      :style="{ paddingTop: `${jtShow ? '140' : '240'}px`, opacity: `${jtShow ? '1' : '0'}` }">
      <div class="title">
        <div class="span">集团介绍</div>
        <div class="line"></div>
        <div class="text">
          <span>{{ type_descData4.content }}</span>
        </div>
      </div>
      <div class="jt-Info-container">
        <div class="img-box">
          <img :src="type_contentData.jt[0].img_two" alt />
        </div>
        <div class="container" ref="containerbox">
          <div class="top">
            <div class="left">
              <img class="topimg" src="@/assets/newImg/lefttop.png" alt />
              <video :src="type_contentData.jt[0].imgs" controls></video>
              <img class="bottomimg" src="@/assets/newImg/leftbottom.png" alt />
              <!-- <Carousel
                trigger="hover"
                style="width:500px;overflow: hidden;"
                easing
                loop
                class="Carousels"
              >
                <CarouselItem
                  class="CarouselsItem"
                  v-for="(item, index) in type_contentData.jt[0].imgs"
                  :key="index"
                >
                  <video style="width:100%" :src="type_contentData.jt[0].imgs" controls></video>
                </CarouselItem>
              </Carousel>-->
            </div>
            <div class="right">
              <div v-html="type_contentData.jt[0].content"></div>
            </div>
          </div>
          <div class="center">
            <div class="cell" v-for="(item, index) in type_contentData.jt[0].other_des" :key="index">
              <div class="top">
                <span class="span1">{{ item.val }}</span>
                <span class="span2">{{ item.dw }}</span>
              </div>
              <span class="bottom">{{ item.title }}</span>
            </div>
          </div>
          <div class="bottom">
            <div class="titles">
              <span>董事长寄语</span>
            </div>
            <div class="contaners">
              <div class="left">
                <div v-html="type_contentData.jy[0].content"></div>
              </div>
              <div class="right">
                <div class="boxss">
                  <img class="topimg" src="@/assets/newImg/righttop.png" alt />
                  <img class="bottomimg" src="@/assets/newImg/rightbottom.png" alt />
                  <img class="avatur" :src="type_contentData.jy[0].imgs" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 企业动态 -->
    <div class="myBest2" ref="dtModel"
      :style="{ paddingTop: `${dtShow ? '100' : '200'}px`, opacity: `${dtShow ? '1' : '0'}` }">
      <div class="container">
        <div class="title">
          <div class="span">企业动态</div>
          <div class="line"></div>
          <div class="text">
            <span>{{ type_descData5.content }}</span>
          </div>
        </div>
        <div class="myBest2-tabs">
          <div class="cells" v-for="(item, index) in newType_list" :key="index">
            <span @click="changetabs(index, item)" :style="tabsCurrent == index ? 'background:#E33929' : ''">{{
              item.names
            }}</span>
          </div>
        </div>
        <div class="model-box">
          <div class="left">
            <img :src="news_listData[0].imgs" alt />
          </div>
          <div class="right">
            <div class="cells" v-for="(item, index) in news_listData" :key="index" @click="toqyDetail(item)">
              <span>{{ item.title }}</span>
              <span>{{ item.show_date }}</span>
            </div>
          </div>
        </div>
        <!-- <div style="text-align: right; margin-top: 20px">
          <Page
            :total="total"
            show-total
            @on-change="news_listpageChange"
            :current="news_listQuery.page"
            :page-size="5"
          />
        </div>-->
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="friends" ref="hbModel"
      :style="{ paddingTop: `${hbShow ? '180' : '280'}px`, opacity: `${hbShow ? '1' : '0'}` }">
      <div class="container">
        <div class="title">
          <div class="span">合作伙伴</div>
          <div class="line"></div>
          <div class="text">
            <span>{{ type_descData6?type_descData6.content: ''}}</span>
          </div>
        </div>
      </div>

      <div class="cell-imgs">
        <!-- <div class="cell" v-for="(item,index) in banner_list15" :key="index">
          <img :src="itemSon.ads_pic" v-for="(itemSon,indexSon) in item" :key="indexSon" alt />
        </div>-->
        <div class="imgs-boxs" ref="imgsBox" :style="{ transform: `translateX(${leftNum}px)` }">
          <div class="cell" v-for="(item, index) in banner_list15" :key="index">
            <img :src="itemSon.ads_pic" v-for="(itemSon, indexSon) in item" :key="indexSon" alt />
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <button>查看更多>></button>
      </div>-->
    </div>
    <div class="form-info" ref="bdModel"
      :style="{ paddingTop: `${bdShow ? '100' : '200'}px`, opacity: `${bdShow ? '1' : '0'}` }">
      <div class="title">
        <span>您可以提交对我们公司产品的需求，或预约来我们公司参观面谈</span>
        <span>我们会在第一时间回复您！</span>
      </div>
      <div class="form-container">
        <div class="cell">
          <span>姓名:</span>
          <input v-model="formItem.name" style="padding-left:15px;color:#ffffff" type="text" />
        </div>
        <div class="cell">
          <span>手机号码:</span>
          <input v-model="formItem.tel" style="padding-left:15px;color:#ffffff" type="text" />
        </div>
        <div class="cell">
          <span>在线留言:</span>
          <textarea v-model="formItem.content" style="padding:15px;color:#ffffff" name id cols="30"
            rows="10"></textarea>
        </div>
      </div>
      <div class="btn">
        <button @click="postAddx">提交</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  banner_list,
  note_list,
  advantage,
  num_list,
  newType_list,
  news_list,
  links_list,
  structure_type,
  type_desc,
  ring_det,
  type_content,
  add_feed
} from "@/api/api";
import vueSeamlessScroll from "vue-seamless-scroll";
import bar from "@/components/bar/bar.vue";
export default {
  components: {
    vueSeamlessScroll,
    bar
  },
  data() {
    return {
      scrollData: [
        {
          time: "2022-09-01",
          content: "商业安全中心某某某成功签约职业病1单 "
        },
      ],
      tabsCurrent: 0,
      banner_list: [],
      banner_list15: [],
      note_list2: [],
      structureData: [],
      advantageData: [], //我们的优势
      advantage2Data: [], //荣誉证书
      num_listData: [], //主营产品
      newType_list: [],
      news_listData: [],
      news_listQuery: {
        page: 1, //否	string	无
        pz: 5, //	20	否	string	无
        type_two_id: 0, //	34	是	string	服务类型一级级类型id
        search: "" //	湖北省黄冈市	否	string	搜索关键字
      },
      total: 0,
      type_descData1: {},
      type_descData2: {},
      type_descData3: {},
      type_descData4: {},
      type_descData5: {},
      type_descData6: {},
      ring_detData1: {},
      ring_detData2: {},
      type_contentData: {},
      ysCurrent: "",
      formItem: {
        tel: "",
        name: "",
        content: ""
      },
      leftNum: 0,
      scrollTop: 0,
      jgShow: false,
      jgH: 0,
      ysShow: false,
      ysH: 0,
      ryShow: false,
      ryH: 0,
      zyShow: false,
      zyH: 0,
      jtShow: false,
      jtH: 0,
      dtShow: false,
      dtH: 0,
      hbShow: false,
      hbH: 0,
      bdShow: false,
      bdH: 0,
    };
  },
  mounted() {
    let that = this;
    this.getbanner_list();
    this.getbanner_list15();
    // this.getstructure_type();
    this.getnote_listt();
    this.getadvantage1();
    this.getadvantage2();
    // this.getnum_list();
    this.getnewType_list();
    this.gettype_desc1();
    this.gettype_desc2();
    this.gettype_desc3();
    this.gettype_desc4();
    this.gettype_desc5();
    this.gettype_desc6();
    this.getring_det2();
    this.getring_det1();
    this.gettype_content();
    this.playLeftScroll();

    this.$nextTick(() => {
      window.addEventListener("scroll", that.handleScroll, true);
      window.addEventListener("scroll", that.getJgModel, true);
    });
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.getJgModel);
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 1
      };
    }
  },
  watch: {
    scrollTop(newValue, oldVlue) {
      let that = this;
      let jgH = that.jgH;
      let ysH = that.ysH;
      let ryH = that.ryH;
      let zyH = that.zyH;
      let jtH = that.jtH;
      let dtH = that.dtH;
      let hbH = that.hbH;
      let bdH = that.bdH;
      // console.log("newValue", newValue, oldVlue);
      if (newValue > oldVlue) {
        //向下
        if (newValue > jgH && jgH != 0) {
          that.jgShow = true;
        }
        if (newValue > ysH && ysH != 0) {
          that.ysShow = true;
        }
        if (newValue > ryH && ryH != 0) {
          that.ryShow = true;
        }
        if (newValue > zyH && zyH != 0) {
          that.zyShow = true;
        }
        if (newValue > jtH && jtH != 0) {
          that.jtShow = true;
        }
        if (newValue > dtH && dtH != 0) {
          that.dtShow = true;
        }
        if (newValue > hbH && hbH != 0) {
          that.hbShow = true;
        }
        if (newValue > bdH && bdH != 0) {
          that.bdShow = true;
        }
      } else {
        //向上
        if (newValue < jgH && jgH != 0) {
          that.jgShow = false;
        }
        if (newValue < ysH && ysH != 0) {
          that.ysShow = false;
        }
        if (newValue < ryH && ryH != 0) {
          that.ryShow = false;
        }
        if (newValue < zyH && zyH != 0) {
          that.zyShow = false;
        }
        if (newValue < jtH && jtH != 0) {
          that.jtShow = false;
        }
        if (newValue < dtH && dtH != 0) {
          that.dtShow = false;
        }
        if (newValue < hbH && hbH != 0) {
          that.hbShow = false;
        }
        if (newValue < bdH && bdH != 0) {
          that.bdShow = false;
        }
      }
    }
  },
  methods: {
    toSDetail() {
      this.$router.push({ path: "/productStructure" });
    },
    tozDetail() {
      this.$router.push({ path: "/digitalproducts" });
    },
    getJgModel(e) {
      let wh = window.innerHeight / 2 + 100;
      this.jgH = this.$refs.jgModel.offsetTop - wh;
      this.ysH = this.$refs.ysModel.offsetTop - wh;
      this.ryH = this.$refs.ryModel.offsetTop - wh;
      this.zyH = this.$refs.zyModel.offsetTop - wh;
      this.jtH = this.$refs.jtModel.offsetTop - wh;
      this.dtH = this.$refs.dtModel.offsetTop - wh;
      this.hbH = this.$refs.hbModel.offsetTop - wh;
      this.bdH = this.$refs.bdModel.offsetTop - wh;
    },
    //滚动事件
    handleScroll() {
      let that = this;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
    },
    playLeftScroll() {
      let that = this;
      setInterval(() => {
        that.leftNum -= 1;
        if (that.leftNum < -310) {
          that.leftNum = -10;
          that.banner_list15.push(that.banner_list15.shift());
        }
      }, 10);
    },
    mousemoveYS(index) {
      this.ysCurrent = index;
    },
    //企业动态切换tab
    changetabs(index, item) {
      this.tabsCurrent = index;
      this.news_listQuery = {
        page: 1, //否	string	无
        pz: 5, //	20	否	string	无
        type_two_id: item.id, //	34	是	string	服务类型一级级类型id
        search: "" //	湖北省黄冈市	否	string	搜索关键字
      };
      this.getnews_list();
    },
    toqyDetail(item) {
      if (item.w_link == 1 || item.w_link == "") {
        //跳内部富文本
        this.$router.push({ path: "/newDetail", query: { id: item.id } });
        //  let router = this.$router.resolve({
        //   path: "/newDetail",
        //   query: { id: item.id },
        // });
        // window.open(router.href, "_blank");
      } else {
        window.open(item.w_link, "_blank");
        //跳外部链接
      }
    },
    toYsDetail(item) {

      if (item.content) {
        let routeUrl = this.$router.resolve({
          path: "/ysDetail",
          query: { id: item.id }
        });
        window.open(routeUrl.href, '_blank');
        // this.$router.push({ path: "/ysDetail",  });
      }
    },

    //头部图
    getbanner_list() {
      banner_list(1).then(res => {
        this.banner_list = res.data;
      });
    },
    //合作伙伴
    getbanner_list15() {
      banner_list(15, 1).then(res => {
        this.banner_list15 = res.data;
        // console.log("banner_list15", this.banner_list15);
      });
    },
    //公告栏
    getnote_listt() {
      note_list(1).then(res => {
        // console.log("scrollData", res);
        let arr = [];
        if (res.data.length < 2) {
          for (let i = 0; i < 6; i++) {
            arr.push(res.data[0])
          }
        } else {
          arr=res.data;
        }
        this.scrollData = arr;
      });
    },
    //产品结构
    // getstructure_type() {
    //   structure_type().then(res => {
    //     console.log("structure", res);
    //     this.structureData = res.data.list;
    //   });
    // },
    //我们的优势
    getadvantage1() {
      advantage(1).then(res => {
        // console.log("advantageData", res);
        this.advantageData = res.data.list;
      });
    },
    //荣誉证书
    getadvantage2() {
      advantage(2).then(res => {
        // console.log("advantageData2", res);
        this.advantage2Data = res.data.list;
      });
    },
    //主营产品
    getnum_list() {
      num_list(2).then(res => {
        // console.log("num_listData", res);
        this.num_listData = res.data.list;
      });
    },
    //集团介绍
    getring_det2() {
      let that = this;
      ring_det(2).then(res => {
        // console.log("ring_det2", res);
        that.ring_detData2 = res.data.list[0];
      });
    },
    //集团介绍
    getring_det1() {
      let that = this;
      ring_det(1).then(res => {
        // console.log("ring_det1", res);
        that.ring_detData1 = res.data.list[0];
      });
    },
    //企业动态分类
    getnewType_list() {
      newType_list().then(res => {
        // console.log("newType_list", res);
        this.newType_list = res.data.list;
        this.news_listQuery = {
          page: 1, //否	string	无
          pz: 5, //	20	否	string	无
          type_two_id: res.data.list[0].id, //	34	是	string	服务类型一级级类型id
          search: "" //	湖北省黄冈市	否	string	搜索关键字
        };
        this.getnews_list();
      });
    },
    //企业动态列表
    getnews_list() {
      news_list(this.news_listQuery).then(res => {
        // console.log("news_listData", res);
        this.news_listData = res.data.list;
        this.total = res.data.total;
      });
    },
    //频道
    gettype_desc1() {
      type_desc(1).then(res => {
        // console.log("type_descData", res);
        this.type_descData1 = res.data;
      });
    },
    //频道
    gettype_desc2() {
      type_desc(2).then(res => {
        // console.log("type_descData", res);
        this.type_descData2 = res.data;
      });
    },
    //频道
    gettype_desc3() {
      type_desc(3).then(res => {
        // console.log("type_descData", res);
        this.type_descData3 = res.data;
      });
    },
    //频道
    gettype_desc4() {
      type_desc(4).then(res => {
        // console.log("type_descData", res);
        this.type_descData4 = res.data;
      });
    },
    //频道
    gettype_desc5() {
      type_desc(5).then(res => {
        // console.log("type_descData", res);
        this.type_descData5 = res.data;
      });
    },
    //频道
    gettype_desc6() {
      type_desc(6).then(res => {
        // console.log("type_descData", res);
        this.type_descData6 = res.data;
      });
    },
    //首页内容
    gettype_content() {
      type_content().then(res => {
        // console.log("type_contentData", res);
        this.type_contentData = res.data;
      });
    },
    news_listpageChange(e) {
      let that = this;
      that.news_listQuery.page = e;
      that.getnews_list();
    },
    postAddx() {
      let that = this;
      let formItem = that.formItem;
      for (let i in formItem) {
        if (formItem[i] == "") {
          that.$Message.error("表单为填写完整");
          return false;
        }
      }
      this.$Modal.confirm({
        title: "提示",
        content: "确认提交需求？",
        onOk: () => {
          that.postadd_feed();
        },
        onCancel: () => { }
      });
    },
    postadd_feed() {
      let that = this;
      add_feed(that.formItem).then(res => {
        that.$Message.success(res.message);
        that.formItem = { tel: "", name: "", content: "" };
      });
    }
  }
};
</script>

<style lang="less" scoped>
.header-img img {
  width: 100%;
  display: block;
}

.indexpages {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .scroll-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
    background: #f2f1f1;

    .boxs {
      width: 60%;
      display: flex;
      align-items: center;

      .title {
        flex-shrink: 0;
        display: inline-block;
        width: 100px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fd4431;
      }

      .seamless-warp2 {
        overflow: hidden;
        width: calc(100% - 100px);

        ul.item {
          width: 100%;

          li {
            float: left;
            margin-right: 10px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #fd7431;
          }
        }
      }
    }
  }

  .containers {
    transition: all 1.5s;
    width: 60%;
    background: #ffffff;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .span {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .line {
        width: 48px;
        height: 3px;
        background: #1c4492;
        margin: 20px 0 35px 0;
      }

      .text {
        text-align: center;
        width: 60%;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #888888;
      }
    }

    .model-box {
      margin-top: 80px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .cell {
        width: 32%;
        // height: 377px;
        background: #ffffff;
        margin-bottom: 50px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        border-radius: 33px 33px 0px 0px;
        border: 1px solid #ffffff;

        .top {
          width: 100%;
          // height: 131px;
          border-radius: 33px 33px 0px 0px;

          img {
            width: 100%;
            display: block;
          }
        }

        .bottom {
          display: flex;
          flex-direction: column;
          align-items: center;

          .text {
            height: 120px;
            text-align: center;
            // margin: 25px 0;
            margin: 10% 0;
            width: 50%;
            line-height: 40px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #323131;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            span {
              overflow: hidden;
            }
          }

          .btn {
            width: 100%;
            text-align: center;
            margin-bottom: 9%;

            button {
              width: 80%;
              // height: 55px;
              // height: 15%;
              padding: 4% 0;
              border: 1px solid #fc3d2c;
              border-radius: 8px;
              background: #ffffff;
              font-size: 22px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ff6a4c;
            }
          }
        }
      }

      .cell:hover {
        border: 1px solid #3b64ea;

        .bottom {
          .btn {
            button {
              background: linear-gradient(90deg, #fc3d2c 0%, #ff6f50 100%);
              font-size: 22px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .myBest {
    padding-top: 100px;
    margin-top: 50px;
    width: 100%;
    height: 862px;
    background: #0e1751;
    display: flex;
    justify-content: center;

    .container {
      transition: all 1.5s;
      width: 60%;

      // background: #ffffff;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .span {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }

        .line {
          width: 48px;
          height: 3px;
          background: #ffffff;
          margin: 20px 0 35px 0;
        }

        .text {
          text-align: center;
          width: 60%;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .model-box {
        margin-top: 80px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .cell {
          width: 32%;
          height: 205px;
          background: #272f63;
          margin-bottom: 20px;
          padding: 34px 20px 0 20px;
          display: flex;

          .left {
            padding-top: 10px;
            margin-right: 15px;

            img {
              width: 86px;
              height: 86px;
              border-radius: 50%;
            }
          }

          .right {
            display: flex;
            flex-direction: column;

            span:nth-child(1) {
              font-size: 19px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 20px;
            }

            span:nth-child(2) {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #9397b1;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }

        .cell:hover {
          background: #ffffff !important;
        }

        .cell:hover .right span {
          color: #333333 !important;
        }
      }
    }
  }

  .card {
    transition: all 1.5s;
    margin-top: 20px;
    width: 100%;
    padding: 0 20px;
    display: flex;

    .cell {
      width: calc(100% / 7);
      padding: 20px;

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        img {
          width: 17%;
          // height: 37px;
        }

        .text {
          display: flex;
          flex-direction: column;

          .t1 {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .t2 {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .imgs {
        width: 100%;
      }
    }
  }

  //主营产品
  .containers3 {
    transition: all 1.5s;
    width: 80%;
    background: #ffffff;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .span {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .line {
        width: 48px;
        height: 3px;
        background: #1c4492;
        margin: 20px 0 35px 0;
      }

      .text {
        text-align: center;
        width: 60%;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #888888;
      }
    }

    .model-box {
      margin-top: 80px;
      width: 100%;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .cell {
        width: calc(100% / 5 - 22px);
        margin: 0 11px;
        // height: 377px;
        background: #ffffff;

        // margin-bottom: 50px;
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        // border-radius: 33px 33px 0px 0px;
        .top {
          width: 100%;

          // height: 131px;
          // border-radius: 33px 33px 0px 0px;
          img {
            width: 100%;
            // min-height: 200px;
            display: block;
          }
        }

        .bottom {
          border: 1px solid #c6c6c6;
          border-top: none;
          display: flex;
          flex-direction: column;
          align-items: center;

          .text {
            text-align: center;

            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #323131;
            display: flex;
            flex-direction: column;

            .t1 {
              height: 66px;
              margin: 20px 0;
              text-align: center;
              font-size: 22px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .t2 {
              // height: 72px;
              text-align: center;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          .btn {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 5% 0 6% 0;

            .button {
              width: 50%;
              // height: 55px;
              // height: 15%;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3784ec;
              background: #ffffff;
              border: 1px solid #4196fd;
              display: flex;
              justify-content: space-between;
              align-items: center;

              span {
                display: block;
                padding-left: 10%;
              }

              img {
                width: 30%;
              }
            }

            .button:hover {
              cursor: pointer;
            }
          }
        }
      }

      .cell:hover {
        .bottom {
          background: #e5eefc;

          .btn {
            .button {
              background: #3784ec;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .jt-Info {
    transition: all 1.5s;
    width: 100%;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .span {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .line {
        width: 48px;
        height: 3px;
        background: #1c4492;
        margin: 20px 0 35px 0;
      }

      .text {
        text-align: center;
        width: 60%;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #888888;
      }
    }

    .jt-Info-container {
      margin-top: 90px;
      position: relative;

      .img-box {
        width: 100%;
        height: 1100px;

        img {
          width: 100%;
          display: block;
          z-index: 0;
        }
      }

      .container {
        padding: 50px;
        position: absolute;
        top: 100px;
        left: calc(50% - 30%);
        width: 60%;
        background: #ffffff;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);

        .top {
          display: flex;
          justify-content: space-between;

          .left {
            position: relative;
            margin-left: -99px;
            margin-right: 30px;
            width: 450px;
            height: 200px;
            flex-shrink: 0;

            img {
              width: 50px;
            }

            .topimg {
              position: absolute;
              top: -28px;
              left: -1px;
            }

            video {
              position: absolute;
              width: 450px;
              height: 200px;
              background: #000000;
              z-index: 1;
            }

            .bottomimg {
              position: absolute;
              bottom: -28px;
              left: -1px;
            }
          }

          .right {
            height: 167px;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
            // overflow: hidden;
            // flex-shrink: 0;
          }
        }

        .center {
          padding: 60px 0;
          display: flex;

          .cell {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .top {
              display: flex;
              align-items: center;
              justify-content: center;

              .span1 {
                display: inline-block;
                font-size: 52px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #3b75ff;
              }

              .span2 {
                margin-left: 5px;
                margin-top: 10px;
                display: inline-block;
                font-size: 25px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #3b75ff;
              }
            }

            .bottom {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #2e2f34;
            }
          }
        }

        .bottom {
          .titles {
            text-align: left;
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #2e2f34;
          }

          .contaners {
            display: flex;
            justify-content: space-between;

            .left {
              width: 65%;
              padding: 20px 0;
              height: 330px;
              overflow: hidden;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 13;
              -webkit-box-orient: vertical;
            }

            .right {
              width: 35%;
              margin-right: -100px;
              float: right;

              .boxss {
                position: relative;

                .avatur {
                  width: 100%;
                  display: block;
                  z-index: 1;
                }

                .bottomimg {
                  // z-index: 1;
                  display: block;
                  position: absolute;
                  width: 50px;
                  right: 0;
                  bottom: -28px;
                }

                .topimg {
                  width: 50px;
                  display: block;
                  position: absolute;
                  right: 0;
                  top: -28px;
                }
              }
            }
          }
        }
      }
    }
  }

  // 企业动态
  .myBest2 {
    transition: all 1.5s;
    padding-bottom: 100px;
    margin-top: 50px;
    width: 100%;
    // height: 862px;
    background: #2d3350;
    display: flex;
    justify-content: center;

    .container {
      width: 60%;

      // background: #ffffff;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .span {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }

        .line {
          width: 48px;
          height: 3px;
          background: #ffffff;
          margin: 20px 0 35px 0;
        }

        .text {
          text-align: center;
          width: 60%;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .myBest2-tabs {
        width: 100%;
        display: flex;
        margin: 60px 0;

        .cells:hover {
          cursor: pointer;
        }

        .cells {
          flex: 1;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          padding: 2px;

          span {
            display: block;
            border: 1px solid #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .model-box {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left {
          width: 41%;

          img {
            width: 100%;
            display: block;
          }
        }

        .right {
          width: 56%;
          height: 329px;

          .cells:hover {
            cursor: pointer;
          }

          .cells {
            padding: 4% 0;
            border-bottom: 1px solid #e5e5e5;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #e5e5e5;
            display: flex;
            justify-content: space-between;

            span:nth-child(1) {
              display: inline-block;
              width: 75%;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          .cells:nth-child(1) {
            padding-top: 0;
          }

          .cells:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }
  }

  // 合作伙伴
  .friends {
    transition: all 1.5s;
    padding-bottom: 180px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
      width: 60%;

      // background: #ffffff;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .span {
          font-size: 36px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }

        .line {
          width: 48px;
          height: 3px;
          margin: 20px 0 35px 0;
          background: #1c4492;
        }

        .text {
          text-align: center;
          width: 60%;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #888888;
        }
      }
    }

    .cell-imgs {
      width: 95%;
      margin: 97px 75px;

      position: relative;
      overflow: hidden;

      .leftM {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 20%;
        // filter: blur(20px);
        // background: linear-gradient(rgb(255, 255, 255),rgba(255, 255, 255, 0.2));
        // background: rgb(255, 255, 255);
        z-index: 1;
      }

      .rightM {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        width: 20%;
        height: 100%;
        background: #14508c;
      }

      // justify-content: space-between;
      .imgs-boxs {
        // width: 100%;
        display: flex;
        flex-wrap: nowrap;

        .cell {
          flex-shrink: 0;
          width: 300px;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            // width: 96%;
            display: block;
            border: 1px solid #cbcbcb;
            margin: 6px 0;
            width: 285px;
            height: 123px;
          }
        }
      }
    }

    .btn {
      text-align: center;

      button {
        width: 171px;
        height: 49px;
        background: #3769e8;
        border-radius: 5px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        border: none;
      }
    }
  }

  .form-info {
    transition: all 1.5s;
    width: 100%;
    background: #174483;
    padding: 0 0 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }

    .form-container {
      margin-top: 100px;
      width: 60%;

      .cell {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        input {
          width: 100%;
          background: none;
          height: 38px;
          line-height: 38px;
          border-radius: 4px;
          outline: none;
          border: 1px solid #ffffff;
        }

        span {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          display: block;
          width: 90px;
          color: #ffffff;
          text-align: justify;
          text-align-last: justify;
          flex-shrink: 0;
          margin-right: 15px;
        }

        textarea {
          border: 1px solid #ffffff;
          background: none;
          width: 100%;
          outline: none;
        }
      }

      .cell:last-child {
        align-items: flex-start !important;
      }
    }

    .btn {
      button {
        width: 201px;
        height: 61px;
        border: 1px solid #ffffff;
        border-radius: 5px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        background: #174483;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.ivu-input {
  border: none !important;
  background-color: none !important;
  border-bottom: 1px solid #ffffff !important;
  border-radius: 0 !important;
  height: 40px !important;
  font-size: 20px !important;
  color: #ffffff !important;
}

.ivu-input-default {
  background-color: #000000 !important;
}

.messageBox {
  width: 100%;
  overflow: hidden;
}
</style>