<template>
  <!-- 数字产品 -->
  <div class="digitalProducts">
    <!-- <Header :startWidth="80" :startTop="50" :currentRouter="'/digitalproducts'"></Header> -->
    <div class="header-bimg">
      <img v-if="banner_list.length > 0" :src="banner_list.length > 0 ? banner_list[0].ads_pic : ''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="header-img" ref="box">
      <img class="poleft" @click="turnLeft" src="@/assets/leftus.jpg" alt />
      <div class="header-img-model" ref="imgmodel">
        <div ref="imgbox" class="header-img-model-son-box" v-for="(item, index) in num_listData" :key="index"
          :style="index == 0 ? 'margin-left:' + marginLeft + 'px' : ''">
          <!-- <div class="header-img-model-son" > -->
          <img class="header-img-model-son-img" :src="item.imgs" @click="changeTabs(index, item, item.id)" alt />
          <!-- <div v-else></div>
            <span class="header-img-model-son-span">
              {{
              item.title
              }}
          </span>-->
          <!-- </div> -->
        </div>
      </div>
      <img class="poright" @click="turnRight" src="@/assets/rightus.jpg" alt />
    </div>

    <!-- <div style="height:45px" v-if="pFixed"></div> -->
    <div style="width: 100%;display:flex;justify-content: center;">
      <digital-product-box @changeBox="changeBox" :cellData="cellData"></digital-product-box>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import DigitalProductBox from "./components/DigitalProductBox/DigitalProductBox.vue";
import { num_list, num_type_list, banner_list } from "@/api/api";
export default {
  components: {
    DigitalProductBox,
    Header
  },
  data() {
    return {
      banner_list: [],
      tabsTopCurrent: 0,
      bannerCurrent: 0,
      topCurrent: "top",
      pFixed: false,
      marginLeft: 0,
      cellType: 1,
      cellData: [],
      titleTabsChecked: 0,
      num_listData: []
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.getnum_list();
    this.getbanner_list();
    this.getnum_type_list();
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getbanner_list() {
      banner_list(8).then(res => {
        // console.log("banner_list", res);
        this.banner_list = res.data;
      });
    },
    //获取分类
    getnum_list() {
      let that = this;
      num_list().then(res => {
        // console.log("num_list", res);
        let arr = [];
        for (let i = res.data.list.length-1; i > -1; i--) {
          arr.push(res.data.list[i])
        }

        that.num_listData =arr;
      });
    },
    //获取列表
    getnum_type_list() {
      let that = this;
      num_type_list().then(res => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].current = 1;
        }
        that.cellData = res.data;

        //  that.cellData.push(res.data[i]);
        // = res.data;
      });
    },
    changeTabs(index, str, id) {
      this.bannerCurrent = index;
      //根据id跳转到指定的元素
      this.$el.querySelector(`#box${index}`).scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    },
    tabsTopChange(index) {
      this.tabsTopCurrent = index;
      this.cellType = index + 1;
      this.getnum_type_list();
    },
    turnLeft() {
      let imgbox = this.$refs.imgbox[0].offsetWidth; //不包括溢出的部分
      if (-this.marginLeft > 1) {
        this.marginLeft = this.marginLeft + imgbox;
      }
    },
    turnRight() {
      let scrollwidth = this.$refs.imgmodel.scrollWidth; //包括溢出的部分
      let offsetWidth = this.$refs.box.offsetWidth; //不包括溢出的部分
      let imgbox = this.$refs.imgbox[0].offsetWidth; //不包括溢出的部分
      // let boxscrollwidth = this.$refs["box"][0].scrollWidth; //包括溢出的部分
      // console.log("scrollwidth", scrollwidth);
      // console.log("offsetWidth", offsetWidth);
      // console.log("boxscrollwidth",boxscrollwidth);

      if (scrollwidth - offsetWidth > -this.marginLeft) {
        this.marginLeft = this.marginLeft - imgbox;
      }
    },
    changeBox(item) {
      this.cellData[item.index].current = item.type;
    }
    //滚动事件
    // handleScroll() {
    //   let that = this;
    //   var scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   var offsetTop = document.querySelector(".tabpbox").offsetTop; // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
    //   // console.log(scrollTop, offsetTop);
    //   if (scrollTop > offsetTop) {
    //     that.pFixed = true;
    //   }
    //   if (offsetTop == 0 && scrollTop < 550) {
    //     that.pFixed = false;
    //   }
    // }
  }
};
</script>

<style lang="less" scoped>
.digitalProducts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-bimg {
  width: 100%;

  img {
    width: 100%;
    display: block;
  }

  margin-bottom: 40px;
}

.header-img {
  width: 72%;
  display: flex;
  position: relative;
}

.poleft {
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  left: -50px;
  /* box-shadow: 0 0 4px #f0f0f0; */
}

.poright {
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  right: -50px;
  /* box-shadow: 0 0 4px #f0f0f0; */
}

.header-img-model {
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow: hidden;
}

.header-img-model-son-box {
  width: 25%;
  display: flex;
  justify-content: center;
  /* 超出滚动的关键，没有它元素会自动缩小，不会滚动 */
  flex-shrink: 0;
  transition: all 0.6s;
}

.header-img-model-son-img {
  display: block;
  width: 95%;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}

.header-img-model-son {
  margin-bottom: 10px;
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

::-webkit-scrollbar {
  /* 隐藏滚动条 */

  /* display: none; */
}

.header-img-model-son-span {
  width: 280px;
  text-align: center;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: sanMedium;
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 140px);
  color: #ffffff;
}

.checkedImg {
  color: #14508c;
}

.tabpbox {
  width: 1170px;
  display: flex;
  justify-content: center;
  z-index: 999;
}

.pbox {
  position: fixed;
  top: 0;
}

.tabbox {
  display: flex;
  width: 80%;
}

.tab-span {
  display: inline-block;
  width: 50%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-family: sanMedium;
  color: #ffffff;
}

.tab-span:nth-child(1) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #14508c;
}

.tab-span:nth-child(2) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #14508c;
}

.tabsChecked {
  background-color: #d71a21 !important;
}
</style>