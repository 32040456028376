<template>
  <div class="headerbox" :style="{ top: defaultTop + 'px' }">
    <div
      class="header-bar"
      :style="{
        width: defaultWidth + '%',
        borderRadius: defaultWidth == 100 ? '0' : '10px',
        height: height + 'px',
        transition: isTransition ? 'all 0.8s' : '',
      }"
    >
      <div class="header-not-box-left">
        <img
          @click="toIndex"
          style="width: 300px"
          :src="
            require(times == 2
              ? '@/assets/newImg/yyt1.png'
              : '@/assets/newImg/yyt2.png')
          "
          class="header-not-leftimg"
          alt="EUT"
        />
      </div>
      <div class="header-not-box-right">
        <!-- <span
          class="header-bar-span"
          :class="currentRouter == item.path ? 'tabChecked' : ''"
          v-for="(item, index) in headerBar"
          :key="index"
          @click="changeTab(item)"
        >{{ item.text }}</span> -->
        <el-menu class="el-menu-demo bar-menu-list" mode="horizontal" >
          <el-menu-item  v-for="(item, index) in headerBar"
          :key="index" :index="item.id">
            <template v-if="item.children && item.children.length !=0">
              <el-submenu :index="item.id">
                <template slot="title">
                  <span class="header-bar-span" :class="currentRouter == item.path ? 'tabChecked' : ''">{{item.text}}</span>
                </template>
                <el-menu-item 
                  v-for="(subItem,subIndex) in item.children" 
                  :key="subIndex" 
                  :index="subItem.id">
                  <span @click="chanageSubTab(subItem)">{{ subItem.text }}</span>
                </el-menu-item>
              </el-submenu>
            </template>
            <template v-else>
              <span class="header-bar-span" :class="currentRouter == item.path ? 'tabChecked' : ''" @click="changeTab(item)">{{item.text}}</span>
            </template>
          </el-menu-item>
        </el-menu>

        <div class="car-box">
          <img class="car" @click="showCar" src="@/assets/newImg/cars1.png" alt />
          <div class="yuan" v-if="isLogin">{{ carLen }}</div>
          <div class="car-model" v-if="isShowCar">
            <Scroll :on-reach-bottom="handleReachBottom" :height="219">
              <div
                class="car-cell"
                v-for="(item, index) in carData"
                :key="index"
                style="border-bottom: 1px solid #e7e7e7"
              >
                <div class="left">
                  <Checkbox size="small" v-model="carData[index].isChecked"></Checkbox>
                  <img :src="item.imgs" alt />
                </div>
                <div class="center">
                  <span>{{ item.title }}</span>
                </div>
                <div class="right">
                  <span>￥{{ item.price }}</span>
                  <span>x{{ item.num }}</span>
                </div>
              </div>
            </Scroll>
            <div class="allPrice">
              <div class="left">
                <span>总计:</span>
                <span>￥{{ compAllPrice }}</span>
              </div>
              <div class="right">
                <button @click="toPay">结算</button>
              </div>
            </div>
          </div>
        </div>
        <div class="right-center" v-if="!isLogin">
          <span class="header-bar-span goin" @click="showBox">管理中台</span>
          <div class="login-model" v-if="isShowBox">
            <div
              style="
                width: 81px;
                height: 2px;
                background: #547af8;
                margin-bottom: 21px;
              "
            ></div>
            <div class="tologin-text" style="margin-bottom: 23px" @click="toMine">
              <span>请</span>
              <span>登录/注册</span>
              <span>后查看</span>
            </div>
            <div>
              <button class="tologin-btn" @click="toMine">登录</button>
            </div>
          </div>
        </div>
        <div class="right-center" v-else>
          <!-- <span class="header-bar-span goin" @click="showBox">{{userInfo.phone}}</span> -->
          <span class="header-bar-span goin" @click="showBox">
            {{
            userInfo.phone
            }}
          </span>
          <div class="login-model" v-if="isShowBox">
            <div
              style="
                width: 81px;
                height: 2px;
                background: #547af8;
                margin-bottom: 21px;
              "
            ></div>
            <div class="tologin-text" style="margin-bottom: 23px" @click="toMine">
              <span>平台入口</span>
            </div>
            <div class="tologin-text" style="margin-bottom: 23px">
              <span>我的积分:{{ userInfo.integral }}</span>
            </div>
            <div class="tologin-text" style="margin-bottom: 23px" @click="toMyOredr">
              <span>我的订单</span>
            </div>
            <div class="tologin-text" style="margin-bottom: 23px" @click="toMyCar">
              <span>购物车</span>
            </div>
            <!-- <div class="tologin-text" style="margin-bottom:23px;">
              <span>消息中心</span>
            </div>-->
            <div class="tologin-text" style="margin-bottom: 23px" @click="toMyaddress">
              <span>收货地址</span>
            </div>
            <!-- <div class="tologin-text" style="margin-bottom:23px;" @click="tologin2">
              <span>切换账号</span>
            </div>-->
            <div class="tologin-text" style="margin-bottom: 23px" @click="togoOut">
              <span>注册登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { paycar_list, get_user } from "@/api/api";
export default {
  props: {
    startWidth: {
      type: Number,
      default() {
        return 100;
      },
    },
    endWidth: {
      type: Number,
      default() {
        return 100;
      },
    },
    startTop: {
      type: Number,
      default() {
        return 0;
      },
    },
    entTop: {
      type: Number,
      default() {
        return 0;
      },
    },
    borderRadius: {
      type: String || Number,
      default() {
        return "0";
      },
    },
    currentRouter: {
      type: String,
      default() {
        return "";
      },
    },
    isLogin: {
      type: Boolean,
      default() {
        return false;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isShowCar: false,
      isShowBox: false,
      isTransition: false,
      times: 1,
      scrollTop: 0,
      height: 70,
      defaultWidth: 100,
      defaultTop: 0,
      time: "",
      carData: [],
      listData: [],
      marginLeft: 0,
      current: 0,
      headerBar: [
        {
          id: '0',
          text: "首页",
          path: "/index",
          cpath: "",
        },
        {
          id: '1',
          text: "集团介绍",
          path: "/aboutus",
          cpath: "/aboutusDetail",
        },
        {
          id: '2',
          text: "产品结构",
          path: "/productStructure",
          cpath: "",
        },
        {
          id: '3',
          text: "数字产品",
          path: "/digitalproducts",
          cpath: "",
        },
        {
          id: '4',
          text: "物联产品",
          path: "/unionproduct",
          cpath: "/productDetail",
        },
        {
          id: '5',
          text: "技术服务",
          path: "/professionalservices",
          cpath: "/serviceDetail",
        },

        {
          id: '6',
          text: "发展历程",
          path: "/History",
          cpath: "/historyDetail",
        },
        {
          id: '7',
          text: "企业动态",
          path: "/news",
          cpath: "/newDetail",
        },
        {
          id: '10',
          text: "公示",
          path: "",
          cpath: "",
          children:[
            {
              id:'10-1',
              text:"职业卫生",
              path:"/publicity/health",
            },
            {
              id:'10-2',
              text:"环境",
              path:"/publicity/environment",
            },
          ]
        },
        {
          id: '8',
          text: "投诉举报",
          path: "/complaint",
          cpath: "",
        },
        {
          id: '9',
          text: "联系我们",
          path: "/callme",
          cpath: "/callmeDetail",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.defaultWidth = this.startWidth;
    this.defaultTop = this.startTop;
    // this.getWidth();
  },
  destroyed() {
    // //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  computed: {
    ...mapState(["carLen"]),
    compAllPrice() {
      let carData = this.carData;
      let num = 0;
      for (let i = 0; i < carData.length; i++) {
        if (carData[i].isChecked) {
          num += parseFloat(carData[i].price) * parseFloat(carData[i].num);
        }
      }
      return num;
    },
    allTop() {
      return this.setTop + this.defaultTop;
    },
  },
  watch: {
    scrollTop(scrollTop) {
      let that = this;
      // && scrollTop < that.startTop + 40
      if (scrollTop > that.startTop) {
        if (that.times == 1) {
          // console.log("11111");
          that.defaultTop = that.entTop;
          that.height = 0;
          that.defaultWidth = that.endWidth;
          that.isTransition = false;

          that.times = 2;
          setTimeout(function () {
            that.isTransition = true;
            that.height = 70;
          }, 100);
          setTimeout(function () {
            that.isTransition = false;
          }, 1100);
        }
      } else if (scrollTop == 0) {
        that.times = 1;
        // console.log("22222");
        that.isTransition = false;
        that.defaultTop = that.startTop;
        setTimeout(() => {
          that.isTransition = true;
          that.defaultWidth = that.startWidth;
        }, 100);
      }
    },
  },
  methods: {
    ...mapMutations(["goOut", "setCarLen", "setUserInfo"]),
    toIndex() {
      this.$router.push({ path: "/index" });
    },
    //购物车立即结算
    toPay() {
      let that = this;
      let data = [];
      for (let i = 0; i < that.carData.length; i++) {
        if (that.carData[i].isChecked) {
          data.push({
            car_id: that.carData[i].id,
            pid: that.carData[i].pid,
            num: that.carData[i].num,
          });
        }
      }
      if (data.length < 1) {
        that.$Message.warning("未选择商品");
        return false;
      }
      // console.log("data",data)
      that.$router.push({
        path: "/createOrder",
        query: { buy_str: JSON.stringify(data) },
      });
    },
    toMyCar() {
      this.isShowBox = !this.isShowBox;
      this.$router.push({ path: "/car" });
    },
    togoOut() {
      this.goOut();
      this.isShowBox = !this.isShowBox;
    },
    //购物车触底事件
    handleReachBottom() {},
    getpaycar_list() {
      let that = this;
      paycar_list()
        .then((res) => {
          for (let i = 0; i < res.data.list.length; i++) {
            res.data.list[i].isChecked = false;
          }

          that.carData = res.data.list;
          that.$store.commit("setCarLen", res.data.total);
        })
        .catch((err) => {});
    },
    toMyaddress() {
      this.$router.push({ path: "/myAddress" });
      this.isShowBox = !this.isShowBox;
    },
    toMyOredr() {
      this.$router.push({ path: "/myOrder" });
      this.isShowBox = !this.isShowBox;
    },
    //显示或隐藏购物车
    showCar() {
      let that = this;
      that.isShowBox = false;
      if (!that.isLogin) {
        that.$Message.error("请登录后重试");
      } else {
        that.isShowCar = !that.isShowCar;
        // console.log(1);
        if (that.isShowCar) {
          // console.log(2);
          that.getpaycar_list();
        }
      }
    },
    //获取积分
    getJF() {
      let that = this;
      get_user().then((item) => {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        userInfo.integral = item.data.integral;

        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        that.$store.commit("setUserInfo", userInfo);
        that.formItem = {
          phone: "", //	19160832579	是	string	手机号
          type: 1, //	2	是	string	登录类型 1密码登录 2短信验证码登录
          msg_code: "", //	706128	否	string	当选择类型未短信验证码登录时必填
          pwd: "", //		否	string	当登录类型未密码登录 必填
        };
      });
    },
    //切换登录窗口的显示和影藏
    showBox() {
      this.isShowCar = false;
      this.isShowBox = !this.isShowBox;
      if (this.isShowBox) {
        this.getJF();
      }
    },
    getWidth() {
      let scrollwidth = this.$refs.spanBox.scrollWidth; //包括溢出的部分
      let offsetWidth = this.$refs.spanBox.offsetWidth; //不包括溢出的部分
      this.marginLeft = offsetWidth;

      this.time = setInterval(() => {
        this.marginLeft = this.marginLeft - 1;
        if (this.marginLeft < -scrollwidth) {
          clearInterval(this.time);
          this.marginLeft = offsetWidth;
          this.getWidth();
        }
      }, 10);
    },
    changeTab(item) {
      let that = this;
      that.current = item.id;
      switch (item.id) {
        case '0':
          that.$router.push({ path: "/index" });
          break;
        case '1':
          that.$router.push({ path: "/aboutus" });
          break;
        case '2':
          that.$router.push({ path: "/productStructure" });
          break;
        case '3':
          that.$router.push({ path: "/digitalproducts" });
          break;
        case '4':
          that.$router.push({ path: "/unionproduct" });
          break;
        case '5':
          that.$router.push({ path: "/professionalservices" });
          break;
        case '6':
          that.$router.push({ path: "/History" });
          break;
        case '7':
          that.$router.push({ path: "/news" });
          break;
        case '8':
          that.$router.push({ path: "/complaint" });
          break;
        case '9':
          that.$router.push({ path: "/callme" });
          break;
      }
    },
    chanageSubTab(d){
      let that = this
      if(d.id == '10-1') {
        that.$router.push({ path: "/publicity/health" });
      }else if(d.id == '10-2') {
        that.$router.push({ path: "/publicity/environment" });
      }
    },
    //跳转到个人信息
    toMine() {
      let that = this;
      // that.$parent.isShow = false;
      that.$router.push({ path: "/mine" });
      that.isShowBox = !that.isShowBox;
    },
    //滚动事件
    handleScroll() {
      let that = this;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop = document.querySelector(".headerbox").offsetTop; // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
      // console.log(scrollTop, offsetTop);
      that.scrollTop = scrollTop;
    },
  },
};
</script>

<style lang="less" scoped>
.bar-menu-list {
  background-color: inherit !important;
  border-bottom: none !important;
  .el-menu-item {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
  .el-submenu {
    /deep/ .el-submenu__title {
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
    }
  }
  /deep/ .el-menu-item:hover,
  /deep/.el-menu-item:focus  {
    background-color: inherit !important;
  }
  /deep/ .el-submenu__title {
    background-color: inherit !important;
  }
  /deep/ .el-submenu__icon-arrow {
    right: -4px !important;
  }
  /deep/ .el-submenu__title i {
    color: #fff;
  }
  /deep/ .is-active {
    border-bottom: 0 !important;
    color: #fff !important;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 1000;
}

.tabChecked {
  color: #2f4373 !important;
}

.header-bar {
  background: #ffffff;
  box-shadow: 0 0 5px #adadad;
  color: #494949;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-not-box-right {
  width: 1200px;
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-bar-span {
  // font-family: sanNormal;
  font-size: 1.1rem;

  // font-size: 20px;
  color: #363636;
}

.header-bar-span:hover {
  cursor: pointer;
}

.goin {
  display: flex;
  align-items: center;
  display: inline-block;

  color: #ffffff;
  transition: all 0.5s;

  width: 152px;
  height: 55px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background: #547af8;
  border-radius: 5px;
}

.car-box {
  position: relative;

  .car {
    width: 24px;
    height: 24px;
  }

  .yuan {
    color: #ffffff;
    background: red;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    top: -2px;
    right: -6px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .car-model {
    overflow: hidden;
    position: absolute;
    top: 50px;
    left: -128px;
    width: 350px;
    height: 290px;
    background: #ffffff;
    border-radius: 10px;

    .car-cell {
      padding: 10px 20px;
      height: 100px;
      display: flex;

      .left {
        display: flex;
        align-items: center;

        img {
          width: 70px;
          height: 70px;
        }
      }

      .center {
        width: 200px;
        padding: 5px;
      }

      .right {
        display: flex;
        align-items: flex-end;
        padding: 5px;

        span:nth-child(1) {
          margin-right: 10px;
        }
      }
    }

    .allPrice {
      height: 70px;
      display: flex;
      background: #ededed;
      justify-content: space-between;
      padding: 0 30px;

      // border-radius: 10px;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span:nth-child(1) {
          font-size: 13px;
          font-weight: 400;
          color: #3a3a3a;
        }

        span:nth-child(2) {
          font-size: 22px;
          font-weight: 400;
          color: #ca141d;
        }
      }

      .right {
        display: flex;
        align-items: center;

        button {
          width: 150px;
          height: 40px;
          background: #1c4492;
          // opacity: 0.6;
          border-radius: 2px;
          border: none;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}

.right-center {
  position: relative;
  z-index: 9999;

  .login-model {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    position: absolute;
    top: 64px;
    left: 0;
    width: 152px;
    background: #ffffff;
    border-radius: 10px;

    .tologin-text {
      span {
        font-size: 16px;
        font-weight: 400;
        color: #363636;
      }

      span:nth-child(2) {
        color: #547af8;
      }

      span:hover {
        cursor: pointer;
      }
    }

    .tologin-btn:hover {
      cursor: pointer;
    }

    .tologin-btn {
      width: 122px;
      height: 38px;
      background: #1c4492;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      border: none;
    }
  }
}
</style>