<template>
  <div class="caring">
    <div class="caring-content" v-html="content"></div>
  </div>
</template>

<script>
import { care_det } from "@/api/api";
export default {
  data() {
    return {
      content: "",
    };
  },
  mounted() {},
  activated() {
    this.getcare_det();
  },
  methods: {
    //获取详情
    getcare_det() {
      let that = this;
      care_det().then((res) => {
        // console.log("content", res);
        that.content = res.data.content;
      });
    },
  },
};
</script>

<style>
.caring {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.caring-content {
  width: 80%;
}
</style>