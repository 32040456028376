<template>
  <div class="pages">
    <div class="header-title">
      <span class="header-title-span">确认订单</span>
      <div class="header-title-line">
        <Steps :current="0" size="small">
          <Step title content="填写核对订单"></Step>
          <Step title content="成功提交订单"></Step>
        </Steps>
      </div>
    </div>
    <div class="header-address">
      <div class="title">
        <span>收货地址</span>
        <span v-if="Myaddr_indexData.length>0" @click="toAdd">+ 新增收货地址</span>
      </div>
      <div
        v-if="Myaddr_indexData.length>0"
        class="address-box"
        :style="isShowMore?'min-height:79px':'height:79px'"
      >
        <div class="address-text2" v-for="(item,index) in Myaddr_indexData" :key="index">
          <div class="top">
            <div class="left">
              <span>{{item.name}}</span>
              <span>{{item.tel}}</span>
              <span v-if="item.state==1">默认地址</span>
            </div>
            <div class="right">
              <!-- <span v-if="item.state!=1" >设为默认</span> -->
              <span class="edit" @click="edit(item)">编辑</span>

              <Poptip confirm title="确认删除该地址?" @on-ok="removeok(item)">
                <span>删除</span>
              </Poptip>
            </div>
          </div>
          <span class="bottom-span">{{item.province}}{{item.city}}{{item.area}}{{item.address}}</span>
        </div>
      </div>
      <div class="address-text" v-else>
        <span @click="toAdd">+ 新增收货地址</span>
      </div>
      <div class="look-all" v-if="Myaddr_indexData.length>2" @click="toIsShowMore">
        <span>查看全部地址</span>
        <img :src="require(`@/assets/${isShowMore?'top':'bottom'}.png`)" alt />
      </div>
    </div>
    <div class="product">
      <div class="title">
        <span>购买物品</span>
      </div>
      <div class="cell-box">
        <div class="left">
          <Scroll :on-reach-bottom="handleReachBottom" :height="310">
            <div
              class="car-cell"
              v-for="(item, index) in query_orderData.order_info"
              :key="index"
              style="border-bottom:1px solid #e7e7e7"
            >
              <!-- <Checkbox size="small"></Checkbox> -->

              <div class="center">
                <div class="center-t1">
                  <div class="img-box">
                    <img :src="item.imgs" alt />
                  </div>
                  <span>{{item.title}}</span>
                </div>
                <div class="center-t2">
                  <span>x{{item.num}}</span>
                  <span>￥{{item.price}}</span>
                </div>
              </div>
            </div>
          </Scroll>
        </div>
        <div class="right">
          <div class="titles">
            <span>商品由</span>
            <span>易尤特</span>
            <span>选择合作快递</span>
          </div>
          <div class="text">
            <div class="yuan"></div>
            <span>标准配送</span>
          </div>
          <div class="lines"></div>
          <div class="price">
            <span>商品总金额：</span>
            <span>￥{{query_orderData.price}}</span>
          </div>
          <div class="price">
            <span>运费：</span>
            <span>￥0.00</span>
          </div>
          <div class="price">
            <span>结算金额：</span>
            <span
              style="font-size: 21px;font-weight: 400;color: #CA141D;"
            >￥{{query_orderData.price}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="jiesuan">
      <div class="allPrice">
        <span>应付金额</span>
        <span>￥{{query_orderData.price}}</span>
      </div>
      <div class="jiesuan-text">
        <div class="jiesuan-box" v-if="Myaddr_indexData.length<1">
          <span>无收货地址不可下单，</span>
          <span>点击填充</span>
        </div>
      </div>
      <div class="btn">
        <div class="lefts">
          <span>推荐人：</span>
          <Select size="small" v-model="sale_name" style="width:170px">
            <Option
              v-for="item in sale_listData"
              :value="item.id"
              :key="item.value"
            >{{ item.name }}-{{item.tel}}</Option>
          </Select>
        </div>
        <Poptip confirm title="确认提交订单?" @on-ok="toPay()">
          <button class="querenBtn">提交订单</button>
        </Poptip>
      </div>
    </div>

    <add-address ref="addAddress"></add-address>
  </div>
</template>

<script>
import {
  query_order,
  Myaddr_index,
  sale_list,
  del_addr,
  myorder_add
} from "@/api/api";
import addAddress from "./components/addAddress.vue";
export default {
  components: {
    addAddress
  },
  data() {
    return {
      buy_str: [],
      isShowMore: false, //是否展示更多地址
      address: [{}, {}],
      query_orderData: {},
      Myaddr_indexData: [],
      sale_listData: [], //推荐人列表
      sale_name: "" //选中的推荐人
    };
  },
  mounted() {},
  activated() {
    let that = this;
    that.buy_str = JSON.parse(this.$route.query.buy_str);
    that.getProduct();
    that.getMyaddr_index();
    that.getsale_list();
  },
  methods: {
    //获取要购买的商品的信息
    getProduct() {
      let that = this;
      query_order({ buy_str: JSON.stringify(that.buy_str) }).then(res => {
        // console.log("res", res);
        that.query_orderData = res.data;
      });
    },
    //获取地址列表 取第一个地址
    getMyaddr_index() {
      let that = this;
      Myaddr_index({ page: 1 }).then(res => {
        // console.log("Myaddr_indexData", res);
        that.Myaddr_indexData = res.data.list;
      });
    },
    //获取推荐人列表
    getsale_list() {
      let that = this;
      sale_list().then(res => {
        // console.log("sale_listData", res);
        that.sale_listData = res.data;
      });
    },
    //发起删除地址
    postdel_addr(id) {
      let that = this;
      del_addr(id).then(res => {
        if (res.code == 200) {
          that.$Message.success(res.message);
        } else {
          that.$Message.error(res.message);
        }
        that.getMyaddr_index();
      });
    },
    toIsShowMore() {
      this.isShowMore = !this.isShowMore;
    },
    toAdd() {
      this.$refs.addAddress.title = "添加地址";
      this.$refs.addAddress.showModel = true;
    },
    //购物车触底事件
    handleReachBottom() {},
    toPay() {
      let that = this;
      if (that.Myaddr_indexData.length < 1) {
        that.$Message.error("请添加收货地址");
        return false;
      }
      that.tomyorder_add();
    },
    //确认删除地址
    removeok(item) {
      this.postdel_addr(item.id);
    },
    edit(item) {
      this.$refs.addAddress.title = "编辑地址";
      this.$refs.addAddress.formItem.id = item.id;
      this.$refs.addAddress.getDetail();
      this.$refs.addAddress.showModel = true;
    },
    //创建订单
    tomyorder_add() {
      let that = this;
      let data = {
        buy_str: JSON.stringify(that.buy_str), //[{“car_id”:0,”pid”:6,”num”:2},{“car_id”:0,”pid”:5,”num”:1}]	是	string	无
        addr_id: that.Myaddr_indexData[0].id, //1	是	string	地址id
        sale_name: that.sale_name //		否	string	销售人员
      };
      myorder_add(data).then(res => {
        if (res.code == 200) {
          that.$Message.success(res.message);
          that.$router.push({ path: "/pay",query:{order_id:res.data.order_id} });
        } else {
          that.$Message.error(res.message);
        }
        that.getMyaddr_index();
      });
    }
  }
};
</script>

<style lang="less" scoped>
.pages {
  background: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header-title {
    padding: 0 30px;
    margin: 130px 0 30px 0;
    width: 60%;
    height: 60px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-title-span {
      font-size: 22px;
      font-weight: 400;
      color: #3a3a3a;
    }
    .header-title-line {
      width: 200px;
    }
  }
  .header-address {
    padding: 25px 40px;
    width: 60%;
    // height: 187px;
    background: #ffffff;
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #3a3a3a;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      span:nth-child(1) {
      }
      span:nth-child(2) {
        width: 147px;
        height: 29px;
        line-height: 29px;
        text-align: center;
        border: 1px solid #d1d1d1;
        border-radius: 2px;
        display: inline-block;
        font-size: 14px;
      }
    }
    .address-text {
      padding: 0 30px;
      height: 79px;
      border: 1px solid #d1d1d1;
      display: flex;
      align-items: center;
      justify-content: center;
      span:nth-child(1) {
        font-size: 20px;
        //   font-weight: 600;
        color: #3a3a3a;
      }
      span:nth-child(2) {
        font-size: 20px;
        color: #3a3a3a;
        margin: 0 30px;
      }
      span:nth-child(3) {
        font-size: 20px;
        color: #3a3a3a;
      }
    }
    .address-box {
      overflow: hidden;
      // transition: all 0.5s;
      .address-text2 {
        margin-bottom: 16px;
        padding: 0 30px;
        height: 79px;
        border: 1px solid #d1d1d1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          .left {
            span {
              font-size: 14px;
              font-weight: 400;
              color: #000000;
            }
            span:nth-child(1) {
            }
            span:nth-child(2) {
              margin: 0 16px;
            }
            span:nth-child(3) {
              display: inline-block;

              text-align: center;
              border: 1px solid #1c4492;
              border-radius: 2px;
              font-size: 11px;
              padding: 0px 5px;
            }
          }
          .right {
            span {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
            }
            .edit {
              margin: 0 12px;
            }
          }
        }
        .bottom-span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #9a9a9a;
        }
      }
      .address-text2:last-child {
        margin-bottom: 0;
      }
    }

    .look-all {
      margin-top: 20px;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9a9a9a;
        margin-right: 4px;
      }
      img {
        width: 12px;
      }
    }
  }
  .product {
    width: 60%;
    background: #ffffff;
    margin: 30px 0;
    padding: 20px 30px 40px 30px;
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #3a3a3a;
      margin-bottom: 20px;
    }
    .cell-box {
      border: 1px solid #d1d1d1;
      display: flex;
      .left {
        width: 65%;
        .car-cell {
          width: 100%;
          height: 150px;
          .center {
            display: flex;
            justify-content: space-between;
            // padding: 20px 0;
            .center-t1 {
              display: flex;
              padding-left: 28px;

              .img-box {
                width: 100px;
                height: 150px;
                display: flex;
                align-items: center;
                flex-shrink: 0;
                img {
                  width: 100px;
                  height: 100px;
                  display: block;
                  object-fit: contain;
                }
              }
              span {
                flex-shrink: 0;
                margin-left: 30px;
                margin-top: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #3a3a3a;
              }
            }
            .center-t2 {
              padding-right: 20px;
              padding-top: 20px;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #3a3a3a;
              }
              span:nth-child(2) {
                margin-left: 100px;
              }
            }
          }
        }
      }
      .right {
        width: 35%;
        padding: 20px 20px;
        background: #f6f6f6;

        .titles {
          font-size: 19px;
          font-weight: 400;
          color: #3a3a3a;
          margin-bottom: 20px;
          span:nth-child(2) {
            color: #1c4492;
          }
        }
        .text {
          display: flex;
          .yuan {
            width: 20px;
            height: 20px;
            border: 5px solid #1c4492;
            border-radius: 50%;
            margin-right: 11px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #3a3a3a;
          }
        }
        .lines {
          margin: 40px 0 60px 0;
          height: 1px;
          border: 1px solid #d1d1d1;
        }
        .price {
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #3a3a3a;
          }
          span:nth-child(1) {
          }
          span:nth-child(2) {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            width: 70px;
          }
        }
      }
    }
  }

  .jiesuan {
    width: 60%;
    background: #ffffff;
    padding: 40px;
    margin-bottom: 30px;
    .allPrice {
      text-align: right;
      span:nth-child(1) {
        font-size: 18px;
        font-weight: 400;
        color: #3a3a3a;
      }
      span:nth-child(2) {
        font-size: 29px;
        font-weight: bold;
        color: #ca141d;
      }
    }
    .jiesuan-text {
      margin: 47px 0 20px 0;
      display: flex;
      justify-content: flex-end;
      .jiesuan-box {
        width: 227px;
        height: 56px;
        text-align: center;
        line-height: 56px;
        background: #eff4ff;
        border: 1px solid #1c4492;
        font-size: 13px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #1c4492;
      }
    }

    .btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 172px;
        height: 46px;
        background: #1c4492;
        border-radius: 2px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        border: none;
      }
      button:hover{
        cursor: pointer;
      }
      .lefts {
        display: flex;
        align-items: center;
        margin-right: 20px;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #3a3a3a;
        }
        input {
          width: 87px;
          height: 19px;
          border: 1px solid #3a3a3a;
        }
      }
    }
  }
}
</style>
<style>
.ivu-steps-content {
  margin-left: -30px;
}
.ivu-steps-item {
  overflow: unset !important;
}
.ivu-steps-content {
  padding-left: 0 !important;
}
.ivu-steps-head-inner {
  width: 30px;
  height: 30px;
}
</style>