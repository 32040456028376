<template>
  <div style="width:100%;">
    <div style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0 60px 0;
      " :style="{ background: (index - 1) % 2 == 0 ? '#1C4492' : '#ffffff', color: (index - 1) % 2 == 0 ? '#ffffff !important' : '' }"
      v-for="(item, index) in cellDatas" :key="index">
      <div style="width: 69%;">
        <div class="product-box" :id="'box' + index"></div>
        <div v-if="(index - 1) % 2 != 0">
          <div class v-if="item.current == 1" style="display:flex">
            <div class="right-i" style="width:45%">
              <img style="width:100%" :src="item.qy.all_img[0]" alt />
            </div>
            <div v-html="item.zf.brief" style="width:55%;"></div>
          </div>
          <div class v-else-if="item.current == 2" style="display:flex">
            <div class="right-i" style="width:45%">
              <img style="width:100%" :src="item.qy.all_img[0]" alt />
            </div>
            <div v-html="item.qy.brief" style="width:55%;"></div>
          </div>
        </div>
        <div v-else>
          <div class v-if="item.current == 1" style="display:flex">
            <div v-html="item.zf.brief" style="width:55%;"></div>
            <div class="right-i" style="width:45%">
              <img style="width:100%" :src="item.qy.all_img[0]" alt />
            </div>
          </div>
          <div class v-else-if="item.current == 2" style="display:flex">
            <div v-html="item.qy.brief" style="width:55%;"></div>
            <div class="right-i" style="width:45%">
              <img style="width:100%" :src="item.qy.all_img[0]" alt />
            </div>
          </div>
        </div>

        <div class="tabpbox">
          <div class="tabbox">
            <span class="tab-span" :class="item.current == 1 ? 'tabsChecked' : ''"
              @click="tabsTopChange(1, index)">政府版</span>
            <span class="tab-span" :class="item.current == 2 ? 'tabsChecked' : ''"
              @click="tabsTopChange(2, index)">企业版</span>
          </div>
        </div>
        <div class="product-list" v-if="item.current == 1">
          <div class="product-model" v-for="(itemSon, indexSon) in item.zf.tyle_all" :key="indexSon">
            <div class="product-model-top">
              <img :src="itemSon.imgs" alt />
            </div>
            <div class="product-model-bottom">
              <span>{{ itemSon.title }}</span>
            </div>
            <div class="model-m">
              <span @click="lookMovie(itemSon)">查看视频</span>
              <span @click="toDetail(itemSon)">了解详情</span>
            </div>
          </div>
        </div>
        <div class="product-list" v-else-if="item.current == 2">
          <div class="product-model" v-for="(itemSon, indexSon) in item.qy.tyle_all" :key="indexSon">
            <div class="product-model-top">
              <img :src="itemSon.imgs" alt />
            </div>
            <div class="product-model-bottom">
              <span>{{ itemSon.title }}</span>
            </div>
            <div class="model-m">
              <span @click="lookMovie(itemSon)">查看视频</span>
              <span @click="toDetail(itemSon)">了解详情</span>
            </div>
          </div>
        </div>
      </div>

      <Modal v-model="modal1" title="视频">
        <video v-if="modal1" style="width:100%" :src="videoSrc" controls="controls"></video>
      </Modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cellData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      cellDatas: [],
      modal1: false,
      videoSrc: "",
      content: "",
      titleTabsChecked: 0
    };
  },
  mounted() { },

  watch: {
    cellData(val) {
      for (let i = 0; i < val.length; i++) {
        this.cellDatas.push(val[i]);
      }

      // switch (route.path){
      //   case :
      //   break;
      // }
    }
    //   immediate: true,
    //   deep: true
    // }
  },
  methods: {
    lookMovie(item) {
      this.videoSrc = item.v_img;
      this.modal1 = true;
    },
    toDetail(item) {
      let routeUrl = this.$router.resolve({
        path: "/digitalProductDetail",
        query: { id: item.id }
      });

      window.open(routeUrl.href, '_blank');
      // this.$router.push({
      //   path: "/digitalProductDetail",
      //   query: { id: item.id }
      // });
    },
    tabsTopChange(type, index) {
      this.$emit("changeBox", { type, index });
    }
  }
};
</script>

<style lang="less" scoped>
.product-box {
  width: 100%;
  margin: 30px 0;
  border-radius: 10px;
}

.product-box-img {
  width: 100%;
  border-radius: 10px;
}

.table-box {
  width: 100%;
}

.table-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.table-title-span {
  font-size: 32px;
  font-family: sanMedium;
  color: #000000;
}

.title-tabs {}

.title-tabs span {
  display: inline-block;
  padding: 4px 0;
  margin: 0 10px;
  color: #767676;
  font-size: 18px;
  font-family: sanRegular;
}

.titleTabsChecked {
  color: #14508c;
  border-bottom: 3px solid #14508c;
}

.container-style {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.toDetail-s {
  text-align: right;
  width: 100%;
  margin-top: 20px;
  padding-right: 20px;
}

.toDetail-s span {
  text-decoration: underline;
  color: #14508c;
}

.product-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.product-model {
  width: 24%;
  margin-bottom: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  margin-right: 1%;
  position: relative;
}

.product-model:nth-child(4n) {
  margin-right: 0 !important;
}

.product-model-top {
  width: 100%;
  /* height: 155px; */
}

.product-model-top img {
  width: 100%;
  height: 155px;
  display: block;
}

.model-m {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  span:nth-child(1) {
    display: inline-block;
    text-align: center;
    width: 78px;
    height: 37px;
    line-height: 37px;
    border: 1px solid #ffffff;
    opacity: 0.8;
    border-radius: 5px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-right: 5px;
  }

  span:nth-child(1):hover,
  span:nth-child(2):hover {
    cursor: pointer;
  }

  span:nth-child(2) {
    display: inline-block;
    text-align: center;
    width: 78px;
    height: 37px;
    line-height: 37px;
    border: 1px solid #ffffff;
    opacity: 0.8;
    border-radius: 5px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
}

.product-model-bottom {
  text-align: center;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #3e3e3e;
}

.product-model:hover .product-model-bottom {
  background: #486ed5;
  color: #ffffff;
}

.product-model:hover .model-m {
  height: 155px;
}

.tabpbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 35px 0;
}

.pbox {
  position: fixed;
  top: 0;
}

.tabbox {
  display: flex;
  width: 100%;
}

.tab-span {
  display: inline-block;
  width: 50%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-family: sanMedium;
  color: #ffffff;
}

.tab-span:nth-child(1) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #486ed5;
}

.tab-span:nth-child(2) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #486ed5;
}

.tabsChecked {
  background-color: #d71a21 !important;
}
</style>