<template></template>

<script>
import { my_order_det, Pay } from "@/api/api";
export default {
  data() {
    return {
      payQuery: {}
    };
  },
  mounted() {
    let that = this;
    that.payQuery = this.$route.query;
    that.Pay();
  },
  methods: {
    Pay() {
      let that = this;
      Pay(that.payQuery).then(res => {
        if (res.code == 200) {
          let div = document.createElement("div");
          div.innerHTML = res.data.pay_url.url; //此处form就是后台返回接收到的数据
          document.body.appendChild(div);
          setTimeout(() => {
            document.forms["alipaysubmit"].submit();
          }, 200);
        }
      });
    }
  }
};
</script>

<style>
</style>