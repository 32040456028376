import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import Index from '../views/index/index.vue'
import ysDetail from '../views/index/ysDetail.vue'
import AboutUs from '../views/AboutUs/AboutUs.vue'
import productStructure from '../views/productStructure/productStructure.vue'
import structureDetail from '../views/productStructure/components/structureDetail.vue'
import aboutusDetail from '../views/AboutUs/aboutusDetail/aboutusDetail.vue'
import CaringLeadership from '../views/CaringLeadership/CaringLeadership.vue'
import caringDetail from '../views/CaringLeadership/caringDetail/caringDetail.vue'
import History from '../views/History/History.vue'
import historyDetail from '../views/History/historyDetail/historyDetail.vue'
import DigitalProducts from '../views/DigitalProducts/DigitalProducts.vue'
import DigitalProductDetail from '../views/DigitalProducts/components/DigitalProductDetail.vue'
import UnionProduct from '../views/UnionProduct/UnionProduct.vue'
import Complaint from '../views/Complaint/Complaint.vue'
import CallMe from '../views/CallMe/CallMe.vue'
import callmeDetail from '../views/CallMe/CallMeDetail/CallMeDetail.vue'
import ProfessionalServices from '../views/ProfessionalServices/ProfessionalServices.vue'
import serviceDetail from '../views/ProfessionalServices/serviceDetail/serviceDetail.vue'
import News from '../views/News/News.vue'
import newDetail from '../views/News/newDetail/newDetail.vue'
import Health from '../views/Publicity/Health/Health.vue'
import Environment from '../views/Publicity/Environment/Environment.vue'
import Login from '../views/Login/Login.vue'
import QueryPassword from '../views/Login/QueryPassword.vue'
import AddPassWord from '../views/Login/addPassWord.vue'
import ForgetPassword from '../views/Login/forgetPassword.vue'
import Mine from '../views/Mine/Mine.vue'
import pay from '../views/pay/pay.vue'
import AdminHealth from '../views/Admin/Health/index.vue'
import AdminEnvironment from '../views/Admin/Environment/index.vue'
// import productDetail from '../views/UnionProduct/productDetail.vue'
import productDetail from '../views/productDetail/productDetail.vue'
import createOrder from '../views/pay/createOrder.vue'
import myOrder from '../views/pay/myOrder.vue'
import orderDetail from '../views/pay/orderDetail.vue'
import zfbPay from '../views/pay/zfbPay.vue'
import car from '../views/pay/car.vue'
import myAddress from '../views/myAddress/myAddress.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  redirect: "/index",
  component: Home,
  children: [
    {
      path: '/index',
      name: '首页',
      meta: {
        showHeader: false
      },
      component: Index,
    },
    {
      path: '/ysDetail',
      name: '我们的优势',
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      },
      component: ysDetail,
    },
    {
      path: '/login',
      name: '登录',
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      },
      component: Login,
    },
    {
      path: '/queryPassword',
      name: '修改密码',
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      },
      component: QueryPassword,
    },
    {
      path: '/addPassWord',
      name: '注册',
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      },
      component: AddPassWord,
    },
    {
      path: '/forgetPassword',
      name: '忘记密码',
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      },
      component: ForgetPassword,
    },
    {
      path: '/mine',
      name: '个人中心',
      meta: {
        showHeader: false
      },
      component: Mine,
    },
    {
      path: '/productStructure',
      component: productStructure,
      name: "产品结构",
      meta: {
        showHeader: false
      }
    },
    {
      path: '/structureDetail',
      component: structureDetail,
      name: "产品结构详情",
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      }
    },
    {
      path: '/digitalproducts',
      component: DigitalProducts,
      name: "数字产品",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/digitalProductDetail',
      component: DigitalProductDetail,
      name: "详情",
      meta: {
        // showHeader: true
      }
    },
    {
      path: '/unionproduct',
      component: UnionProduct,
      name: "物联产品",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/professionalservices',
      component: ProfessionalServices,
      name: "技术服务",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/aboutus',
      component: AboutUs,
      name: "集团介绍",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/aboutusDetail',
      component: aboutusDetail,
      name: "集团介绍详情",
      meta: {
        title: "集团介绍",
        titlei: "Group Introduction"
      }
    },
    {
      path: '/caringDetail',
      component: caringDetail
    },
    {
      path: '/history',
      component: History,
      name: "发展历程",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/historyDetail',
      component: historyDetail,
      name: "发展历程",
      meta: {
        showHeader: true
      }
    },
    // {
    //   path: '/caringleadership',
    //   component: CaringLeadership,
    //   name: "领导关怀",
    //   meta: {
    //     title: "领导关怀",
    //     titlei: "Leadership care"
    //   }
    // },
    {
      path: '/news',
      component: News,
      name: "企业动态",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/publicity/health',
      component: Health,
      name: "职业卫生",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/publicity/environment',
      component: Environment,
      name: "环境",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/complaint',
      component: Complaint,
      name: "投诉举报",
      meta: {
        showHeader: true
      }
    },
    {
      path: '/callme',
      component: CallMe,
      name: "联系我们",
      meta: {
        showHeader: true
      }
    },
    {
      name: 'productDetail',
      path: '/productDetail',
      component: productDetail,
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      }
    },
    {
      path: '/callmeDetail',
      component: callmeDetail
    },
    {
      path: '/newDetail',
      component: newDetail
    },
    {
      path: '/serviceDetail',
      component: serviceDetail,
      meta: {
        showHeader: true
      }
    },
    {
      path: '/pay',
      component: pay,
      meta: {
        showHeader: "null"
      }
    },
    {
      path: '/createOrder',
      component: createOrder,
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      }
    },
    {
      path: '/myOrder',
      component: myOrder,
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      }
    },
    {
      path: '/orderDetail',
      component: orderDetail,
      name: 'orderDetail',
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      }
    },
    {
      path: '/car',
      component: car,
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      }
    },
    {
      path: '/myAddress',
      component: myAddress,
      meta: {
        showHeader: false,
        bgc: '#1C4391'
      }
    },
  ]
}, {
  path: '/zfbPay',
  component: zfbPay,
  name: 'zfbPay',
  meta: {
    showHeader: false,
  }
},
{
  path:"/admin/health/index",
  name:"admin-health",
  component:AdminHealth,
  meta: {
    showHeader: false
  },
},
{
  path:"/admin/environment/index",
  name:"admin-environment",
  component:AdminEnvironment,
  meta: {
    showHeader: false
  },
},

]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  //mode: 'history',
  routes
})

export default router