import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    userInfo: {
      phone: ""
    },
    carLen: 0,
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, userInfo) {
      // console.log("修改登录数据", userInfo)
      state.isLogin = true;
      state.userInfo = userInfo;
    },
    goOut(state) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      state.isLogin = false;
      state.userInfo = {
        phone: ""
      };
      router.push({ path: "/index" });
    },
    toLogins(state) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      state.isLogin = false;
      state.userInfo = {
        phone: ""
      };
      router.push({ path: "/login" });
    },
    setCarLen(state, carLen) {
      state.carLen = carLen;
    },
  },
  actions: {

  },
  modules: {
  }
})
