<template>
  <div class="bannerbox" v-if="isShow">
    <!-- <div class="banner-title">
      <span>{{ data.title }}</span>
      <span>{{ data.titlei }}</span>
    </div> -->
    <div class="bannerImg-box" v-if="data.imgs.length > 1">
      <Carousel
        style="width: 100%; height: 350px;border-radius:10px;display:block"
        autoplay
        v-model="value2"
        loop
      >
        <CarouselItem v-for="(item, index) in data.imgs" :key="index">
          <!-- <div class="demo-carousel"></div> -->
          <img :src="item.ads_pic" style="width: 100%; height: 350px;border-radius:10px;" alt="" />
        </CarouselItem>
      </Carousel>
    </div>
    <div class="bannerImg-box" v-else-if="data.imgs.length == 1">
      <img
        :src="data.imgs[0].ads_pic"
        style="width: 100%; height: 350px;border-radius:10px;display:block"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    bannerData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return { value2: 0, data: {} };
  },
  mounted() {},
  watch: {
    bannerData(value) {
      this.data = value;
    },
  },
  methods: {},
};
</script>
<style scoped>
.bannerbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner-title {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}
.banner-title span:nth-child(1) {
  font-size: 28px;
  color: #5f5f5f;
  margin-bottom: 2px;
  font-family: sanMedium;
}
.banner-title span:nth-child(2) {
  font-size: 12px;
  color: #838383;
}

.bannerImg-box {
  width: 80%;
  height: 350px;
  display: flex;
  justify-content: center;
}
.bannerImg {
  width: 100%;
  height: 350px;
  border-radius: 10px;
}
.img-centerText > div {
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
}
.img-centerText {
  color: #ffffff;
  width: 400px;
  position: absolute;
  left: calc(50% - 200px);
  top: 40px;
}

.img-centerText-callme {
  position: absolute;
  top: 40px;
  left: 30px;
  color: #ffffff;
}
.news-titles {
  padding: 20px 0;
  font-size: 28px;
  font-family: sanMedium;
}
.news-texts {
  padding: 4px 0;
  font-size: 18px;
  font-family: sanRegular;
}
</style>