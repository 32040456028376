<template>
  <div class="myOrder">
    <div class="container">
      <div style="height:70px;"></div>
      <div class="title">
        <span>我的订单</span>
      </div>
      <div class="title" style="margin-top:20px">
        <span>订单号:{{orderDetail.order_code}}</span>
      </div>
      <div class="Steps-box">
        <Steps :current="orderState" size="small">
          <Step title content="提交订单">
            <div slot="content">
              <div class="Steps-box-cell">
                <div class="span1">提交订单</div>
                <div class="span2">{{orderDetail.order_time?orderDetail.order_time:''}}</div>
              </div>
            </div>
          </Step>
          <Step title content="付款成功">
            <div slot="content">
              <div class="Steps-box-cell">
                <div class="span1">付款成功</div>
                <div
                  class="span2"
                  v-if="orderState>0"
                >{{orderDetail.order_pay_time?orderDetail.order_pay_time:''}}</div>
              </div>
            </div>
          </Step>
          <Step title content="待发货">
            <div slot="content">
              <div class="Steps-box-cell">
                <div class="span1">待发货</div>
                <div class="span2" v-if="orderState>0">{{orderDetail.f_time?orderDetail.f_time:''}}</div>
              </div>
            </div>
          </Step>
          <Step title content="等待收货">
            <div slot="content">
              <div class="Steps-box-cell">
                <div class="span1">等待收货</div>
                <div
                  class="span2"
                  v-if="orderState>1"
                >{{orderDetail.order_sh_time?orderDetail.order_sh_time:''}}</div>
              </div>
            </div>
          </Step>
          <Step title content="等待收货">
            <div slot="content">
              <div class="Steps-box-cell">
                <div class="span1">已完成</div>
                <div
                  class="span2"
                  v-if="orderState>2"
                >{{orderDetail.orser_shtime?orderDetail.orser_shtime:''}}</div>
              </div>
            </div>
          </Step>
          <!-- <Step title content="已完成">
            <div slot="content">
              <div class="Steps-box-cell">
                <div class="span1">已完成</div>
                <div class="span2">2022-10-20 11:10:10</div>
              </div>
            </div>
          </Step>-->
        </Steps>
      </div>
      <div class="tabs">
        <span
          @click="changeTab(index)"
          :style="tabCurrent==index?'color:#1C4492;border-bottom:1px solid #1C4492':''"
          v-for="(item,index) in tabsData"
          :key="index"
        >{{item.text}}</span>
      </div>
      <div class="info" v-if="tabCurrent==0">
        <div class="titles">
          <span>处理时间</span>
          <span>处理信息</span>
        </div>
      </div>
      <div class="Steps-box2" v-if="tabCurrent==0">
        <Steps :current="order_log_listData.length-1" size="small" direction="vertical">
          <Step title content="提交订单" v-for="(item,index) in order_log_listData" :key="index">
            <div slot="content">
              <div class="Steps-box-cell2">
                <span class="span1">{{item.title}}</span>
                <span class="span2">{{item.add_time}}</span>
              </div>
            </div>
          </Step>
        </Steps>
      </div>
      <div class="order-list" v-else>
        <div class="top">
          <div class="left">
            <span>商品清单</span>
          </div>
          <div class="right">
            <span>发货单号：</span>
            <span>{{search_kuaidiData.son_order[0].son_order_code}}</span>
          </div>
        </div>
        <div class="order-title">
          <div class="span1">
            <span>商品</span>
          </div>
          <div class="span2">
            <span>单价/元</span>
          </div>
          <div class="span3">
            <span>数量</span>
          </div>
          <div class="span4">
            <span>状态</span>
          </div>
        </div>
        <div class="cells" @click="toDetail()">
          <div class="bottom">
            <div class="left">
              <div class="box" v-for="(item,index) in search_kuaidiData.son_order" :key="index">
                <div class="cell1">
                  <img style="width:100px;height:100px;" :src="item.imgs" alt />
                  <span>{{item.title}}</span>
                </div>
                <div class="cell2">
                  <span>{{item.order_pay_money}}</span>
                </div>
                <div class="cell3">
                  <span>{{item.order_num}}</span>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="cell4">
                <span v-if="search_kuaidiData.son_order[0].order_state==1">待支付</span>
                <span v-else-if="search_kuaidiData.son_order[0].order_state==2">待发货</span>
                <span v-else-if="search_kuaidiData.son_order[0].order_state==3">配送中</span>
                <span v-else-if="search_kuaidiData.son_order[0].order_state==4">已收货</span>
                <span v-else-if="search_kuaidiData.son_order[0].order_state==5">已取消</span>
                <span v-else-if="search_kuaidiData.son_order[0].order_state==6">已过期</span>
                <span v-else-if="search_kuaidiData.son_order[0].order_state==7">已退款</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Steps-box2" v-if="tabCurrent==1&&search_kuaidiData.data">
        <Steps :current="order_log_listData.length-1" size="small" direction="vertical">
          <Step title content="提交订单" v-for="(item,index) in search_kuaidiData.data" :key="index">
            <div slot="content">
              <div class="Steps-box-cell2">
                <span class="span2">{{item.time}}</span>
                <span class="span1">{{item.context}}</span>
              </div>
            </div>
          </Step>
        </Steps>
      </div>
      <div v-else-if="tabCurrent==1&&!search_kuaidiData.data" style="text-align:center;">
          <span>暂无快递信息..</span>
      </div>
      <div class="footers">
        <div class="footer-title">
          <span>收货信息</span>
        </div>
        <div class="footer-container">
          <div class="left">
            <div class="text">
              <span>基本信息</span>
            </div>
            <div class="cell">
              <span>姓名：</span>
              <span>{{orderDetail.order_add_name}}</span>
            </div>
            <div class="cell">
              <span>收货地址：</span>
              <span>{{orderDetail.order_add_address}}</span>
            </div>
            <div class="cell">
              <span>联系电话：</span>
              <span>{{orderDetail.order_add_tel}}</span>
            </div>
          </div>
          <div class="left">
            <div class="text">
              <span>配送信息</span>
            </div>
            <div class="cell">
              <span>配送方式：</span>
              <span>标准配送</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { my_order_det, order_log_list, search_kuaidi } from "@/api/api";
export default {
  data() {
    return {
      order_id: 0,
      orderDetail: {},
      orderState: 0,
      tabCurrent: 0,
      tabsData: [
        {
          text: "订单处理信息"
        },
        {
          text: "发货单"
        }
      ],
      order_log_listData: [], //订单处理信息
      search_kuaidiData: {} //订单处理信息
    };
  },
  activated() {
    let that = this;
    that.order_id = this.$route.query.order_id;
    that.getmy_order_det();
    that.getorder_log_list();
    that.getsearch_kuaidi();
  },
  methods: {
    changeTab(index) {
      this.tabCurrent = index;
    },
    //获取订单详情
    getmy_order_det() {
      let that = this;
      my_order_det(that.order_id).then(res => {
        that.orderDetail = res.data;
        switch (res.data.order_state) {
          case 1:
            that.orderState = 0;
            break;
          case 2:
            that.orderState = 1;
            break;
          case 3:
            that.orderState = 2;
            break;
          case 4:
            that.orderState = 3;
            break;
          // case 5:
          //   that.orderState = 5;
          //   break;
          // case 6:
          //   that.orderState = 5;
          //   break;
          // case 7:
          //   that.orderState = 5;
          //   break;
        }
      });
    },
    //获取订单处理信息
    getorder_log_list() {
      let that = this;
      order_log_list({ type: 2, order_id: that.order_id }).then(res => {
        that.order_log_listData = res.data;
      });
    },
    //获取物流信息
    getsearch_kuaidi() {
      let that = this;
      search_kuaidi(that.order_id).then(res => {
        that.search_kuaidiData = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.myOrder {
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  .container {
    width: 60%;
    .title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #282828;
      padding: 17px 0;
      border-bottom: 1px solid #dedede;
    }
    .Steps-box {
      padding: 70px 30px;
    }
    .tabs {
      width: 100%;
      border-bottom: 1px solid #d9d9d9;
      span {
        margin-right: 45px;
        display: inline-block;
        padding: 8px 5px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
      span:hover {
        cursor: pointer;
      }
    }
    .info {
      height: 41px;
      line-height: 41px;
      background: #fafafa;

      .titles {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        span {
          display: inline-block;
          text-align: center;
        }
        span:nth-child(1) {
          width: 40%;
        }
        span:nth-child(2) {
          width: 60%;
        }
      }
    }
    .order-list {
      margin-bottom: 30px;
      .top {
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 10px 20px;
      }
      .order-title {
        height: 40px;
        background: #fafafa;
        display: flex;
        align-items: center;
        div {
          height: 14px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          text-align: center;
          border-right: 1px solid #dedede;
        }
        .span1 {
          width: 42%;
        }
        .span2 {
          width: 14%;
        }
        .span3 {
          width: 14%;
        }
        .span4 {
          width: 30%;
        }
      }
      .cells {
        .bottom {
          display: flex;
          border-top: 1px solid #dedede;
          border-bottom: 1px solid #dedede;
          .left {
            width: 70%;
            .box {
              width: 100%;
              display: flex;
              border-bottom: 1px solid #dedede;
              .cell1 {
                display: flex;
                height: 120px;
                width: 60%;
                border-right: 1px solid #dedede;
              }
              .cell2 {
                height: 120px;
                width: 20%;
                border-right: 1px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .cell3 {
                width: 20%;
                height: 120px;
                border-right: 1px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .box:last-child {
              border-bottom: none;
            }
          }
          .right {
            display: flex;
            width: 30%;
            .cell4 {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              border-right: 1px solid #dedede;
            }
            // .cell5 {
            //   width: 25%;
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            //   border-right: 1px solid #dedede;
            // }
            // .cell6 {
            //   width: 50%;
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            // }
          }
        }
      }
    }
    .footers {
      .footer-title {
        padding: 12px 0;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3a3a3a;
        border-bottom: 1px solid #d9d9d9;
      }
      .footer-container {
        display: flex;
        padding: 20px 0 12px 0px;
        .left {
          padding-left: 30px;
          width: 50%;
          .text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3a3a3a;
            margin-bottom: 10px;
          }
          .cell {
            padding: 3px 0;
            span:nth-child(1) {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #bbbbbb;
            }
            span:nth-child(2) {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3a3a3a;
            }
          }
        }
        .left:nth-child(1) {
          border-right: 1px solid #f2f2f2;
        }
      }
    }
  }
}
</style>
<style lang="less">
.Steps-box {
  .ivu-steps {
    padding-left: 28px;
  }
  .ivu-steps-content {
    margin-left: -28px;
  }
  .ivu-steps-item {
    overflow: unset !important;
  }
  .ivu-steps-main {
    position: relative;
  }
  .ivu-steps-content {
    position: absolute !important;
    top: -54px;
    left: -90px;
    padding-left: 0 !important;
  }
  .ivu-steps-head-inner {
    width: 30px;
    height: 30px;
  }
  .Steps-box-cell {
    width: 200px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    .span1 {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #282828 !important;
    }
    .span2 {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #282828;
    }
  }
}
.Steps-box2 {
  padding: 20px 70px;

  .Steps-box-cell2 {
    margin-top: -4px;
    padding-left: 50px;
    .span1 {
      margin-right: 100px;
    }
  }
}
</style>