<template>
  <div v-html="content"></div>
</template>
  
  <script>
import { advantage_det } from "@/api/api";
export default {
  data() {
    return {
      id: 0,
      content: ""
    };
  },
  activated() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      advantage_det(this.id).then(res => {
        if (res.code == 200) {
          this.content = res.data.content;
        }
      });
    }
  }
};
</script>
  
  <style>
</style>