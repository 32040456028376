<template>
  <div v-html="detailData.content"></div>
</template>

<script>
import { num_type_det } from "@/api/api";
export default {
  data() {
    return {
        id:0,
        detailData:{}
    };
  },
  activated(){
    this.id=this.$route.query.id;
    this.getDetails()
  },
  methods: {
    getDetails() {
      let that = this;
      num_type_det(that.id).then(res => {
        // console.log("res", res);
        
        if (res.code == 200) {
            that.detailData=res.data;
        //   this.$Message.success(res.message);
        }
      });
    }
  }
};
</script>

<style>
</style>