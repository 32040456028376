<template>
  <div class="mainpageboxs">
    <!-- <Header :startWidth="80" :startTop="50" :currentRouter="'/news'"></Header> -->
    <div class="header-img">
      <img v-if="banner_list.length>0" :src="banner_list.length>0?banner_list[0].ads_pic:''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="header-search">
      <img
        :src="require('@/assets/search.png')"
        style="width: 26px; height: 26px; margin-left: 10px"
      />
      <input type="text" placeholder="请输入要查询的内容" v-model="pages.search" />
      <span @click="getNews_list">搜索</span>
    </div>
    <div class="tabs-top">
      <span
        class="tabs-top-span"
        :class="tabsTopCurent == index ? 'tabsTopChecked' : ''"
        v-for="(item, index) in oneType"
        :key="index"
        @click="tabsTopChange(item, index)"
      >{{ item.names }}</span>
    </div>
    <div class="caringLeadership">
      <div class="leadership-container">
        <div
          :style="modelCurrent===index?`transform:translateY(-30px)`:''"
          class="container-box"
          v-for="(item, index) in news_list"
          :key="index"
          @click="toDetail(item)"
          @mouseenter="mouseenterModel(index)"
          @mouseleave="mouseleaveModel(index)"
        >
   
            <img
              class="container-img"
              :src="item.imgs"
              alt
            />

          <div class="span-box">
            <span class="container-title">{{ item.title }}</span>
            <span class="container-time">{{ item.show_date }}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: right; margin: 50px 0">
      <Page
        :total="total"
        show-total
        @on-change="pageChange"
        :current="pages.page"
        show-elevator
        :page-size="9"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import { newType_list, newTwo_type, news_list, banner_list } from "@/api/api";
export default {
  components: { Header },
  data() {
    return {
      oneType: [],
      twoType: [],
      news_list: [],
      tabsTopCurent: 0,
      tabsCurrent: 0,
      pages: {
        page: 1, //	否	string	无
        pz: 9, //		20	否	string	无
        type_two_id: 0, //		34	是	string	服务类型一级级类型id
        search: "" //		湖北省黄冈市	否	string	搜索关键字
      },
      total: 0,
      banner_list: [],
      modelCurrent: ""
    };
  },
  mounted() {
    this.getOneType();
    this.getbanner_list();
  },
  methods: {
    //移出
    mouseleaveModel(index) {
      this.modelCurrent = "";
    },
    //移入
    mouseenterModel(index) {
      this.modelCurrent = index;
    },
    getbanner_list() {
      banner_list(4).then(res => {
        this.banner_list = res.data;
      });
    },
    //获取一级分类
    getOneType() {
      newType_list().then(res => {
        // console.log("oneType", res);
        this.oneType = res.data.list;
        this.pages.type_two_id = res.data.list[0].id;
        // this.getTwoType(res.data.list[0].id);
        this.getNews_list();
      });
    },
    //获取二级分类
    // getTwoType(id) {
    //   newTwo_type(id).then(res => {
    //     // console.log("twoType", res);
    //     this.twoType = res.data.list;
    //     this.pages.type_two_id = res.data.list[0].id;
    //   });
    // },
    //获取列表
    getNews_list() {
      news_list(this.pages).then(res => {
        console.log("news_list", res);
        this.news_list = res.data.list;
        this.total = res.data.total;
      });
    },
    toDetail(item) {
      if (item.w_link == "") {
        //跳内部富文本
        this.$router.push({ path: "/newDetail", query: { id: item.id } });
        //  let router = this.$router.resolve({
        //   path: "/newDetail",
        //   query: { id: item.id },
        // });
        // window.open(router.href, "_blank");
      } else {
        window.open(item.w_link, "_blank");
        //跳外部链接
      }
    },
    //点击一级分类
    tabsTopChange(item, index) {
      this.tabsTopCurent = index;
      this.pages.type_two_id = item.id;
      this.pages.page = 1;
      this.getNews_list();
      // this.getTwoType(item.id);
    },
    // //点击二级分类
    // tabsChange(item, index) {
    //   this.tabsCurrent = index;
    //   this.pages.type_two_id = item.id;
    //   this.getNews_list();
    // },
    //分页的页数变化后触发
    pageChange(e) {
      let that = this;
      that.pages.page = e;
      that.getNews_list();
    }
  }
};
</script>

<style lang="less" scoped>
.mainpageboxs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-img img {
  width: 100%;
  display: block;
  min-height: 200px;
}
.header-search {
  z-index: 1;
  margin-top: -25px;
  width: 65%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 2px #e2e2e2;
  input {
    padding-left: 20px;
    height: 50px;
    border: none;
    width: calc(100% - 120px);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    outline: none;
  }
  span {
    display: inline-block;
    width: 120px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #5075ec;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 8px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.tabsTopChecked {
  color: #3255fa;
  border-bottom: 3px solid #3255fa;
}
.tabs-top {
  width: 65%;
  margin-top: 80px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  .tabs-top-span {
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    font-family: sanMedium;
    // transition: all 0.5s;
    font-size: 24px;
    color: #2e2f34;
  }
}

.caringLeadership {
  margin-top: 60px;
  width: 65%;
  // .tabs-span {
  //   display: inline-block;
  //   padding: 6px 30px;
  //   background: #f1f1f1;
  //   color: #737373;
  //   font-size: 18px;
  //   font-family: sanRegular;
  //   margin-right: 10px;
  //   transition: all 0.5s;
  // }
  // .checkedTabs {
  //   background: #14508c;
  //   color: #ffffff;
  // }
  .leadership-container {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 20px;
  }

  .container-box {
    transition: all 0.5s;
    width: 32%;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    // background: #729fde;
    // margin-right: 10px;
    margin-bottom: 2%;
    margin-right: 2%;

    .container-img {
      width: 100%;
      display: block;
      transition: all 0.5s;
      min-height: 216px;
    }

    .span-box {
      height: 100px;
      padding: 10px 10px 20px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .container-title {
        font-size: 14px;
        color: #2e2f34;
        font-family: sanMedium;
        overflow: hidden;
        text-overflow: ellipsis; /* 超出部分省略号 */
        word-break: break-all; /* break-all(允许在单词内换行。) */
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 2; /** 显示的行数 **/
      }
    }
  }
  .container-box:nth-child(3n) {
    margin-right: 0 !important;
  }
}
</style>