<template>
  <div class="mainpageboxs">
    <!-- <List :listData="listData"></List> -->
    <div class="caringLeadership">
      <div class="tabs">
        <span
          class="tabs-span"
          :class="tabsCurrent == index ? 'checkedTabs' : ''"
          v-for="(item, index) in type_list"
          :key="index"
          @click="tabsChange(item, index)"
          >{{ item.name }}</span
        >
      </div>
      <div class="leadership-container">
        <div
          class="container-box"
          v-for="(item, index) in care_list"
          :key="index"
          @click="toDetail(item)"
        >
          <img class="container-img" :src="item.imgs" alt />
          <div class="span-box">
            <span class="container-title">{{ item.title }}</span>
            <span class="container-time">{{ item.show_date }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ctype_list, care_list } from "@/api/api";
export default {
  components: {},
  data() {
    return {
      type_list: [],
      listData: [],
      tabsCurrent: 0,
      care_list: [],
      pages: {
        page: 1, //	否	string	无
        pz: 20, //	否	string	无
        t_id: 0,
      },
      total: 0,
    };
  },
  mounted() {
    this.getctype_list();
  },
  methods: {
    //获取分类
    getctype_list() {
      let that = this;
      ctype_list().then((res) => {
        // console.log("type_list", res);
        that.type_list = res.data.list;
        that.total = res.data.total;
        that.pages.t_id = res.data.list[0].id;
        that.getCare_list();
      });
    },
    //获取列表
    getCare_list() {
      let that = this;
      care_list(this.pages).then((res) => {
        // console.log("care_list", res);
        that.care_list = res.data.list;
      });
    },
    toDetail(item) {
      if (item.w_link == "") {
        //跳内部富文本
        this.$router.push({ path: "/caringDetail", query: { id: item.id } });
        // let router = this.$router.resolve({
        //   path: "/caringDetail",
        //   query: { id: item.id },
        // });
        // window.open(router.href, "_blank");
      } else {
        window.open(item.w_link, "_blank");
        //跳外部链接
      }
    },
    tabsChange(item, index) {
      this.tabsCurrent = index;
      this.pages.page = 1;
      this.pages.t_id = item.id;
      this.getCare_list();
    },
  },
};
</script>

<style scoped>
.mainpageboxs {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.caringLeadership {
  width: 79%;
}
.tabs-span {
  display: inline-block;
  padding: 6px 30px;
  background: #f1f1f1;
  color: #737373;
  font-size: 18px;
  font-family: sanRegular;
  margin-right: 10px;
  transition: all 0.5s;
  flex-shrink: 0;
}
.checkedTabs {
  background: #14508c;
  color: #ffffff;
}
.leadership-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.container-box {
  width: 300px;
  margin-right: 10px;
  background: #f5f5f5;
  margin-bottom: 20px;
}
.container-box:nth-child(3n) {
  margin-right: 0 !important;
}
.container-img {
  width: 300px;
  height: 170px;
  display: block;
}
.span-box {
  height: 130px;
  padding: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container-title {
  font-size: 20px;
  font-family: sanMedium;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分省略号 */
  word-break: break-all; /* break-all(允许在单词内换行。) */
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2; /** 显示的行数 **/
}
</style>