<template>
  <div class="history">
    <div class="history-content" v-html="content"></div>
  </div>
</template>

<script>
import { lc_det } from "@/api/api";
export default {
  data() {
    return {
      id:0,
      content: "",
    };
  },
  mounted() {},
  activated() {
    this.id=this.$route.query.id;
    this.getlc_det();
  },
  methods: {
    //获取详情
    getlc_det() {
      let that = this;
      lc_det(that.id).then((res) => {
        // console.log("content", res);
        that.content = res.data.content;
      });
    },
  },
};
</script>

<style>
.history {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.history-content {
  width: 80%;
}
</style>