import axios from 'axios';
import qs from 'qs';
import {
  Message
} from 'view-design';

const baseUrl = 'https://www.eut.org.cn'

// 超时设定
axios.defaults.timeout = 15000;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  return config;
}, err => {
  Message.error({
    background: true,
    content: '请求超时'
  });
  return Promise.resolve(err);
});


// http response 拦截器
axios.interceptors.response.use(response => {
  const data = response.data;
  // 根据返回的code值来做不同的处理(和后端约定)
  // console.log(data);
  switch (data.code) {
    case 400:
      Message.warning({
        background: true,
        content: data.msg !== null ? data.msg : "未知错误"
      });
      break;
    case 500:
      // 错误
      Message.warning({
        background: true,
        content: data.msg !== null ? data.msg : "未知错误"
      });
      break;
    case 502:
      // 错误
      Message.warning({
        background: true,
        content: data.msg !== null ? data.msg : "未知错误"
      });
      break;
    default:
      return data;
  }

  return data;
}, (err) => {
  // if (err && err.response) {
  //     if (err.response.request.responseType === "arraybuffer") {
  //         let enc = new TextDecoder("utf-8");
  //         err.response.data = JSON.parse(enc.decode(new Uint8Array(err.response.data)));
  //     }
  //     if (err.response.status === 400) {
  //         Message.warning({ background: true, content: err.response.data.msg });
  //     } else {
  //         Message.error({ background: true, content: err.response.data.msg ? err.response.data.msg : err.message });
  //     }
  // } else {
  //     Message.error({ background: true, content: err.message });
  // }
  return Promise.resolve(err);
});


export const getRequestOther = (url, params) => {
  return axios({
    method: 'get',
    url: url,
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    },
    headers: {}
  });
};


export const getRequest = (url, params) => {
  return axios({
    method: 'get',
    url: `${baseUrl}${url}`,
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    },
    headers: {}
  });
};

export const expExcelRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    responseType: 'arraybuffer',
    data: params,
    transformRequest: [function (data) {
      return JSON.stringify(data);
    }],
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const postRequestDoc = (url, params) => {
  return axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    data: params,
    transformRequest: [function (data) {
      return JSON.stringify(data);
    }],
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

export const putRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${baseUrl}${url}`,
    data: params,
    transformRequest: [function (data) {
      delete data.isDeleted;
      delete data.creator;
      delete data.createDate;
      return JSON.stringify(data);
    }],
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const deleteRequest = (url, params) => {
  return axios({
    method: 'delete',
    url: `${baseUrl}${url}`,
    // params: params,
    headers: {}
  });
};

export const uploadFileRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    params: params,
    headers: {}
  });
};