<template>
  <div class="footerbox">
    <div class="footer-container">
      <div class="footer-bar">
        <span class v-for="(item, index) in tabbars" :key="index" @click="toPages(item, index)">{{ item }}</span>
      </div>
      <div class="footer-all">
        <div class="all-left">
          <div class="all-left-top">
            <div class="top1">
              <img src="@/assets/newImg/phones.png" alt />
              <span>{{ biz_listData1[0].hot_tel }}</span>
            </div>
            <div class="top2">
              <span>（总部：全国统一咨询热线）</span>
            </div>
          </div>
          <div class="all-left-bottom">
            <div class="bottom1">
              <img src="@/assets/newImg/calls.png" alt />
              <span>举报专线：{{ biz_listData1[0].hot_tel }}</span>
            </div>
            <div class="bottom1">
              <img src="@/assets/newImg/locations.png" alt />
              <span>地址：{{ biz_listData1[0].addr }}</span>
            </div>
            <div class="bottom2">
              <img src="@/assets/newImg/searchs.png" alt />
              <img src="@/assets/newImg/maps.jpg" alt />
              <span>
                搜索“
                <span class="text-t">易尤特集团</span>”或“
                <span class="text-t">鑫达路8号</span>”，即可导航到我公司总部
              </span>
            </div>
          </div>
        </div>
        <div class="all-right">
          <div class="ps">
            <!-- <img src="@/assets/newImg/jtwx.jpg" alt />
            <span>添加易尤特集团微信</span> -->
          </div>
          <div class="ps">
            <img src="@/assets/newImg/jtgzh.jpg" alt />
            <span>关注易尤特集团公众号</span>
          </div>
        </div>
      </div>
      <div class="frients-lines">
        <span>友情链接:</span>
        <span v-for="(item, index) in links_listData" :key="index" @click="toLink(item)">{{ item.title }}</span>
      </div>
      <div class="footer-img">
        <img :src="item.imgs" @click="toUrls(item)" v-for="(item, index) in links_listData2" :key="index" alt />
      </div>
      <div class="footer-last">
        <span @click="openOther">©2003-2022 All Rights Reserved 嘉善县易尤特科技股份有限公司 浙ICP备17027634号-2</span>
      </div>
    </div>
  </div>
</template>

<script>
import { links_list, biz_list } from "@/api/api";

export default {
  data() {
    return {
      tabbars: [
        "首页",
        "集团介绍",
        "产品结构",
        "数字产品",
        "物联产品",
        "技术服务",
        "发展历程",
        "企业动态",
        "投诉举报",
        "联系我们"
      ],
      ring_list: [],
      sertype_top: [],
      oneType: [],
      biz_listData1: [],
      biz_list1: [],
      biz_list3: [],
      links_listData: [],
      links_listData2: []
    };
  },
  mounted() {
    this.getlinks_list();
    this.getlinks_list2();
    this.getbiz_list1();
  },
  methods: {
    toPages(item, index) {
      let that=this;
      switch (index) {
        case 0:
          that.$router.push({ path: "/index" });
          break;
        case 1:
          that.$router.push({ path: "/aboutus" });

          break;
        case 2:
          that.$router.push({ path: "/productStructure" });
          break;
        case 3:
          that.$router.push({ path: "/digitalproducts" });
          break;
        case 4:
          that.$router.push({ path: "/unionproduct" });
          break;
        case 5:
          that.$router.push({ path: "/professionalservices" });
          break;
        case 6:
          that.$router.push({ path: "/History" });

          break;
        case 7:
          that.$router.push({ path: "/news" });

          break;
        case 8:
          that.$router.push({ path: "/complaint" });
          break;
        case 9:
          that.$router.push({ path: "/callme" });
          break;
      }
    },
    //关于我们
    getring_list() {
      let that = this;
      ring_list().then(res => {
        let list = res.data.list;
        let arr = [{ title: "集团介绍" }];
        for (let i = 0; i < list.length; i++) {
          arr.push(list[i]);
        }
        that.ring_list = arr;
      });
    },
    //产品服务
    getsertype_top() {
      let that = this;
      sertype_top().then(res => {
        let list = res.data.list;
        let arr = [{ names: "专业服务" }];
        for (let i = 0; i < list.length; i++) {
          arr.push(list[i]);
        }
        that.sertype_top = arr;
      });
    },
    //企业新闻 获取一级分类
    getOneType() {
      newType_list().then(res => {
        let list = res.data.list;
        let arr = [{ names: "企业动态" }];
        for (let i = 0; i < list.length; i++) {
          arr.push(list[i]);
        }
        this.oneType = arr;
      });
    },
    //联系我们 1
    getbiz_list1() {
      let that = this;
      biz_list(1).then(res => {
        that.biz_list1.push({ names: "联系我们" });
        that.biz_list1.push({ names: res.data.list[0].addr });
        that.biz_list1.push({ names: res.data.list[0].hot_tel });
        that.biz_list1.push({ names: res.data.list[0].fax });
      });
    },
    // 分子公司
    getbiz_list3() {
      let that = this;
      biz_list(3).then(res => {
        let list = res.data.list;
        let arr = [{ title: "分子公司" }];
        for (let i = 0; i < list.length; i++) {
          arr.push(list[i]);
        }
        that.biz_list3 = arr;
      });
    },
    toLink(item) {
      window.open(item.link_url, "_blank");
    },
    //友情链接
    getlinks_list() {
      links_list(1).then(res => {
        // console.log("links_listData", res);
        this.links_listData = res.data.list;
      });
    },
    //友情链接
    getlinks_list2() {
      links_list(2).then(res => {
        // console.log("links_listData2", res);
        this.links_listData2 = res.data.list;
      });
    },
    //总部
    getbiz_list1() {
      let that = this;
      biz_list({ type: 1, search: "" }).then(res => {
        this.biz_listData1 = res.data.list;
      });
    },
    toUrls(item) {
      window.open(item.link_url, "_blank");
    },
    openOther() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    }
  }
};
</script>

<style lang="less" scoped>
.footerbox {
  width: 100%;
  /* border-top: 4px solid #f96503; */
  background: #272b2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-container {
  width: 80%;
  padding: 80px 0 30px 0;
}

.footer-bar {
  display: flex;
  justify-content: space-between;
}

.footer-bar span {
  display: inline-block;
  width: calc(100% / 7);
  text-align: center;
  border-right: 2px solid #aeafaf;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #d2d2d2;
}

.footer-bar span:last-child {
  border-right: none;
}

.footer-bar span:hover {
  cursor: pointer;
}

.footer-all {
  display: flex;
  justify-content: space-between;
  padding: 60px 40px;
}

.all-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.all-left-top {}

.top1 {
  display: flex;
  align-items: center;
}

.top1 img {
  width: 30px;
  margin-right: 10px;
}

.top1 span {
  font-size: 31px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #0094f7;
}

.top2 {
  padding-left: 60px;
}

.top2 span {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #d2d2d2;
}

.all-left-bottom {}

.bottom1 {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.bottom1 img {
  width: 20px;
  margin-right: 10px;
}

.bottom1 span {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #d2d2d2;
}

.bottom2 {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.bottom2 img:nth-child(1) {
  width: 20px;
  margin-right: 10px;
}

.bottom2 img:nth-child(2) {
  width: 45px;
  margin-right: 10px;
}

.bottom2 span {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #d2d2d2;
}

.text-t {
  color: #0094f7 !important;
}

.all-right {
  display: flex;
}

.all-right .ps {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 60px;
}

.all-right .ps img {
  width: 200px;
  height: 200px;
  display: block;
}

.all-right .ps span {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #d2d2d2;
  margin-top: 12px;
}

.frients-lines {
  padding: 30px 10px;
  border-bottom: 1px solid #4e4e4e;
  border-top: 1px solid #4e4e4e;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #d2d2d2;
}

.frients-lines span {
  margin-right: 20px;
}

.frients-lines span:hover {
  cursor: pointer;
}

.footer-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.footer-img img {
  width: 140px;
  border-radius: 8px;
  margin: 0 15px;
}

.footer-last {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #d2d2d2;

  span:hover {
    cursor: pointer;
  }
}
</style>