<template>
  <div class="Complaint">
    <!-- <Header :startWidth="80" :startTop="50" :currentRouter="'/complaint'"></Header> -->
    <div class="header-img">
      <img v-if="banner_list.length>0" :src="banner_list.length>0?banner_list[0].ads_pic:''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="box">
      <div class="container">
        <Form></Form>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
// import { lc_type_list, lc_list } from "@/api/api";
import Form from "./components/Form/Form.vue";
import { banner_list } from "@/api/api";
export default {
  components: {
    Header,
    Form
  },
  data() {
    return {
      banner_list: []
    };
  },
  mounted() {
    this.getbanner_list();
  },
  methods: {
    //合作伙伴
    getbanner_list() {
      banner_list(7).then(res => {
        this.banner_list = res.data;
        // console.log("banner_list", this.banner_list);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.header-img img {
  width: 100%;
  display: block;
}
.box {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    width: 63%;
  }
}
</style>