<template>
  <div class="mainpage">
    <div class="mainpagebox">
      <template v-if="showHeader === true">
        <Header :startWidth="80" :startTop="50" :currentRouter="router.path" :isLogin="isLogin" :userInfo="userInfo">
        </Header>
      </template>
      <template v-else-if="showHeader === false">
        <Header-two :isLogin="isLogin" :userInfo="userInfo" :bgc="bgc"></Header-two>
      </template>
      <!-- <Header :noteList="noteList" :currentRouter="currentRouter"></Header> -->
      <!-- <div style="width:100%;display:flex;justify-content: center;">
        <div class="banner-title">
          <span>{{ router.meta.title }}</span>
          <span>{{ router.meta.titlei }}</span>
        </div>
      </div>-->

      <!-- <Banner :isShow="isShow" :bannerData="bannerData"></Banner> -->
      <keep-alive>
        <router-view />
      </keep-alive>
      <div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Header from "@/components/Header/Header.vue";
import HeaderTwo from "@/components/HeaderTwo/HeaderTwo.vue";
import Banner from "@/components/Banner/Banner.vue";
import List from "@/components/List/List.vue";
import Footer from "@/components/Footer/Footer.vue";
import { paycar_list } from "@/api/api";
export default {
  components: {
    Header,
    HeaderTwo,
    Banner,
    List,
    Footer
  },
  data() {
    return {
      showHeader: true,
      router: {},
      currentRouter: {},
      bannerData: {},
      noteList: [],
      isShow: true,
      bgc: "",
      // carLen: 0,
    };
  },
  created() { },
  mounted() {
    this.getpaycar_list();
  },
  computed: {
    ...mapState(["isLogin", "userInfo"])
  },
  watch: {
    $route: {
      handler(route) {
        window.scrollTo(0, 0);
        this.currentRouter = route.path;
        this.router = route;
        this.showHeader = route.meta.showHeader;
        this.showHeader = route.meta.showHeader;
        this.bgc = route.meta.bgc;
        // switch (route.path){
        //   case :
        //   break;
        // }
      },
      immediate: true
    },
    userInfo(value) {
      // console.log("userInfo", value);
    }
  },
  methods: {
    ...mapMutations(["setCarLen"]),
    getpaycar_list() {
      let that = this;
      paycar_list()
        .then(res => {
          // that.carLen = res.data.list;
          that.$store.commit("setCarLen",  res.data.list.length);
        })
        .catch(err => { });
    },
  }
};
</script>

<style scoped>
.mainpage {
  display: flex;
  justify-content: center;
}

.mainpagebox {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 3px #d8d8d8;
  border-left: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
}

.banner-title {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.banner-title span:nth-child(1) {
  font-size: 28px;
  color: #5f5f5f;
  margin-bottom: 2px;
  font-family: sanMedium;
}

.banner-title span:nth-child(2) {
  font-size: 12px;
  color: #838383;
}
</style>