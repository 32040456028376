<template>
  <!-- 专业服务 -->
  <div class="servicespages">
    <!-- <Header :startWidth="80" :startTop="50" :currentRouter="'/professionalservices'"></Header> -->
    <div class="header-bimg">
      <img v-if="banner_list.length>0" :src="banner_list.length>0?banner_list[0].ads_pic:''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="service-model">
      <div
        :style="modelCurrent===index?`transform:translateY(-30px)`:''"
        @mouseleave="mouseleaveModel(index)"
        @mouseenter="mouseenterModel(index)"
        class="service-son"
        v-for="(item, index) in typeList"
        :key="index"
        @click="toDetail(item)"
      >
        <img :src="item.no_select" alt />
        <span class="span1" style="margin-bottom:30px;">{{ item.name }}</span>
        <span class="span2">{{item.desc}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import { banner_list, ptype_list } from "@/api/api";
export default {
  components: { Header },
  data() {
    return {
      banner_list: [],
      typeList: [],
      modelCurrent:"",
    };
  },
  mounted() {
    this.getptype_list();
    this.getbanner_list();
  },
  methods: {
    //移出
    mouseleaveModel(index) {
      this.modelCurrent = "";
    },
    //移入
    mouseenterModel(index) {
      this.modelCurrent = index;
    },
    getbanner_list() {
      banner_list(11).then(res => {
        this.banner_list = res.data;
      });
    },
    //获取头部分类
    getptype_list() {
      let that = this;
      ptype_list(2).then(res => {
        that.typeList = res.data;
      });
    },
    // //获取分类列表
    // getsertype_top() {
    //   let that = this;
    //   sertype_top().then(res => {
    //     // console.log("sertype_top", res);
    //     that.sertype_top = res.data.list;
    //   });
    // },
    //查看详情
    toDetail(item) {
      this.$router.push({
        path: "/serviceDetail",
        query: { id: item.id, title: item.name }
      });
      // let router = this.$router.resolve({
      //   path: "/serviceDetail",
      //   query: { id: item.id },
      // });
      // window.open(router.href, "_blank");
    }
  }
};
</script>

<style lang="less" scoped>
.servicespages {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bimg {
  width: 100%;

  margin-bottom: 40px;
  img {
    width: 100%;
    display: block;
    min-height: 200px;
  }
}
.service-model {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  margin: 80px 0;
}

.service-son {
  transition: all .5s;
  width: 23.6%;
  padding: 3% 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #f7f7f7;
  border: 2px solid #ededef;
  margin-right: 1.8%;
  margin-bottom: 1.8%;
}
.service-son:hover {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}
.service-son:nth-child(4n) {
  margin-right: 0;
}
.service-son img {
  width: 9em;
  height: 9em;
  margin-bottom: 20px;
}
.span1 {
  font-family: sanMedium;
  font-size: 24px;
  font-weight: 500;
  color: #2e2f34;
}
.span2 {
  font-family: sanMedium;
  font-size: 14px;
  color: #8d8d8d;
}
</style>