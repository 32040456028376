<template>
  <div class="formpages">
    <div class="form-box">
      <div class="radio-box">
        <label for>选择类型：</label>
        <div class="radio-cell" v-for="(item, index) in radioDara" :key="index">
          <input
            class="radio"
            type="radio"
            :name="item.value"
            :value="item.value"
            :checked="checkedCurrent===index?true:false"
            @click="radioChange(item,index)"
          />
          {{ item.text }}
        </div>
      </div>
      <div v-if="checkedCurrent==0">
        <div class="select-box">
          <label for>公司：</label>
          <input class="inputs" type="text" v-model="formItem.title" />
        </div>
        <div class="select-box">
          <label for>我的姓名：</label>
          <input class="inputs" type="text" v-model="formItem.names" />
          <input @click="nameChange()" class="radio" :checked="isNameChecked" type="radio" />匿名
        </div>
        <div class="select-box" style="justify-content: space-between">
          <div class="box-son" style="display: flex">
            <label for>联系电话：</label>
            <input
              v-model="formItem.tel"
              class="inputs"
              style="width: 100%; margin-right: 0"
              type="text"
              maxlength="11"
            />
          </div>
          <div class="box-son" style="display: flex">
            <label for>电子邮箱：</label>
            <input
              v-model="formItem.em"
              class="inputs"
              style="width: 100%; margin-right: 0"
              type="text"
            />
          </div>
        </div>
        <div class="radio-box">
          <label for>咨询类别：</label>
          <CheckboxGroup v-model="formItem.other_type2">
            <Checkbox
              :label="item.text"
              v-for="(item, index) in checkedData"
              :key="index"
              :value="item.value"
            >{{item.text}}</Checkbox>
          </CheckboxGroup>
        </div>
        <div class="select-box">
          <label for>咨询内容：</label>
          <input
            class="inputs"
            style="width: 100%; margin-right: 0"
            type="text"
            v-model="formItem.content"
          />
        </div>
      </div>
      <div v-if="checkedCurrent==1">
        <div class="select-box">
          <label for>投诉标题：</label>
          <input
            class="inputs"
            style="width: 100%; margin-right: 0"
            type="text"
            v-model="formItem.title"
          />
        </div>
        <div class="select-box">
          <label for>被投诉单位/人员：</label>
          <input
            class="inputs"
            style="width: 100%; margin-right: 0"
            type="text"
            v-model="formItem.other_name"
          />
        </div>
        <div class="select-box">
          <label for>投诉人姓名：</label>
          <input class="inputs" type="text" v-model="formItem.names" />
          <input @click="nameChange()" class="radio" :checked="isNameChecked" type="radio" />匿名
        </div>
        <div class="select-box" style="justify-content: space-between">
          <div class="box-son" style="display: flex">
            <label for>联系电话：</label>
            <input
              v-model="formItem.tel"
              class="inputs"
              style="width: 100%; margin-right: 0"
              type="text"
              maxlength="11"
            />
          </div>
          <div class="box-son" style="display: flex">
            <label for>电子邮箱：</label>
            <input
              v-model="formItem.em"
              class="inputs"
              style="width: 100%; margin-right: 0"
              type="text"
            />
          </div>
        </div>
        <div class="select-box">
          <label for>投诉内容：</label>
          <input
            class="inputs"
            style="width: 100%; margin-right: 0"
            type="text"
            v-model="formItem.content"
          />
        </div>
        <div class="select-box">
          <label for>上传材料：</label>
          <div>
            <Upload
              multiple
              :action="`${url}/api/other/uploads_img`"
              ref="upload"
              :headers="headers"
              :show-upload-list="false"
              :on-success="handleSuccess"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :before-upload="handleBeforeUpload"
              :on-progress="handleBeProgress"
            >
              <Button>点击上传材料</Button>
              <div
                v-for="(item,index) in uploadNames"
                :key="index"
                style="font-size:13px;position: relative;"
              >
                <span>{{item}}</span>
                <img
                  @click.stop="handleRemove"
                  style="position: absolute;right:-10px;top:calc(50% - 7px);width:14px;height:14px"
                  src="@/assets/newImg/close.png"
                  alt
                />
              </div>
              <div>
                <Progress :percent="percent" v-if="percent>0&&percent<100" />
                <!-- percent -->
              </div>
            </Upload>
          </div>
        </div>
        <div class="select-box">
          <label for>事由位置：</label>
          <div class="select-box2">
            <div class="select-cell">
              <select v-model="prvIndex" @change="secelectPrv">
                <option value>请选择省份</option>
                <option :value="index" v-for="(item, index) in area" :key="index">{{ item.name }}</option>
              </select>
            </div>
            <div class="select-cell">
              <select v-model="cityIndex" @change="secelectCity">
                <option value>请选择城市</option>
                <option :value="index" v-for="(item, index) in city" :key="index">{{ item.name }}</option>
              </select>
            </div>
            <div class="select-cell">
              <select v-model="formItem.qu" @change="secelectQu">
                <option value>请选择县区</option>
                <option :value="item.name" v-for="(item, index) in qu" :key="index">{{ item.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="select-box">
          <label for>详细地址：</label>
          <input
            v-model="formItem.addr"
            class="inputs"
            style="width: 100%; margin-right: 0"
            type="text"
          />
        </div>
      </div>
      <div v-if="checkedCurrent==2">
        <div class="select-box">
          <label for>举报标题：</label>
          <input
            class="inputs"
            style="width: 100%; margin-right: 0"
            type="text"
            v-model="formItem.title"
          />
        </div>
        <div class="select-box">
          <label for>被举报人员：</label>
          <input
            class="inputs"
            style="width: 100%; margin-right: 0"
            type="text"
            v-model="formItem.other_name"
          />
        </div>
        <div class="select-box">
          <label for>举报人姓名：</label>
          <input class="inputs" type="text" v-model="formItem.names" />
          <input @click="nameChange()" class="radio" :checked="isNameChecked" type="radio" />匿名
        </div>
        <div class="select-box" style="justify-content: space-between">
          <div class="box-son" style="display: flex">
            <label for>联系电话：</label>
            <input
              v-model="formItem.tel"
              class="inputs"
              style="width: 100%; margin-right: 0"
              type="text"
              maxlength="11"
            />
          </div>
          <div class="box-son" style="display: flex">
            <label for>电子邮箱：</label>
            <input
              v-model="formItem.em"
              class="inputs"
              style="width: 100%; margin-right: 0"
              type="text"
            />
          </div>
        </div>

        <div class="select-box">
          <label for>举报内容：</label>
          <input
            class="inputs"
            style="width: 100%; margin-right: 0"
            type="text"
            v-model="formItem.content"
          />
        </div>
        <div class="select-box">
          <label for>上传材料：</label>
          <div>
            <Upload
              multiple
              :action="`${url}/api/other/uploads_img`"
              ref="upload"
              :headers="headers"
              :show-upload-list="false"
              :on-success="handleSuccess"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :before-upload="handleBeforeUpload"
              :on-progress="handleBeProgress"
            >
              <Button>点击上传材料</Button>
              <div
                v-for="(item,index) in uploadNames"
                :key="index"
                style="font-size:12px;position: relative;"
              >
                <span>{{item}}</span>
                <img
                  @click.stop="handleRemove"
                  style="position: absolute;right:10px;top:10px;width:20px;height:20px"
                  src="@/assets/newImg/close.png"
                  alt
                />
              </div>
              <div>
                <Progress :percent="percent" v-if="percent>0&&percent<100" />
                <!-- percent -->
              </div>
            </Upload>
          </div>
        </div>
      </div>

      <div class="select-box">
        <label for>验证码：</label>
        <input class="inputs" v-model="inputCode" style="margin-right: 10px" type="text" />
        <input
          disabled
          :value="codeValue"
          class="inputs"
          style="
            margin-right: 0;
            width: 180px;
            text-align: center;
            letter-spacing: 4px;
          "
          type="text"
        />
      </div>
      <div style="text-align:center;margin:50px 0">
        <Button type="primary" @click="okBtn">提交</Button>
      </div>
    </div>

    <Modal v-model="modal1" title="提示" @on-ok="ok" @on-cancel="cancel">
      <span>确认提交？</span>
    </Modal>
  </div>
</template>

<script>
import { set_add } from "@/api/api";
import { areaJson } from "@/assets/js/area-json.js";
import { mapState } from "vuex";
import md5 from "@/utils/md5";

export default {
  data() {
    return {
      headers: {},
      url: "",
      showImgUrl: "",
      checkedCurrent: 0,
      radioDara: [
        { value: 1, text: "我要咨询" },
        { value: 2, text: "我要投诉" },
        { value: 3, text: "我要举报" }
        // { value: 4, text: "领导信箱" }
      ],
      checkedData: [
        { value: 1, text: "数字化产品" },
        { value: 2, text: "软件开发" },
        { value: 4, text: "物联研发" },
        { value: 5, text: "体系认证" },
        { value: 6, text: "安全生产" },
        { value: 7, text: "产品认证" },
        { value: 8, text: "产品检测" },
        { value: 9, text: "环境监测" },
        { value: 10, text: "公共卫生" },
        { value: 11, text: "职业卫生" },
        { value: 12, text: "安全学院" },
        { value: 13, text: "其他" }
      ],
      area: areaJson(),
      prvIndex: "",
      cityIndex: "",
      prv: {},
      cityCurrent: "",
      city: [],
      quCurrent: "",
      qu: [],
      inputCode: "",
      codeValue: "",
      modal1: false,
      isNameChecked: false,
      formItem: {
        type: 1, //	1	是	string	类型 1我要咨询 2食品投诉 3我要举报 4领导信箱
        pr: "", //四川省	否	string	省份
        city: "", //成都市	否	string	市
        qu: "", //成华区	否	string	区
        addr: "", //双庆路	否	string	详细地址
        names: "", //张先生	是	string	姓名
        a_name: "1", //1	是	string	是否匿名 1不是 2是
        title: "", //标题	是	string	标题
        content: "", //内容	是	string	内容
        tel: "", //15011566143	是	string	电话
        em: "", //邮箱
        t_imgs: [],
        other_name: "", //F	否	string	被投诉人/被举报人
        other_type: "",
        other_type2: []
      },

      imgName: "",
      visible: false,
      uploadList: [],
      percent: 0,
      uploadNames: []
    };
  },
  mounted() {
    this.url = process.env.VUE_APP_BASE_URL;

    this.codeValue = Math.floor(Math.random() * (9999 - 1000)) + 1000;
    // console.log("areaJson(),", areaJson());
  },
  activated() {
    let random = Math.random()
      .toString(36)
      .substr(2);
    let str =
      "app_id=cqskurrqogaoclsumenr&app_key=lDExuywpBznIDhGEzbrolIsJDggaeszE&random=" +
      random;
    let sign = md5.hex_md5(str).toUpperCase();
    this.headers = {
      // "Content-Type": "application/json", //配置请求头
      random: random,
      sign: sign,
      Authorization: localStorage.getItem("accessToken")
    };
  },
  computed: {
    ...mapState(["isLogin"])
  },
  methods: {
    CheckboxChange(e) {
      console.log("e0", e);
    },
    secelectPrv(e) {
      let area = this.area;
      this.formItem.pr = area[this.prvIndex].name;
      this.city = area[this.prvIndex].children;
      // console.log(" this.city", e);
    },
    secelectCity(e) {
      let city = this.city;
      this.formItem.city = city[this.cityIndex].name;
      this.qu = city[this.cityIndex].children;
    },
    secelectQu(e) {
      // console.log("qu", this.formItem);
    },
    okBtn() {
      this.formItem.t_imgs = JSON.stringify(this.uploadList);
      this.formItem.other_type = this.formItem.other_type2.toString();
      console.log("this.formItem", this.formItem);
      if (this.formItem.type == 1) {
        if (this.formItem.names == "") {
          this.$Message.error("请填写姓名");
          return false;
        }
        if (this.formItem.tel == "") {
          this.$Message.error("请填写联系电话");
          return false;
        }
        if (this.formItem.other_type == "") {
          this.$Message.error("请选择咨询类别");
          return false;
        }
        if (this.formItem.content == "") {
          this.$Message.error("请填写咨询内容");
          return false;
        }
      } else if (this.formItem.type == 2) {
        if (this.formItem.title == "") {
          this.$Message.error("请填写投诉标题");
          return false;
        }
        if (this.formItem.names == "") {
          this.$Message.error("请填写投诉人姓名");
          return false;
        }
        if (this.formItem.tel == "") {
          this.$Message.error("请填写投诉人电话");
          return false;
        }
        if (this.formItem.content == "") {
          this.$Message.error("请填写投诉内容");
          return false;
        }
        if (this.formItem.tel == "") {
          this.$Message.error("请填写投诉人电话");
          return false;
        }
        if (
          this.formItem.pr == "" ||
          this.formItem.city == "" ||
          this.formItem.qu == ""
        ) {
          this.$Message.error("请选择事由位置");
          return false;
        }
      } else if (this.formItem.type == 3) {
        if (this.formItem.title == "") {
          this.$Message.error("请填写举报标题");
          return false;
        }
        if (this.formItem.other_name == "") {
          this.$Message.error("请填写被举报人姓名");
          return false;
        }
        if (this.formItem.names == "") {
          this.$Message.error("请填写举报人姓名");
          return false;
        }
        if (this.formItem.tel == "") {
          this.$Message.error("请填写举报人电话");
          return false;
        }
        if (this.formItem.content == "") {
          this.$Message.error("请填写举报内容");
          return false;
        }
      }
      if (this.inputCode != this.codeValue) {
        this.$Message.warning("验证码不正确");
        return false;
      }

      this.modal1 = true;
    },
    //确定提交表单
    ok() {
      let that = this;
      set_add(that.formItem).then(res => {
        // console.log("res", res);
        if (res.code == 200) {
          this.$Message.success(res.message);
        } else {
          this.$Message.error(res.message);
        }
      });
    },

    cancel() {
      //   this.$Message.info("Clicked cancel");
    },

    //选择类型
    radioChange(item, index) {
      this.checkedCurrent = index;
      this.formItem = {
        type: item.value, //	1	是	string	类型 1我要咨询 2食品投诉 3我要举报 4领导信箱
        pr: "", //四川省	否	string	省份
        city: "", //成都市	否	string	市
        qu: "", //成华区	否	string	区
        addr: "", //双庆路	否	string	详细地址
        names: "", //张先生	是	string	姓名
        a_name: "1", //1	是	string	是否匿名 1不是 2是
        title: "", //标题	是	string	标题
        content: "", //内容	是	string	内容
        tel: "", //15011566143	是	string	电话
        em: "", //邮箱
        t_imgs: [],
        other_name: "", //F	否	string	被投诉人/被举报人
        other_type: "",
        other_type2: []
      };
      this.uploadNames = [];
      this.uploadList = [];
    },
    //是否匿名选择
    nameChange() {
      this.isNameChecked = !this.isNameChecked;
      if (this.isNameChecked) {
        this.formItem.a_name = 2;
      } else {
        this.formItem.a_name = 1;
      }
    },

    //预览图片
    handleView(item) {
      this.showImgUrl = item.url;
      this.visible = true;
    },
    //删除图片
    handleRemove(item, index) {
      this.uploadList.splice(index, 1);
      this.uploadNames.splice(index, 1);
    },
    handleSuccess(res, file) {
      this.uploadList.push({ status: "finished", url: file.response.src });
      this.uploadNames.push(file.name);
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "The file format is incorrect",
        desc:
          "File format of " +
          file.name +
          " is incorrect, please select jpg or png."
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "Exceeding file size limit",
        desc: "File  " + file.name + " is too large, no more than 2M."
      });
    },
    handleBeforeUpload() {
      const check = this.uploadList.length < 5;

      if (!this.isLogin) {
        this.$Message.error("请先登录");
        return false;
      }
      if (!check) {
        this.$Notice.warning({
          title: "已达最大上传数"
        });
      }
      return check;
    },
    handleBeProgress(file) {
      this.percent = parseInt(file.percent);
    }
    //以上是上传图片
  }
};
</script>

<style lang="less" scoped>
.formpages {
  width: 100%;
  display: flex;
  justify-content: center;
}
.form-box {
  width: 100%;
}
.radio-box {
  display: flex;
  align-items: center;
  font-family: sanRegular;
  padding: 20px 0;
  font-size: 22px;
  font-weight: 400;
  color: #2b2a2b;
  label {
    width: 130px;
    flex-shrink: 0;
  }
}
.radio {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  border: 1px solid #2b2a2b;
}
.radio-cell {
  display: flex;
  align-items: center;
  margin-right: 80px;
}

.select-box {
  display: flex;
  align-items: center;
  font-family: sanRegular;
  font-size: 22px;
  color: #2b2a2b;
  padding: 30px 0;
  label {
    width: 130px;
    flex-shrink: 0;
  }
}
.select-box2 {
  font-family: sanRegular;
  width: calc(100% - 110px);
  display: flex;
  align-items: center;
}

.select-cell {
  width: 155px;
  margin-right: 20px;
}
.select-cell select {
  outline: none;
  color: #808080;
  font-family: sanRegular;
  width: 100%;
  height: 38px;

  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #2b2a2b;
  font-size: 16px;
  font-weight: 300;
  color: #2b2a2b;
  /* border-radius: 8px; */
}
.inputs {
  outline: none;
  width: 155px;
  padding-left: 10px;
  color: #2b2a2b;
  font-family: sanRegular;
  height: 33px;
  font-size: 24px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #2b2a2b;
  margin-right: 20px;
}
.textareas {
  border-radius: 10px;
}

// 一下是上传图片
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
// 以上是上传图片
</style>
<style scoped>
.radio-box label {
  width: 180px;
}
.ivu-checkbox-wrapper {
  font-size: 22px;
}
</style>
<style>
.ivu-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #2b2a2b !important;
}
.ivu-checkbox-checked .ivu-checkbox-inner:after {
  width: 8px !important;
  height: 14px !important;
  left: 7px !important;
}
</style>