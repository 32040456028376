import request from "../config/request"
// 图片上传
// export function uploads_img(data) {
//     return request({
//         url: "/api/other/uploads_img",
//         method: "post",
//         data
//     })
// }
// 首页我们的优势
export function advantage(type) {
    return request({
        url: "/api/opinion/advantage",
        method: "post",
        data: {
            type
        }
    })
    // type	1	否	string	1 优势 2荣誉证书
}
// 首页我们的优势 详情
export function advantage_det(id) {
    return request({
        url: "/api/opinion/advantage_det",
        method: "post",
        data: {
            id
        }
    })
    // id	8	是	string	id
}
// 首页  频道
export function type_desc(type) {
    return request({
        url: "/api/opinion/type_desc",
        method: "post",
        data: {
            type
        }
    })
    // type	1	否	string	1产品结构 2我们的优势 3主营产品 4集团介绍 5企业动态 6合作伙伴
}
// 首页  内容
export function type_content() {
    return request({
        url: "/api/opinion/type_content",
        method: "post",
    })
}
// 首页  提交需求
export function add_feed(data) {
    return request({
        url: "/api/opinion/add_feed",
        method: "post",
        data
    })
    //     name	张先生	是	string	姓名
    // content	内容	是	string	内容
    // tel	15011566143	是	string	电话
}
// 底部 友情链接
export function links_list(type) {
    return request({
        url: "/api/opinion/links_list",
        method: "post",
        data: {
            type
        }
    })
    // type	1	是	string	1纯文字 2纯图
}
// 头部公告栏
export function note_list(type) {
    return request({
        url: "/api/noticesys/note_list",
        method: "post",
        data: {
            type
        }
    })
    // type	2	是	string	1顶部 2签约
}

//头部轮播
export function banner_list(type, is_index) {
    return request({
        url: "/api/banner/banner_list",
        method: "post",
        data: {
            type, is_index
        }
    })

    // type	1	是	string	1首页 2产品结构 3物联网产品 4企业动态 5发展历程 6平台入口 7投诉举报 8数字产品 9联系我们 10集团简介 11技术服务 12 荣誉证书 13资质证书 14软著证书 15 合作伙伴 16电子手册
}

//数字产品
//头部分类选项   点击分类跳转到相应的列表
export function num_list() {
    return request({
        url: "/api/product/num_list",
        method: "post",
    })
}
//获取列表
export function num_type_list(type) {
    return request({
        url: "/api/product/num_type_list",
        method: "post",
        data: {
            type
        }
    })
}
//列表详情
export function num_type_det(id) {
    return request({
        url: "/api/product/num_type_det",
        method: "post",
        data: {
            id
        }
    })

    // id	2	是	string	列表id
}



//产品结构   新
export function structure_type() {
    return request({
        url: "/api/product/structure_type",
        method: "post",
        // data: { type }
    })
}
export function structure(type) {
    return request({
        url: "/api/product/structure",
        method: "post",
        // data: { type }
    })
}
//产品结构详情
export function structure_det(id) {
    return request({
        url: "/api/product/structure_det",
        method: "post",
        data: { id }
    })
}

//物联产品
//根据头部选项 分类
export function ptype_list(type) {
    return request({
        url: "/api/product/type_list",
        method: "post",
        data: { type }
    })
    // type	1	是	string	类型 1物联网 2专业服务
}
//获取
export function product_first_list(data) {
    return request({
        url: "/api/product/product_first_list",
        method: "post",
        data
    })
}
//获取列表
export function product_list(data) {
    return request({
        url: "/api/product/product_list",
        method: "post",
        data
    });
    //     page	1	否	string	无
    // pz	10	否	string	无
    // search		否	string	搜索关键字
    // type	1	是	string	类型 1物联网 2专业服务
    // t_id		否	string	标签id
}
//获取详情
export function udet(id) {
    return request({
        url: "/api/product/det",
        method: "post",
        data: {
            id
        }
    })
}
// 产品搭配
export function product_pair(id) {
    return request({
        url: "/api/product/product_pair",
        method: "post",
        data: {
            id
        }
    })
}


//专业服务
//分类列表
export function sertype_top() {
    return request({
        url: "/api/giveto/type_top",
        method: "post",
    })
}
//详情分类选项
export function sertwo_type(type_id) {
    return request({
        url: "/api/giveto/two_type",
        method: "post",
        data: {
            type_id
        }
    })
}
//详情列表
export function sergive_list(data) {
    return request({
        url: "/api/giveto/give_list",
        method: "post",
        data
    })
}


//集团简介
//列表
export function ring_list() {
    return request({
        url: "/api/about/ring_list",
        method: "post",
    })
}
//详情
export function ring_det(type) {
    return request({
        url: "/api/about/ring_det",
        method: "post",
        data: {
            type
        }
    })
}


//发展历程
//一级分类
export function lc_type_list() {
    return request({
        url: "/api/care/lc_type_list",
        method: "post",
    })
}
//列表
export function lc_list(data) {
    return request({
        url: "/api/care/lc_list",
        method: "post",
        data
    })
}
//详情
export function lc_det(id) {
    return request({
        url: "/api/care/lc_det",
        method: "post",
        data: {
            id
        }
    })
}



//领导关怀
//分类
export function ctype_list() {
    return request({
        url: "/api/care/type_list",
        method: "post",
    })
}
//列表
export function care_list(data) {
    return request({
        url: "/api/care/care_list",
        method: "post",
        data
    })
}
//详情
export function care_det(id) {
    return request({
        url: "/api/care/care_det",
        method: "post",
        data: {
            id
        }
    })
}



// 企业动态
// 一级分类
export function newType_list() {
    return request({
        url: "/api/news/type_list",
        method: "post",
    })
}
// 二级分类
export function newTwo_type(type_id) {
    return request({
        url: "/api/news/two_type",
        method: "post",
        data: {
            type_id
        }
    })
}
// 列表
export function news_list(data) {
    return request({
        url: "/api/news/news_list",
        method: "post",
        data
    })
}
// 详情
export function news_det(id) {
    return request({
        url: "/api/news/news_det",
        method: "post",
        data: {
            id
        }
    })
}


//投诉举报
//提交
export function set_add(data) {
    return request({
        url: "/api/opinion/set_add",
        method: "post",
        data
    })
}


//联系我们
//列表
export function biz_list(data) {
    return request({
        url: "/api/about/biz_list",
        method: "post",
        data
    })
    //     type	1	否	string	1总部 2运营中心 3分子公司
    // search	西安易尤特数字科技有限公司	否	string	关键字
}
//详情
export function calldet(id) {
    return request({
        url: "/api/about/det",
        method: "post",
        data: {
            id
        }
    })
}





//我的
//获取平台列表
export function get_platform(t_id) {
    return request({
        url: "/api/wxlogin/get_platform",
        method: "post",
        data:{
            t_id
        }
    })
}
//获取平台分类
export function get_platform_type() {
    return request({
        url: "/api/wxlogin/get_platform_type",
        method: "post",
    })
}
//获取平台地址和平台token
export function get_acc_toekn(id) {
    return request({
        url: "/api/wxlogin/get_acc_toekn",
        method: "post",
        data: {
            id
        }
    })
}


//登录
//获取验证码
export function get_code(data) {
    return request({
        url: "/api/wxlogin/get_code",
        method: "post",
        data
    })
}
//登录
//登录接口
export function login(data) {
    return request({
        url: "/api/wxlogin/login",
        method: "post",
        data
    })
    //     phone	19160832579	是	string	手机号
    // type	2	是	string	登录类型 1密码登录 2短信验证码登录
    // msg_code	706128	否	string	当选择类型未短信验证码登录时必填
    // pwd		否	string	当登录类型未密码登录 必填
}
//登录
//注册
export function register(data) {
    return request({
        url: "/api/wxlogin/register",
        method: "post",
        data
    });
    //     phone	19160832579	是	string	无
    // msg_code	1	是	string	短信验证码
    // pwd	123456	是	string	密码
}
//登录
//修改密码
export function up_pwd(data) {
    return request({
        url: "/api/wxlogin/up_pwd",
        method: "post",
        data
    });
    //     phone	19160832579	是	string	手机号
    // msg_code	706128	否	string	当选择类型未短信验证码登录时必填
    // pwd		否	string	新密码
    // pwd_two		是	string	确定新密码
}
//登录
//重置密码
export function reset_pwd(data) {
    return request({
        url: "/api/wxlogin/reset_pwd",
        method: "post",
        data
    });
    //     phone	19160832579	是	string	手机号
    // msg_code	706128	否	string	当选择类型未短信验证码登录时必填
    // pwd		否	string	新密码
    // pwd_two		是	string	确定新密码
}
//登录
//重置密码
export function query_pwd(data) {
    return request({
        url: "/api/wxlogin/query",
        method: "post",
        data
    });
    //     phone	19160832579	是	string	手机号
    // msg_code	706128	否	string	当选择类型未短信验证码登录时必填
    // pwd		否	string	新密码
    // pwd_two		是	string	确定新密码
}


//以下订单相关

//订单
// 支付
export function Pay(data) {
    return request({
        url: "/api/Pay/Pay",
        method: "post",
        data
    });
    //  order_id	277	是	string	订单id
    // is_pc	1	是	string	支付端口 1pc 2wap
    // pay_type	1	是	string	支付类型1支付宝 2微信 3银联 4线下支付
    // pay_img		否	string	线下支付凭证
}
//创建订单
export function myorder_add(data) {
    return request({
        url: "/api/myorder/add",
        method: "post",
        data
    });
    //     buy_str[{“car_id”: 0,”pid”: 6,”num”: 2}, {“car_id”: 0,”pid”: 5,”num”: 1}]	是	string	无
    // addr_id	1	是	string	地址id
    // sale_name		否	string	销售人员
}
//删除订单
export function del_order(order_id) {
    return request({
        url: "/api/Myorder/del_order",
        method: "post",
        data: {
            order_id
        }
    });
    //     buy_str[{“car_id”: 0,”pid”: 6,”num”: 2}, {“car_id”: 0,”pid”: 5,”num”: 1}]	是	string	无
    // addr_id	1	是	string	地址id
    // sale_name		否	string	销售人员
}
//取消订单
export function esc_order(order_id) {
    return request({
        url: "/api/myorder/esc_order",
        method: "post",
        data: {
            order_id
        }
    });
  
}

//加入购物车
export function paycar_add(data) {
    return request({
        url: "/api/paycar/add",
        method: "post",
        data
    });
    //pid	5	是	string	产品id
    // num	2	是	string	数量
}
//购物车列表
export function paycar_list(data) {
    return request({
        url: "/api/paycar/index",
        method: "post",
        // data
    });
}
//删除购物车中 物品
export function del_cart(id) {
    return request({
        url: "/api/paycar/del_cart",
        method: "post",
        data: {
            id
        }
    });
}
//订单创建页  商品信息获取
export function query_order(data) {
    return request({
        url: "/api/myorder/query_order",
        method: "post",
        data
    });
    //buy_str	[{“car_id”:0,”pid”:6,”num”:2},{“car_id”:0,”pid”:5,”num”:1}]	是	string	无
}
//订单创建页  获取推荐人列表
export function sale_list() {
    return request({
        url: "/api/myorder/sale_list",
        method: "post",
    });

}
//  发起支付
export function toPay(data) {
    return request({
        url: "/api/Pay/Pay",
        method: "post",
        data
    });
    //     order_id	270	是	string	订单id
    // is_pc	1	是	string	支付端口 1pc 2wap
    // pay_line	2	是	string	1线下支付 2线上支付
    // pay_type	1	是	string	支付类型1支付宝 2微信 3银联
    // pay_img		否	string	线下支付凭证
}
//  申请退款
export function return_money(data) {
    return request({
        url: "/api/myorder/return_money",
        method: "post",
        data
    });
    // order_id		是	string	订单id
    // retrun_why		是	string	退款原因
}
//  订单详情
export function my_order_det(order_id) {
    return request({
        url: "/api/Myorder/my_order_det",
        method: "post",
        data: {
            order_id
        }

    });

    //  order_id	272	否	string	订单id

}
//  订单列表
export function my_order_list(data) {
    return request({
        url: "/api/Myorder/my_order_list",
        method: "post",
        data

    });

    //type		否	string	状态 1待支付 2代收货 3已完成 4已取消
    // page	1	否	string	无
    // pz	20	否	string	无

}
//  订单详情  订单处理信息
export function order_log_list(data) {
    return request({
        url: "/api/myorder/order_log_list",
        method: "post",
        data

    });

    //type	1	是	string	排序方式 1按照时间升序 2按照时间降徐
    // order_id	272	是	string	订单id

}
//  订单详情  快递信息
export function search_kuaidi(order_id) {
    return request({
        url: "/api/Myorder/search_kuaidi",
        method: "post",
        data: {
            order_id
        }

    });

    //order_id	270	是	string	订单id

}
//以上订单相关



//以下地址相关
//创建新地址
export function Myaddr_add(data) {
    return request({
        url: "/api/Myaddr/add",
        method: "post",
        data
    });
    //name	张先生	是	string	收货人姓名
    // tel	15011566143	是	string	电话
    // province	四川	是	string	省
    // city	成都	是	string	市
    // area	成华	是	string	区
    // address	双桥路	是	string	详细地址
    // state	2	是	string	1默认地址 2不是默认地址
}
//修改地址
export function edit(data) {
    return request({
        url: "/api/Myaddr/edit",
        method: "post",
        data
    });
    //name	张先生	是	string	收货人姓名
    // tel	15011566143	是	string	电话
    // province	四川	是	string	省
    // city	成都	是	string	市
    // area	成华	是	string	区
    // address	双桥路	是	string	详细地址
    // state	2	是	string	1默认地址 2不是默认地址
    // id	1	是	string	无
}
//地址列表
export function Myaddr_index(data) {
    return request({
        url: "/api/Myaddr/index",
        method: "post",
        data
    });
    //page	1	否	string	无
    // pz	20	是	string	每页显示条数
}

// 删除地址
export function del_addr(id) {
    return request({
        url: "/api/Myaddr/del_addr",
        method: "post",
        data: { id }
    });

}
// 地址详情
export function addr_det(id) {
    return request({
        url: "/api/Myaddr/det",
        method: "post",
        data: { id }
    });

}
//以上地址相关

// 获取个人信息
export function get_user() {
    return request({
        url: "/api/member/get_user",
        method: "post",
    });

}



