<template>
  <!-- 产品结构 -->
  <div class="productStructurepages">
    <div class="header-img">
      <img v-if="banner_list.length > 0" :src="banner_list.length > 0 ? banner_list[0].ads_pic : ''" alt />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="container" :style="(index - 1) % 2 != 0 ? '' : 'background:#F9F9F9'"
      v-for="(item, index) in structureData" :key="index">
      <div class="container-title">
        <img :src="item.imgs" style="width:58px;margin-right:10px" alt />
        <span>{{ item.name }}</span>
      </div>

      <div class="container-box">
        <div class="container-model" v-for="(itemSon, indexSon) in item.all" :key="indexSon" @click="toDetail(itemSon)"
          @mouseenter="mouseenterM(index, indexSon)" @mouseleave="mouseleaveM(index, indexSon)"
          :style="{ background: cu1 === index && cu2 === indexSon ? '#577df8' : '' }">
          <div class="container-model-imgbox">
            <img v-if="cu1 === index && cu2 === indexSon" :src="itemSon.move_img" alt />
            <img v-else :src="itemSon.img" alt />
          </div>
          <!-- <div class="container-model-text1">
            <span>“智安云盾”</span>
          </div>-->
          <div class="container-model-text1" :style="{ color: cu1 === index && cu2 === indexSon ? '#ffffff' : '' }">
            <div v-html="itemSon.desc"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container" v-html="content"></div> -->
  </div>
</template>

<script>
import { structure, banner_list, structure_type } from "@/api/api.js";

export default {
  components: {},
  data() {
    return {
      gundong: false,
      content: "",
      isTransition: false,
      height: 70,
      defaultWidth: 100,
      defaultTop: 0,
      time: "",
      listData: [],
      marginLeft: 0,
      current: 2,
      banner_list: [],
      structureData: [],
      structureData2: [],
      cu1: "",
      cu2: ""
    };
  },

  mounted() {
    this.getbanner_list();
    this.getstructure_type();
  },
  destroyed() { },
  computed: {},
  methods: {
    mouseenterM(index1, index2) {
      this.cu1 = index1;
      this.cu2 = index2;
    },
    mouseleaveM(index1, index2) {
      this.cu1 = "";
      this.cu2 = "";
    },
    //产品结构
    getstructure() {
      structure().then(res => {
        this.structureData1 = res.data.anquan.list;
        this.structureData2 = res.data.zhili.list;
      });
    },
    getstructure_type() {
      structure_type().then(res => {
        this.structureData = res.data.list;
      });
    },
    getbanner_list() {
      banner_list(2).then(res => {
        this.banner_list = res.data;
      });
    },
    toDetail(item) {
      if (item.content) {
        let routeUrl = this.$router.resolve({
          path: "/structureDetail",
          query: { id: item.id }
        });
        window.open(routeUrl.href, '_blank');
        // this.$router.push({ path: "/structureDetail", query: { id: item.id } });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.gundong {
  background: #ffffff;
  transition: all 0.5s;
}

.productStructurepages {
  width: 100%;
  /* display: flex;
  justify-content: center; */
}

.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 9999;
}

.tabChecked {
  border-bottom: 1px solid #ffffff;
  color: #2f4373;
}

.header-bar {
  /* background: #ffffff; */
  /* box-shadow: 0 0 5px #adadad; */
  overflow: hidden;
  color: #494949;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-not-box-right {
  width: 70%;
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-bar-span {
  display: flex;
  align-items: center;
  font-family: sanNormal;
  color: #ffffff;
  font-size: 1.21rem;
}

.header-bar-span:hover {
  cursor: pointer;
}

.goin {
  display: inline-block;
  text-align: center;

  color: #ffffff;
  transition: all 0.5s;

  width: 152px;
  height: 55px;
  display: flex;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 5px;
}

.header-img {
  /* background: #1c4492; */
  width: 100%;

  /* height: 600px; */
  img {
    width: 100%;
    display: block;
    min-height: 200px;
  }

  /* border-bottom-left-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%; */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 140px 0 80px 0;
}

.container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  font-family: sanNormal;
  font-weight: bold;
  color: #051c35;
  margin-bottom: 87px;
}

.container-box {
  width: 59%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container-model {
  margin: 0 1%;
  /* width: 254px; */
  width: 23%;
  height: 314px;
  background: #ffffff;
  border: 2px solid #f7f7f7;
  text-align: center;
  transition: all 0.5s;
  margin-bottom: 2%;
}

.container-model:hover {
  cursor: pointer;
}

.container-model-imgbox {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-model-imgbox img {
  width: 90px;
  // height: 67px;
}

.container-model-text1 {
  padding: 0 30px;
  font-size: 20px;
  font-family: sanNormal;
  font-weight: bold;
  color: #051c35;
}
</style>