<template>
  <div>
    <div class="bannerbox" v-for="(item,index) in listData" :key="index">
      <div class style="width: 80%;display: flex;justify-content: space-between;">
        <div class="banner-title">
          <span>{{item.title}}</span>
          <span>{{item.titlei}}</span>
        </div>
        <div class="list-box" :class="isHorizontal?'horizontal':''">
          <div class="list-box-text" :style="isHorizontal?'width:44%;':''">
            <span v-for="(items,indexs) in item.text" :key="indexs">{{items}}</span>
          </div>
          <div class="list-box-imgbox" :style="isHorizontal?'margin-top:0;width:52%':''">
            <template v-if="isHorizontal">
              <Map :location="{lng:item.lng,lat:item.lat}" class="setmapbox"></Map>
            </template>
            <template v-else>
              <div
                class="list-box-imgbox-son"
                :style="item.img.length>1?'width:49%':'width:49%;'"
                v-for="(itemd,indexd) in item.img"
                :key="indexd"
              >
                <img :src="itemd" alt />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "@/components/Map/Map.vue";
export default {
  components: {
    Map
  },
  props: {
    listData: {
      type: Array,
      default() {
        return [];
      }
    },
    isHorizontal: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
    };
  },
  created() {},
  mounted() {
    // console.log("listData", this.listData);
  },
  methods: {
   
  }
};
</script>
<style scoped>
.bannerbox {
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 40px 0;
}
.banner-title {
  display: flex;
  flex-direction: column;
}
.banner-title span:nth-child(1) {
  font-size: 17px;
  color: #5f5f5f;
  margin-bottom: 2px;
  font-family: sanMedium;
}
.banner-title span:nth-child(2) {
  font-size: 12px;
  color: #838383;
}

.img-centerText > div {
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
}
.img-centerText {
  color: #ffffff;
  width: 400px;
  position: absolute;
  left: calc(50% - 200px);
  top: 40px;
}
.list-box {
  width: 770px;
}
.horizontal {
  display: flex;
  justify-content: space-between;
}
.list-box-text {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #969696;
  font-family: sanRegular;
}
.list-box-text span {
  margin-bottom: 8px;
}
.list-box-imgbox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.list-box-imgbox-son {
  border-radius: 10px;
  box-shadow: 0 0 5px #5f5f5f;
}
.imgWidth {
  width: 100% !important;
}
.list-box-imgbox-son img {
  width: 100%;
  display: block;
  border-radius: 10px;
}
.setmapbox{
  border-radius: 10px;
  box-shadow: 0 0 5px #cacaca;
}
</style>