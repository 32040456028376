<template>
  <div class="paypagse">
    <div style="height:70px"></div>
    <div class="pay-header">
      <img src="@/assets/loginlog.png" alt />
      <span>收银台</span>
    </div>
    <div class="texts">
      <img src="@/assets/newImg/dui.jpg" alt />
      <div class="tishi">
        <div class="top">
          <div class="top-left">
            <span>订单提交成功，只差付款了~</span>
            <span>订单号：</span>
            <span>{{ order_detData.order_code }}</span>
          </div>
          <div class="top-right">
            <span>订单金额：</span>
            <span>￥{{ order_detData.order_price }}</span>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-left">
            <span>请您在</span>
            <span>{{ downTime }}</span>
            <span>内完成支付，否则订单将自动取消。</span>
          </div>
          <div class="bottom-right" @click="findMore">
            <span>订单详情</span>
            <img :src="require(`@/assets/newImg/${isShow ? 'top' : 'bottom'}.png`)" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="lookmore" v-if="isShow">
      <div class="cell">
        <span>订单号：</span>
        <span style="color:red">{{ order_detData.order_code }}</span>
      </div>
      <div class="cell">
        <span>收货信息：</span>
        <span>{{ order_detData.order_add_name }}-</span>
        <span>{{ order_detData.order_add_tel }}</span>
      </div>
      <div class="cell">
        <span>商品名称：</span>
        <span>{{ productName }}</span>
      </div>
      <div class="cell">
        <span>购买时间：</span>
        <span>{{ order_detData.order_time }}</span>
      </div>
    </div>
    <div class="pay-type">
      <div class="tabs">
        <div class="model" :class="tabCurrent == 0 ? 'checkedTabs' : ''" @click="tabsChange(0)">线上支付</div>
        <div class="model" :class="tabCurrent == 1 ? 'checkedTabs' : ''" @click="tabsChange(1)">线下支付</div>
      </div>
      <div class="content" v-if="tabCurrent == 0">
        <div class="cell" :style="{ border: payTypeCurrent == 0 ? '1px solid #DA3232' : '' }" @click="changePayType(0)">
          <img class="log" src="@/assets/newImg/zfblog.jpg" alt />
          <img class="icon" v-if="payTypeCurrent == 0" src="@/assets/newImg/xuanzhong.jpg" alt />
        </div>
        <div class="cell" :style="{ border: payTypeCurrent == 1 ? '1px solid #DA3232' : '' }" @click="changePayType(1)">
          <img class="log" src="@/assets/newImg/wxlog.jpg" alt />
          <img class="icon" v-if="payTypeCurrent == 1" src="@/assets/newImg/xuanzhong.jpg" alt />
        </div>
        <!-- <div
          class="cell"
          :style="{border:payTypeCurrent==2?'1px solid #DA3232':''}"
          @click="changePayType(2)"
        >
          <img class="log" src="@/assets/newImg/yllog.jpg" alt />
          <img class="icon" v-if="payTypeCurrent==2" src="@/assets/newImg/xuanzhong.jpg" alt />
        </div>-->
      </div>
      <div class="content" v-if="tabCurrent == 1">
        <div>
          <Upload :action="`${url}/api/other/uploads_img`" :headers="headers" :show-upload-list="false"
            :on-success="handleSuccess">
            <span class="spans">点击上传支付凭证(图片) +</span>
          </Upload>
          <div v-for="(item, index) in uploadNames" :key="index">
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <button class="cancl">取消</button>
      <button class="ok" @click="toPay">确认支付</button>
    </div>

    <Modal width="600" :footer-hide="true" v-model="showModal" :title="modelTitle" @on-visible-change="statusChange">
      <div class="paymodel">
        <div class="left">
          <div class="qrcode" ref="qrCodeUrl"></div>

          <img v-if="modelTitle == '支付宝支付'" :src="require(`@/assets/sz.jpg`)" alt />
          <img v-else-if="modelTitle == '微信支付'" :src="require(`@/assets/sw.jpg`)" alt />
        </div>
        <div class="right">
          <img v-if="modelTitle == '支付宝支付'" :src="require(`@/assets/zfb.jpg`)" alt />
          <img v-else-if="modelTitle == '微信支付'" :src="require(`@/assets/wx.jpg`)" alt />
        </div>
      </div>
      <div class="paymodel-bottom">
        <button @click="closeModel">更换支付方式</button>
        <button @click="okPay">已完成支付</button>
      </div>
    </Modal>
    <Modal width="350" :footer-hide="true" v-model="showModalPayd" :title="'完成支付'">
      <div class="okPay">
        <div class="title">
          <span>支付成功！</span>
          <span>是否前往订单中心查看订单详情?</span>
        </div>
        <div class="okbtn">
          <button @click="coloseMode">取消</button>
          <button @click="toOrderDetail">确定</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { my_order_det, Pay } from "@/api/api";
import QRCode from "qrcodejs2";
import md5 from "@/utils/md5";
export default {
  data() {
    return {
      headers: {},
      url: "",
      order_id: 0,
      isShow: false,
      tabCurrent: 0,
      payTypeCurrent: 0,
      showModal: false,
      showModalPayd: false,
      modelTitle: "支付宝支付",
      order_detData: {},
      productName: "",
      payQuery: {
        order_id: "", //	277	是	string	订单id
        is_pc: "1", //	1	是	string	支付端口 1pc 2wap
        pay_type: "", //	1	是	string	支付类型1支付宝 2微信 3银联 4线下支付
        pay_img: "" //		否	string	线下支付凭证
      },
      urlImg: "",
      uploadList: [],
      uploadNames: [],
      downTime: "",
      endTime:"",
    };
  },
  mounted() {
    this.url = process.env.VUE_APP_BASE_URL;
    let random = Math.random()
      .toString(36)
      .substr(2);
    let str =
      "app_id=cqskurrqogaoclsumenr&app_key=lDExuywpBznIDhGEzbrolIsJDggaeszE&random=" +
      random;
    let sign = md5.hex_md5(str).toUpperCase();
    this.headers = {
      // "Content-Type": "application/json", //配置请求头
      random: random,
      sign: sign,
      Authorization: localStorage.getItem("accessToken")
    };
  },
  activated() {
    this.order_id = this.$route.query.order_id;
    this.payQuery.order_id = this.$route.query.order_id;
    this.getmy_order_det();
   
  },
  methods: {
    getmy_order_det() {
      let that = this;
      my_order_det(that.order_id).then(res => {
        // console.log(res);
        if (res.code == 200) {
          that.order_detData = res.data;
          that.endTime=res.data.end_time
          that.countdown();
          if (res.data.order_state > 1) {
            this.showModalPayd = true;
          }
          let orderInfo = res.data.son_order;
          let productName = "";
          for (let i = 0; i < orderInfo.length; i++) {
            productName += `${orderInfo[i].title}*${orderInfo[i].order_num} + `;
          }
          that.productName = productName.substr(0, productName.length - 3);
        }
      });
    },
    //查看详情
    findMore() {
      this.isShow = !this.isShow;
    },
    //改变支付方式
    tabsChange(index) {
      this.tabCurrent = index;
      if (index == 0) {
        this.payQuery.pay_img = "";
      } else {
        this.payQuery.pay_img = "";
      }
    },
    //改变支付方式
    changePayType(index) {
      this.payTypeCurrent = index;
    },
    //确认支付
    toPay() {
      let that = this;
      if (that.tabCurrent == 0) {
        //线上支付
        if (that.payTypeCurrent == 0) {
          that.modelTitle = "支付宝支付";
          that.payQuery.pay_type = 1;
          // that.Pay();
          // that.$router.params({name:'/zfbPay',params:{id:0}})
          let { href } = that.$router.resolve({
            name: "zfbPay",
            query: that.payQuery
          });
          window.open(href, "_blank");
          that.showModal = true;
        } else if (that.payTypeCurrent == 1) {
          that.modelTitle = "微信支付";
          that.payQuery.pay_type = 2;
          that.Pay();
        } else if (that.payTypeCurrent == 2) {
          //银联支付
        }
      } else {
        //线下支付
        //   uploadList: [],
        // uploadNames: []
        let uploadList = that.uploadList
        let src = [];
        for (let i = 0; i < uploadList.length; i++) {
          src.push(uploadList[i].url)
        }
        that.payQuery.pay_img = JSON.stringify(src);
        that.payQuery.pay_type = 4;  //支付方式改变为线下支付
        if (that.payQuery.pay_img.length < 1) {
          that.$Message.error("请上传支付凭证");
          return false;
        }
        that.Pay();
      }
    },
    Pay() {
      let that = this;
      Pay(that.payQuery).then(res => {
        // console.log(res);
        if (res.code == 200) {
          // this.vhtmls = ;
          if (that.payQuery.pay_type == 1) {
            let div = document.createElement("div");
            // console.log(div);
            div.innerHTML = res.data.pay_url.url; //此处form就是后台返回接收到的数据
            document.body.appendChild(div);
            // console.log(document.forms);
            setTimeout(() => {
              document.forms["alipaysubmit"].submit();
            }, 500);
          } else if (that.payQuery.pay_type == 2) {
            that.creatQrCode(res.data.pay_url.url);
            that.showModal = true;
          } else if (that.payQuery.pay_type == 4) {
            that.$Message.success(res.msg);
          }
        }
      });
    },
    //支付弹窗关闭时
    statusChange(e) {

      if (!e) {
        const cleardom = document.querySelectorAll('.qrcode img')
        const cleardom2 = document.querySelectorAll('.qrcode canvas')
        Array.from(cleardom).forEach((item) => {
          item.remove()
        })
        Array.from(cleardom2).forEach((item) => {
          item.remove()
        })
      }
    },
    //制作微信二维码
    creatQrCode(str) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: str, // 需要转换为二维码的内容
        width: 140,
        height: 140,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    closeModel() {
      this.showModal = false;
    },
    okPay() {
      this.showModal = false;
      this.getmy_order_det();
    },
    coloseMode() {
      this.showModalPayd = false;
    },
    toOrderDetail() {
      this.showModalPayd = false;
      this.$router.push({ path: "/myOrder" });
    },
    // 上传图片
    handleUpload(file) {
      this.file = file;
      return false;
    },
    handleSuccess(res, file) {
      if (res.code == 200) {
        this.$Message.success(res.msg);
        this.uploadList.push({ status: "finished", url: file.response.src });
        this.uploadNames.push(file.name);
      } else {
        this.$Message.error(res.msg);
      }
    },
    upload() {
      this.loadingStatus = true;
      setTimeout(() => {
        this.file = null;
        this.loadingStatus = false;
        this.$Message.success("Success");
      }, 1500);
    },
    countdown() {
      const that = this
                              // '2023-01-04 22:14:00'
      const end = Date.parse(new Date(that.endTime))
      const now = Date.parse(new Date())
      const msec = end - now

      if (now > end) {
        // console.log("da")
        that.downTime = 0;
        return false;
      }
      // console.log(msec)
      if (msec < 0) return;

      let day = parseInt(msec / 1000 / 60 / 60 / 24)
      let hr = parseInt(msec / 1000 / 60 / 60 % 24)
      let min = parseInt(msec / 1000 / 60 % 60)
      let sec = parseInt(msec / 1000 % 60)
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec



      if (min >= 0 && sec >= 0) {
        // console.log("min", min)
        // console.log("sec", sec)
        that.downTime = `${min}分${sec}秒`
        //倒计时结束关闭订单
        if (min == 0 && sec == 0) {

          return false;
        }
        setTimeout(function () {
          that.countdown()
        }, 1000)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.paypagse {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
}

.pay-header {
  width: 55%;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #63aa56;
  padding: 5px 10px;

  img {
    width: 90px;
  }

  span {
    display: inline-block;
    padding: 0 25px;
    border-left: 1px solid #cccccc;
    margin-left: 50px;
    font-size: 17px;
    font-weight: 400;
    color: #3a3a3a;
  }
}

.texts {
  width: 55%;
  display: flex;
  padding: 30px 20px 0 20px;

  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  .tishi {
    padding-left: 20px;
    width: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-left {
        span:nth-child(1) {
          font-size: 18px;
          font-weight: 400;
          color: #63aa56;
        }

        span:nth-child(2) {
          font-size: 12px;
          font-weight: 400;
          color: #3a3a3a;
          margin: 0 12px 0 17px;
        }

        span:nth-child(3) {
          font-size: 12px;
          font-weight: 400;
          color: #da3232;
        }
      }

      .top-right {
        span:nth-child(1) {
          font-size: 12px;
          font-weight: 400;
          color: #000000;
        }

        span:nth-child(2) {
          font-size: 23px;
          font-weight: bold;
          color: #da3232;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bottom-left {
        span:nth-child(1) {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3a3a3a;
        }

        span:nth-child(2) {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: red;
        }

        span:nth-child(3) {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3a3a3a;
        }
      }

      .bottom-right {
        display: flex;
        align-items: center;

        span {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          margin-right: 10px;
        }

        img {
          width: 9px;
          height: 7px;
        }
      }
    }
  }
}

.lookmore {
  margin-top: 10px;
  width: 55%;
  padding: 0 105px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #3a3a3a;
}

.pay-type {
  margin-top: 70px;
  width: 55%;
  z-index: 1;

  .tabs {
    display: flex;

    .model {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
      height: 41px;
      background: #f2f2f2;
      border: 1px solid #cccccc;
    }

    .model:nth-child(1) {
      border-right: none;
    }

    .checkedTabs {
      border-bottom: 1px solid #ffffff;
      background: #ffffff;
    }
  }
}

.content {
  margin-top: -1px;
  height: 175px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;

  .spans {
    text-decoration: underline;
  }

  .spans:hover {
    cursor: pointer;
  }

  .uploadImg {
    width: 50px;
    height: 50px;
  }

  .cell:nth-child(2) {
    margin: 0 10px;
  }

  .cell {
    position: relative;
    width: 258px;
    height: 52px;
    background: #ffffff;
    border: 1px solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;

    .log {
      width: 130px;
      height: 35px;
      display: block;
    }

    .icon {
      position: absolute;
      bottom: -1px;
      right: -1px;
      width: 30px;
      height: 30px;
    }
  }
}

.btns {
  margin-top: 30px;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .cancl {
    width: 169px;
    height: 41px;
    background: #ffffff;
    border: 1px solid #cccccc;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3a3a3a;
  }

  .cancl,
  .ok:hover {
    cursor: pointer;
  }

  .ok {
    width: 168px;
    height: 40px;
    background: #1c4492;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    border: none;
    margin-left: 47px;
  }
}

.paymodel {
  display: flex;
  justify-content: space-evenly;

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img:nth-child(1) {
      width: 135px;
      height: 135px;
      background: #50b6e4;
    }

    img:nth-child(2) {
      margin-top: 10px;
      width: 150px;
      height: 36px;
    }
  }

  .right {
    img {
      width: 220px;
    }
  }
}

.paymodel-bottom {
  border-top: 1px solid #cccccc;
  width: 100%;
  padding: 33px 0 20px 0;
  display: flex;
  justify-content: center;

  button {
    width: 168px;
    height: 40px;
  }

  button:nth-child(1) {
    background: #ffffff;
    border: 1px solid #cccccc;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3a3a3a;
    margin-right: 17px;
  }

  button:nth-child(2) {
    background: #1c4492;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    border: none;
  }
}

.okPay {
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-size: 18px;
    color: #000000;
    font-weight: 400;

    span {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .okbtn {
    display: flex;
    justify-content: space-between;

    button:nth-child(1) {
      width: 48%;
      height: 40px;
      background: #f2f2f2;
      border: none;
      outline: none;
    }

    button:nth-child(2) {
      color: #ffffff;
      width: 48%;
      height: 40px;
      background: #1c4492;
      border: none;
      outline: none;
    }

    button:hover {
      cursor: pointer;
    }
  }
}
</style>