import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css' //重置浏览器默认样式
import './assets/css/index.css' //全局引入字体
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Carousel3d from 'vue-carousel-3d';  //引入三D相册


// import BaiduMap from 'vue-baidu-map'

// import axios from 'axios'
// Vue.prototype.axios = axios;
// axios.defaults.baseURL='http://eut.codyapp.cn/'

// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'm1BK8vNeXOP8LvTOwZ82pQMi93QblSqS'
// })
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {

  let accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    store.state.isLogin = true;

    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    // console.log("userInfo", userInfo)
    if (userInfo) {
      store.state.userInfo = userInfo
    }

  } else {
    store.state.isLogin = false;
  }
  if (to.path == "/mine") {
    if (accessToken) {

      next()
    } else {

      next("/login")
    }
  } else {

    next()
  }

})
Vue.use(Carousel3d);
Vue.use(ViewUI);
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')