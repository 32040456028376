<template>
  <div class="addAddressPage">
    <div class style="height:70px"></div>
    <div class="container">
      <div class="allTitle">
        <span>收货地址管理</span>
      </div>
      <div class="addAddress">
        <div style="display:flex;width:100%">
          <div class="cell">
            <span class="label">收货人：</span>
            <input
              class="input"
              type="text"
              v-model="addQuery.name"
              style="width:250px"
              placeholder="输入收货人姓名"
            />
          </div>
          <div class="cell">
            <span class="label">手机号：</span>
            <input
              class="input"
              type="text"
              v-model="addQuery.tel"
              placeholder="请输入11位手机号"
              style="width:250px"
            />
          </div>
        </div>

        <div class="cell">
          <span class="label">收货地址：</span>
          <el-cascader
           
            size="medium"
            :options="options"
            v-model="selectedOptions"
            @change="handleChange"
          ></el-cascader>
        </div>
        <div class="cell">
          <span class="label"></span>
          <textarea
            name
            id
            style="width:580px"
            v-model="addQuery.address"
            rows="5"
            placeholder="填写详细地址"
          ></textarea>
        </div>
        <!-- <div class="cell">
          <span class="label">邮编：</span>
          <input class="input" type="text" />
        </div>-->
        <div class="cell">
          <span class="label"></span>
          <div>
            <Checkbox size="small" :trueValue="1" :falseValue="2" v-model="addQuery.state"></Checkbox>
            <span class="moren">设为默认</span>
          </div>
        </div>
        <div class="cell">
          <span class="label"></span>
          <div>
            <button class="btn1" @click="editAndAdd">{{addQuery.id==0?'添加新地址':'修改地址'}}</button>
            <button class="btn2" @click="clearFrom">清空</button>
          </div>
        </div>
      </div>
      <div class="myAddressList">
        <div class="cell1">
          <div class="t1">收货人</div>
          <div class="t2">收货地址</div>
          <div class="t3">邮编</div>
          <div class="t4">电话/手机</div>
          <div class="t5">操作</div>
        </div>
        <div class="cell" v-for="(item,index) in addressList" :key="index">
          <div class="t1">{{item.name}}</div>
          <div class="t2">
            {{item.province}}
            {{item.city}}
            {{item.area}}
            {{item.address}}
          </div>
          <div class="t3"></div>
          <div class="t4">{{item.tel}}</div>
          <div class="t5">
            <span class="btns" @click="toedit(item)">编辑</span>
            <Poptip confirm title="确定删除该地址?" @on-ok="ok(item)" @on-cancel="cancel">
              <span class="btns">删除</span>
            </Poptip>
            <span class="mo" v-if="item.state==1">默认</span>

            <Poptip
              v-if="item.state==2"
              confirm
              title="确定设为默认地址?"
              @on-ok="oks(item)"
              @on-cancel="cancels"
            >
              <span class="mo">设为默认</span>
            </Poptip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Myaddr_index, Myaddr_add, edit, del_addr } from "@/api/api";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  data() {
    return {
      addressList: [],
      options: regionData,
      selectedOptions: [],
      pages: {
        page: 1,
        pz: 20
      },
      count: 0,
      addQuery: {
        id: 0,
        name: "", //	张先生	是	string	收货人姓名
        tel: "", //	15011566143	是	string	电话
        province: "", //	四川	是	string	省
        city: "", //	成都	是	string	市
        area: "", //	成华	是	string	区
        address: "", //	双桥路	是	string	详细地址
        state: 1 //	2	是	string	1默认地址 2不是默认地址
      }
    };
  },
  mounted() {},
  activated() {
    // this.id = this.$route.query.id;
    // this.id = 4;
    this.getAddressList();
  },
  methods: {
    clearFrom() {
      this.addQuery = {
        id: 0,
        name: "", //	张先生	是	string	收货人姓名
        tel: "", //	15011566143	是	string	电话
        province: "", //	四川	是	string	省
        city: "", //	成都	是	string	市
        area: "", //	成华	是	string	区
        address: "", //	双桥路	是	string	详细地址
        state: 1 //	2	是	string	1默认地址 2不是默认地址
      };
      this.selectedOptions = [];
    },
    editAndAdd() {
      let addQuery=this.addQuery;
      if(addQuery.name==""){
        this.$Message.error("请填写收货人姓名");
        return false;
      }
      if(addQuery.tel==""){
        this.$Message.error("请填写手机号");
        return false;
      }
      if(addQuery.tel.length!=11){
        this.$Message.error("手机号位数不正确");
        return false;
      }
      if(addQuery.province==""){
        this.$Message.error("请选择收获地址");
        return false;
      }
      if(addQuery.address==""){
        this.$Message.error("请填写详细地址");
        return false;
      }
      if (this.addQuery.id > 0) {
        this.postedit();
      } else {
        this.addr_add();
      }
    },
    addr_add() {
      let that = this;
      Myaddr_add(that.addQuery)
        .then(res => {
          if (res.code == 200) {
            that.$Message.success(res.message);
          } else {
            that.$Message.error(res.message);
          }
          that.getAddressList();
        })
        .catch(err => {
          that.$Message.error(err);
        });
      this.clearFrom();
    },
    toedit(item) {
      this.addQuery = item;
      let arr = [];
      let province = TextToCode[item.province].code;
      let city = TextToCode[item.province][item.city].code;
      let area = TextToCode[item.province][item.city][item.area].code;
      arr.push(province);
      arr.push(city);
      arr.push(area);
      this.selectedOptions = arr;
    },
    postedit() {
      let that = this;
      edit(that.addQuery)
        .then(res => {
          if (res.code == 200) {
            that.$Message.success(res.message);
          } else {
            that.$Message.error(res.message);
          }
          that.getAddressList();
          that.clearFrom();
        })
        .catch(err => {
          that.$Message.error(err);
        });
    },
    getAddressList() {
      let that = this;
      Myaddr_index(that.pages)
        .then(res => {
          that.addressList = res.data.list;
          that.count = res.data.count;
        })
        .catch(err => {});
    },
    changeAll(e) {},
    cancels() {},
    //确认编辑
    oks(item) {
      let that = this;
      item.state = 1;
      edit(item)
        .then(res => {
          if (res.code == 200) {
            that.$Message.success(res.message);
          } else {
            that.$Message.error(res.message);
          }
          that.getAddressList();
        })
        .catch(err => {
          that.$Message.error(err);
        });
    },
    //确认删除
    ok(item) {
      let that = this;
      del_addr(item.id).then(res => {
        if (res.code == 200) {
          that.$Message.success(res.message);
        } else {
          that.$Message.error(res.message);
        }
        that.getAddressList();
      });
    },
    cancel() {},
    handleChange(value) {
      this.addQuery.province = CodeToText[value[0]];
      this.addQuery.city = CodeToText[value[1]];
      this.addQuery.area = CodeToText[value[2]];
    }
  }
};
</script>

<style lang="less" scoped>
.addAddressPage {
  padding-bottom: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .container {
    width: 60%;
    .allTitle {
      padding: 17px 0;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #282828;
      border-bottom: 1px solid #dedede;
    }
    .addAddress {
      .cell {
        display: flex;
        padding: 10px 0;
        .input {
          height: 35px;
          outline: none;
          padding-left: 10px;
        }
        .label {
          display: inline-block;
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3a3a3a;
          flex-shrink: 0;
        }
        .moren {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3a3a3a;
        }
        textarea {
          padding: 10px;
        }
        .btn1 {
          width: 156px;
          height: 40px;
          background: #1c4492;
          border-radius: 2px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          border: none;
          margin-right: 19px;
        }
        .btn2 {
          width: 156px;
          height: 40px;
          border: 1px solid #bbbbbb;
          border-radius: 2px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          border: none;
        }
        .btn1,
        .btn2:hover {
          cursor: pointer;
        }
      }
    }
    .myAddressList {
      margin-top: 30px;
      .cell1 {
        height: 40px;
        background: #fafafa;
        display: flex;
        align-items: center;
        div {
          text-align: center;
          height: 14px;
          border-right: 1px solid #dedede;
        }
        div:last-child {
          border: none;
        }
        .t1 {
          width: 15%;
        }
        .t2 {
          width: 30%;
        }
        .t3 {
          width: 15%;
        }
        .t4 {
          width: 15%;
        }
        .t5 {
          width: 25%;
        }
      }
      .cell {
        background: #ffffff;
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #dedede;
        div {
          text-align: center;
        }
        .t1 {
          width: 15%;
        }
        .t2 {
          width: 30%;
        }
        .t3 {
          width: 15%;
        }
        .t4 {
          width: 15%;
        }
        .t5 {
          width: 25%;
          span:hover {
            cursor: pointer;
          }
          span {
            display: inline-block;
            padding: 0 15px;
          }
          .btns {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
          }
          .btns:nth-child(2) {
            border-left: 1px solid #000000;
            border-right: 1px solid #000000;
          }
          .mo {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ca141d;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.ivu-input-wrapper {
  border: 1px solid #6e6e6e;
}
.el-cascader .el-input .el-input__inner {
  border: 1px solid #6e6e6e !important;
}
.el-input__inner {
  border: 1px solid #6e6e6e !important;
}
</style>
