<template>
  <div class="mainpageboxs">
    <!-- <Header :startWidth="80" :startTop="50" :currentRouter="'/aboutus'"></Header> -->
    <div class="header-bimg">
      <img
        v-if="banner_list.length > 0"
        :src="banner_list.length > 0 ? banner_list[0].ads_pic : ''"
        alt
      />
      <img v-else :src="require('@/assets/newImg/lian.jpg')" alt />
    </div>
    <div class="tabs">
      <div class="tabs-box">
        <span
          class="tabs-span"
          @click="changeTabs(index)"
          :style="
            tabsCurrent == index
              ? { borderBottom: '2px solid #3255FA', color: '#3255FA' }
              : {}
          "
          v-for="(item, index) in tabsData"
          :key="index"
          >{{ item.text }}</span
        >
      </div>
    </div>
    <div style="margin: 30px 0" class="computer-box">
      <Carousel
        trigger="hover"
        easing
        loop
        class="Carousels"
        @on-change="videoChange"
      >
        <CarouselItem
          class="CarouselsItem"
          v-for="(item, index) in ring_detData1.all_img"
          :key="index"
        >
          <video
            :id="`video${index}`"
            style="width: 490px; height: 310px; background: #000000"
            :src="item"
            controls
          ></video>
          <img
            class="plays"
            :id="`plays${index}`"
            src="@/assets/newImg/play.png"
            @click="playVideos(index)"
            alt
          />
        </CarouselItem>
      </Carousel>
      <img class="computer" src="@/assets/newImg/computer.png" alt />
    </div>
    <div
      class
      v-html="ring_detData1.content"
      style="width: 55%; z-index: 1"
    ></div>
    <div>
      <img
        :src="ring_detData1.no_select_img"
        style="width: 715px; height: 225px; display: block; margin-top: -100px"
        alt
      />
    </div>
    <div class="container-imgbox">
      <img :src="ring_detData1.select_img" alt="图" />
      <div class="textbox">
        <div
          class="cell"
          v-for="(item, index) in ring_detData1.other_des"
          :key="index"
        >
          <div class="top">
            <span>{{ item.val }}</span>
            <span>{{ item.dw }}</span>
          </div>
          <div class="bottom">
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="info1">
      <div class="title">
        <span>董事长寄语</span>
      </div>
      <div class="container">
        <div class="left" v-html="ring_detData2.content">
          <!-- <div>
            <span>易尤特同志们，首先祝贺您选择投身于浙江易尤特科技股份有限公司，谨此我代表公司董事会、管理团队及全体员工，对您的加入表示热烈欢迎和真诚感谢！</span>
          </div>
          <div class="margin">
            <span>我们来自五湖四海，为共同的梦想和目标，相聚在一起，我们为成为易尤特的一员而感到骄傲。我们始终坚持“铁军建企、制度治企、科技强企、改革兴企、平台立企”和“以人为本、人才兴企”的发展管理理念，乐于奉献、爱岗敬业是我们不变的责任。我们杜绝九大行为即官僚主义、形式主义、搬弄是非、以权谋私、欺上瞒下、山头主义、阳奉阴违、擅作主张、甩手掌柜。坚持用铁的纪律要求领导、要求自己、要求员工；坚持真诚做人，踏实做事；坚持服务政府，服务企业，服务商户，服务百姓，坚持服务是我们易尤特人不断追求的企业文化根基。</span>
          </div>
          <div>
            <span>易尤特人在任何时候、任何地方，应该满怀感恩，感恩国家、感恩政府、感恩公司。易尤特给与我们事业发展的平台，给予我们展现自我、发展自我、实现自我的机会。为了民族复兴、为了国家发展、为了家庭幸福、为了个人成长，让我们怀着感恩的心，昂扬精神，努力工作，不懈奋斗，同修共富！</span>
          </div>-->
        </div>
        <div class="right">
          <img :src="ring_detData2.imgs" alt />
        </div>
      </div>
    </div>

    <div class="header-img" ref="box">
      <img class="poleft" @click="turnLeft" src="@/assets/leftus.jpg" alt />
      <div class="header-img-model" ref="imgmodel">
        <div
          :ref="`boxcell${index}`"
          class="header-img-model-son-box"
          v-for="(item, index) in ring_detData2.all_img"
          :key="index"
          :style="index == 0 ? 'margin-left:' + marginLeft + 'px' : ''"
        >
          <!-- <div class="header-img-model-son" > -->
          <!-- @click="changeTabs(index,item,item.id)" -->
          <img class="header-img-model-son-img" :src="item" alt />
          <!-- <div v-else></div>
            <span class="header-img-model-son-span">
              {{
              item.title
              }}
          </span>-->
          <!-- </div> -->
        </div>
      </div>
      <img class="poright" @click="turnRight" src="@/assets/rightus.jpg" alt />
    </div>

    <div class="qiyewenhua">
      <div class="title">
        <span>企业文化</span>
      </div>
      <div class="texts" v-html="ring_detDataAll_img3.content"></div>
    </div>

    <div class="imgs-box">
      <img
        :src="item"
        v-for="(item, index) in ring_detDataAll_img3.img"
        :key="index"
        alt
      />
    </div>
    <img :src="ring_detDataAll_img3.d_img" class="bgi" alt />
    <div class="tabs-1">
      <div
        class="cell-box"
        v-for="(item, index) in ring_detData3"
        :key="index"
        @click="changeRing_detData3(index)"
      >
        <div class="cell">
          <img
            :src="
              ring_detData3Current == index
                ? item.select_img
                : item.no_select_img
            "
            alt
          />
        </div>
        <span :style="ring_detData3Current == index ? 'color:#3255FA' : ''">{{
          item.type_title
        }}</span>
      </div>
    </div>
    <div class="zuzhijiagou">
      <div class="title">
        <span>组织架构</span>
      </div>
      <div v-html="ring_detData4.content"></div>
    </div>
    <div class="usbgi">
      <img :src="ring_detData4.d_imgs" alt />
    </div>

    <!-- 合作伙伴 -->
    <div class="friends">
      <div class="container">
        <div class="title">
          <div class="span">合作伙伴</div>
          <!-- <div class="line"></div>
          <div class="text">
            <span>以“小切口”构建“大场景” 职能部门多跨协同形成“大牵引”,通过“机制重塑、流程再造”形成管理运营闭环新模式</span>
          </div>-->
        </div>
      </div>

      <div class="cell-imgs">
        <!-- <div class="leftM"></div> -->
        <div
          class="imgs-boxs"
          ref="imgsBox"
          :style="{ transform: `translateX(${leftNum}px)` }"
        >
          <div class="cell" v-for="(item, index) in banner_list15" :key="index">
            <img
              :src="itemSon.ads_pic"
              v-for="(itemSon, indexSon) in item"
              :key="indexSon"
              alt
            />
          </div>
        </div>
        <!-- <div class="rightM"></div> -->
      </div>
      <!-- <div class="btn">
        <button>查看更多>></button>
      </div>-->
    </div>

    <div class="books">
      <div class="title">
        <span>电子手册</span>
      </div>
      <!-- <div class="bookBox">
        <div class="left">
          <img :src="leftbooksData.ads_pic" :style="{zIndex:-rightbooksData.length-1}" alt />
        </div>
        <div class="right">
          <img
            :src="item.src"
            v-for="(item,index) in rightbooksData"
            :style="{top:0,right:index*2+'px',zIndex:item.zIndex}"
            :key="index"
            alt
            :class="item.isF?'trans':'nTrans'"
            @click="changeBook(index)"
          />
        </div>
      </div> -->
      <div class="bookp">
        <div
          class="img-box"
          v-for="(item, index) in rightbooksData"
          :key="index"
          @click="toBookDetail(item)"
        >
          <img :src="item.ads_pic" alt="" />
          <span>{{item.ads_title}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
// import books from "@/components/books/books.vue";
// import List from "@/components/List/List.vue";
// import ScrollList from "@/components/ScrollList/ScrollList.vue";

import { ring_det, banner_list } from "@/api/api";
export default {
  components: {
    Header,
    // books
    // ScrollList,
  },
  data() {
    return {
      banner_list: [],
      banner_list15: [],
      banner_list16: [],
      oneData: {},
      twoData: {},
      threeData: {},
      fourData: {},
      ring_list: [],
      tabsCurrent: 0,
      tabsData: [
        { text: "集团简介" },
        { text: "董事长寄语" },
        { text: "企业文化" },
        { text: "组织架构" },
        { text: "合作伙伴" },
        { text: "电子手册" },
      ],
      tabsTopCurrent: 0,
      bannerCurrent: 0,
      topCurrent: "top",
      pFixed: false,
      marginLeft: 0,
      cellType: 1,
      cellData: [],
      titleTabsChecked: 0,
      ring_detData1: {},
      ring_detData2: {},
      ring_detData3: [],
      ring_detDataAll_img3: [],
      ring_detData3Current: 0,
      ring_detData4: {},
      fCurrent: "",
      leftbooksData: {},
      rightbooksData: [
        // { src: "", isF: false, zIndex: 0 },
        // { src: "", isF: false, zIndex: 1 },
        // { src: "", isF: false, zIndex: 2 },
        // { src: "", isF: false, zIndex: 3 }
      ],
      clickNum: false,
      leftNum: 0,
    };
  },
  mounted() {
    this.playLeftScroll();
    this.getbanner_list();
    this.getHb();
    this.getSc();
    this.getring_det1();
    this.getring_det2();
    this.getring_det3();
    this.getring_det4();
  },
  methods: {
    playLeftScroll() {
      let that = this;

      setInterval(() => {
        that.leftNum -= 1;
        if (that.leftNum < -310) {
          that.leftNum = -10;
          that.banner_list15.push(that.banner_list15.shift());
        }
      }, 10);
    },
    //播放视频
    playVideos(index) {
      let video = document.getElementById(`video${index}`);
      let plays = document.getElementById(`plays${index}`);

      video.play();
      // console.log("video", video);
      video.addEventListener("play", function () {
        // console.log("开始播放");
        plays.style.visibility = "hidden";
      });
      video.addEventListener(
        "ended",
        function () {
          // console.log("播放结束");
          plays.style.visibility = "visible";
        },
        false
      );
    },
    videoChange(index) {
      let video = document.getElementById(`video${index}`);
      let plays = document.getElementById(`plays${index}`);
      video.pause(); //暂停播放
      plays.style.visibility = "visible";
      // console.log("index",index)
    },
    getbanner_list() {
      banner_list(10).then((res) => {
        // console.log("banner_list", res);
        this.banner_list = res.data;
      });
    },
    //合作伙伴
    getHb() {
      banner_list(15, 2).then((res) => {
        // console.log("banner_list15", res);
        this.banner_list15 = res.data;
      });
    },
    //电子手册
    getSc() {
      banner_list(16).then((res) => {
        // console.log("booksData", res);
        let arr = [];
        //翻页电子书
        // for (let i = 0; i < res.data.length - 1; i++) {
        //   arr.push({
        //     id: res.data[i].id,
        //     src: res.data[i].ads_pic,
        //     isF: false,
        //     zIndex: i
        //   });
        // }
        // this.leftbooksData = res.data[res.data.length - 1];
        // this.rightbooksData = arr;
        //翻页电子书

        //排列电子书
        for (let i = res.data.length - 1; i + 1 > 0; i--) {
          arr.push(res.data[i]);
        }
        this.rightbooksData = arr;
      });
    },
    //查看电子书
    toBookDetail(item) {
      // console.log(item)
      window.open(item.pdf_url, "_black");
    },

    getring_det1() {
      let that = this;
      ring_det(1).then((res) => {
        that.ring_detData1 = res.data.list[0];
      });
    },
    getring_det2() {
      let that = this;
      ring_det(2).then((res) => {
        that.ring_detData2 = res.data.list[0];
      });
    },
    //企业文化
    getring_det3() {
      let that = this;
      ring_det(3).then((res) => {
        that.ring_detData3 = res.data.list;
        that.ring_detDataAll_img3 = {
          content: res.data.list[0].content,
          img: res.data.list[0].all_img,
          d_img: res.data.list[0].d_imgs,
        };
      });
    },
    changeRing_detData3(index) {
      let that = this;
      that.ring_detDataAll_img3 = {
        content: that.ring_detData3[index].content,
        img: that.ring_detData3[index].all_img,
        d_img: that.ring_detData3[index].d_imgs,
      };
      that.ring_detData3Current = index;
    },
    //组织结构
    getring_det4() {
      let that = this;
      ring_det(4).then((res) => {
        that.ring_detData4 = res.data.list[0];
      });
    },
    toDetail(item) {
      // let router = this.$router.resolve({
      //   path: "/aboutusDetail",
      //   query: { id: item.id },
      // });
      // window.open(router.href, "_blank");
      this.$router.push({
        path: "/aboutusDetail",
        query: { id: item.id },
      });
    },
    changeTabs(index) {
      let that = this;
      that.tabsCurrent = index;
      switch (index) {
        case 0:
          that.$el.querySelector(`.computer-box`).scrollIntoView({
            behavior: "smooth",
            block: "center", // start顶端对齐  center 中间对齐
          });
          break;
        case 1:
          that.$el.querySelector(`.info1`).scrollIntoView({
            behavior: "smooth",
            block: "center", // start顶端对齐  center 中间对齐
          });
          break;
        case 2:
          that.$el.querySelector(`.qiyewenhua`).scrollIntoView({
            behavior: "smooth",
            block: "center", // start顶端对齐  center 中间对齐
          });
          break;
        case 3:
          that.$el.querySelector(`.zuzhijiagou`).scrollIntoView({
            behavior: "smooth",
            block: "center", // start顶端对齐  center 中间对齐
          });
          break;
        case 4:
          that.$el.querySelector(`.friends`).scrollIntoView({
            behavior: "smooth",
            block: "center", // start顶端对齐  center 中间对齐
          });
          break;
        case 5:
          that.$el.querySelector(`.books`).scrollIntoView({
            behavior: "smooth",
            block: "center", // start顶端对齐  center 中间对齐
          });
          break;
      }
    },
    turnLeft() {
      let offsetWidth2 = this.$refs.boxcell0[0].offsetWidth; //不包括溢出的部分
      if (-this.marginLeft > 1) {
        this.marginLeft = this.marginLeft + offsetWidth2;
      }
    },
    turnRight() {
      let scrollwidth = this.$refs.imgmodel.scrollWidth; //包括溢出的部分
      let offsetWidth = this.$refs.box.offsetWidth; //不包括溢出的部分
      let offsetWidth2 = this.$refs.boxcell0[0].offsetWidth; //不包括溢出的部分
      // let boxscrollwidth = this.$refs["box"][0].scrollWidth; //包括溢出的部分
      // console.log("scrollwidth", scrollwidth);
      // console.log("offsetWidth", offsetWidth);
      // console.log("offsetWidth2", offsetWidth2);
      // console.log("boxscrollwidth",boxscrollwidth);

      if (scrollwidth - offsetWidth > -this.marginLeft) {
        this.marginLeft = this.marginLeft - offsetWidth2;
      }
    },
    changeBook(index) {
      let that = this;

      if (!that.clickNum) {
        that.clickNum = true;
        if (that.rightbooksData[index].isF) {
          that.rightbooksData[index].isF = false;
          setTimeout(() => {
            that.rightbooksData[index].zIndex = -(
              that.rightbooksData[index].zIndex - that.rightbooksData.length
            );
            that.clickNum = false;
          }, 1000);
        } else {
          that.rightbooksData[index].isF = true;
          setTimeout(() => {
            that.rightbooksData[index].zIndex = -(
              that.rightbooksData[index].zIndex - that.rightbooksData.length
            );
            that.clickNum = false;
          }, 1000);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mainpageboxs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 30px; */
}

.computer-box {
  width: 650px;
  height: 374px;
  position: relative;
  overflow: hidden;

  .Carousels {
    position: absolute;
    left: calc(50% - 245px);
    top: 20px;
    width: 490px;
    overflow: hidden;
    .CarouselsItem {
      position: relative;
      .plays {
        width: 60px;
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        z-index: 1;
      }
    }
  }

  .computer {
    display: block;
    width: 650px;
    height: 373px;
    // position: absolute;
    // top: 0;
    // left: 0;
  }
}

.header-bimg {
  width: 100%;
  height: 473px;
  background: #14508c;
}

.tabs {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  justify-content: center;
  .tabs-box {
    width: 55%;
    display: flex;
  }
  .tabs-span {
    display: inline-block;
    width: 80px;
    text-align: center;
    height: 60px;
    line-height: 60px;
    margin-right: 4%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2e2f34;
  }
}

.container-imgbox {
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    display: block;
  }

  .textbox {
    height: 100px;
    position: absolute;

    top: calc(50% - 50px);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .cell {
      .top {
        span:nth-child(1) {
          font-size: 78px;
          font-family: Bahnschrift;
          font-weight: 400;
          color: #ffffff;
        }

        span:nth-child(2) {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .bottom {
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}

.info1 {
  margin-top: 130px;
  width: 55%;

  .title {
    font-size: 29px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2e2f34;
    text-align: center;
    margin-bottom: 40px;
  }

  .container {
    display: flex;
    justify-content: space-between;

    .left {
      width: calc(100% - 300px);
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2e2f34;
      text-indent: 32px;

      .margin {
        margin: 30px 0;
      }
    }

    .right {
      width: 292px;

      img {
        width: 100%;
      }
    }
  }
}

.header-img {
  margin-top: 67px;
  width: 70%;
  display: flex;
  position: relative;

  .poleft {
    width: 48px;
    height: 48px;
    position: absolute;
    top: calc(50% - 24px);
    left: -50px;
    /* box-shadow: 0 0 4px #f0f0f0; */
  }

  .poright {
    width: 48px;
    height: 48px;
    position: absolute;
    top: calc(50% - 24px);
    right: -50px;
    /* box-shadow: 0 0 4px #f0f0f0; */
  }

  .header-img-model {
    width: 100%;
    display: flex;
    overflow-x: auto;
    overflow: hidden;
  }

  .header-img-model-son-box {
    /* 超出滚动的关键，没有它元素会自动缩小，不会滚动 */
    flex-shrink: 0;
    transition: all 0.6s;
    width: 25%;
    display: flex;
    justify-content: center;

    .header-img-model-son-img {
      display: block;
      width: 90%;
      background: #ffffff;
    }
  }

  .header-img-model-son {
    margin-bottom: 10px;
    margin-right: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  ::-webkit-scrollbar {
    /* 隐藏滚动条 */

    /* display: none; */
  }

  .header-img-model-son-span {
    width: 280px;
    text-align: center;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: sanMedium;
    position: absolute;
    top: calc(50% - 70px);
    left: calc(50% - 140px);
    color: #ffffff;
  }
}

.qiyewenhua {
  width: 45%;

  .title {
    font-size: 29px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2e2f34;
    text-align: center;
    margin: 150px 0 40px 0;
  }

  .texts {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2e2f34;
    text-indent: 32px;
    height: 142.5px;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.imgs-box {
  margin-top: 67px;
  margin-bottom: -70px;
  width: 42%;
  display: flex;
  justify-content: space-between;

  img {
    width: 32%;
    // height: 180px;
    display: block;
    background: #bfbfbf;
    border: 4px solid #ffffff;
  }

  z-index: 1;
}

.bgi {
  width: 100%;
  min-height: 160px;
  background: #bfbfbf;
}

.tabs-1 {
  margin-top: -44px;
  width: 700px;
  display: flex;
  justify-content: space-between;

  .cell-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cell {
      box-shadow: 0 0 4px #d3d3d3;
      width: 87px;
      height: 87px;
      background: #ffffff;
      border-radius: 50%;
      margin-bottom: 10px;

      img {
        width: 87px;
        height: 87px;
        border-radius: 50%;
        display: block;
      }
    }

    span {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2e2f34;
    }
  }

  .cell-box:hover span {
    color: #3255fa;
  }
}

.zuzhijiagou {
  margin-top: 150px;
  width: 60%;

  .title {
    text-align: center;
    font-size: 29px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2e2f34;
  }

  img {
    width: 100%;
  }
}

.usbgi {
  width: 100%;

  img {
    width: 100%;
  }
}

// 合作伙伴
.friends {
  padding: 100px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 60%;

    // background: #ffffff;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .span {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  .cell-imgs {
    width: 70%;
    margin: 97px 0 75px 0;
    overflow: hidden;
    position: relative;

    .leftM {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 20%;
      // filter: blur(20px);
      // background: linear-gradient(rgb(255, 255, 255),rgba(255, 255, 255, 0.2));
      // background: rgb(255, 255, 255);
      z-index: 1;
    }

    .rightM {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      width: 20%;
      height: 100%;
      background: #14508c;
    }

    // justify-content: space-between;
    .imgs-boxs {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;

      .cell {
        flex-shrink: 0;
        width: 300px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          // width: 96%;
          display: block;
          border: 1px solid #cbcbcb;
          margin: 6px 0;
          width: 285px;
          height: 123px;
        }
      }
    }
  }

  .btn {
    text-align: center;

    button {
      width: 171px;
      height: 49px;
      background: #3769e8;
      border-radius: 5px;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      border: none;
    }
  }
}

.books {
  width: 100%;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;

  .title {
    font-size: 29px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2e2f34;
    text-align: center;
    margin-bottom: 100px;
  }

  .bookBox {
    width: 60%;
    display: flex;

    .left {
      width: 50%;

      img {
        // position: absolute;
        width: 100%;
        display: block;
      }
    }

    .right {
      width: 50%;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        display: block;
      }

      .trans {
        opacity: 0.5;
        transition: all 2s;
        transform-origin: left;
        transform: rotateY(-180deg) !important;
      }

      .nTrans {
        opacity: 1;
        transition: all 2s;
        transform-origin: right;
        transform: rotateY(0) !important;
      }
    }
  }

  .bookp {
    width: 780px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    .img-box {
      width: 250px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      text-align: center;
      img {
        width: 250px;
        height: 350px;
        display: block;
        margin-bottom: 25px;
      }
      span {
        font-weight: 600;
      }
    }

    // img:nth-child(3n) {
    //   margin-right: 0;
    // }
  }
  ::-webkit-scrollbar {
    /* 隐藏滚动条 */

    // display: none;
  }
}
</style>