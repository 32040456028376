<template>
  <div class="productDetailpage">
    <div class style="height:70px"></div>
    <div class="container">
      <div class="left">
        <div class="imgbox">
          <img :src="bigImgUrl" alt />
        </div>
        <div class="bannerbox">
          <img class="leftImg" @click="toLeft" src="@/assets/leftus.jpg" alt />
          <div class="container-imgs" ref="cellbox">
            <div ref="cell" class="cell" :style="{ marginLeft: index == 0 ? `${marginLeft}px` : '', height: `${cellHeight}px` }"
              v-for="(item, index) in imgData" :key="index">
              <img @click="changeImg(index)" :class="currentImg == index ? 'checkClass' : ''" :src="item" alt />
            </div>
          </div>

          <img class="rightImg" @click="toRight" src="@/assets/rightus.jpg" alt />
        </div>
        <!-- <span class="payBtn" @click="toBuy(detailData.p_link)">点击购买</span> -->
      </div>
      <div class="right">
        <div class="title">
          <span>{{ detailData.title }}</span>
        </div>
        <div class="desc">
          <span v-html="detailData.desc"></span>
        </div>
        <div class="price">
          <span class="label">价格</span>
          <span class="desc">￥{{ detailData.price }}</span>
        </div>
        <div class="loca" v-for="(item, index) in detailData.other_desc" :key="index">
          <span class="label">{{ item.title }}</span>
          <span class="desc">{{ item.val }}</span>
        </div>
        <!-- <div class="loca">
          <span class="label">安装要求</span>
          <span class="desc">无</span>
        </div>
        <div class="loca">
          <span class="label">产品功能</span>
          <span class="desc">{{detailData.desc}}</span>
        </div>-->
        <div class="num">
          <span class="label">购买数量</span>
          <span class="edd" @click="edd">-</span>
          <span class="input">{{ carQuery.num }}</span>
          <span class="add" @click="add">+</span>
        </div>
        <div class="buyBtn">
          <button @click="toBuy(1)" class="buy">立即购买</button>
          <Poptip confirm title="确认加入购物车?" @on-ok="toCar(1)" ok-text="确定" cancel-text="取消">
            <button class="car">加入购物车</button>
          </Poptip>
        </div>
      </div>
      <!-- <div class="right" v-html="detailData.content"></div> -->
    </div>
    <div class="line">
      <!-- 华丽的分界线 -->
    </div>
    <div class="product-car" v-if="product_pairData.length > 0">
      <div class="title">
        <span>产品搭配</span>
      </div>
      <div class="produce-tuijian">
        <div class="left">
          <div class="cell">
            <img class="topimg" :src="detailData.imgs" alt />
            <span class="name">{{ detailData.title }}</span>
            <div class="priceBox">
              <span>￥{{ detailData.price }}</span>
            </div>
          </div>
          <div class="cell">
            <img class="addimg" src="@/assets/newImg/add.jpg" alt />
          </div>
          <!--  -->
          <template v-if="product_pairData.length > 0">
            <div class="cell" v-for="(item, index) in product_pairData" :key="index">
              <img class="topimg" src="@/assets/newImg/product.jpg" alt />
              <span class="name">{{ item.title }}</span>
              <div class="priceBox">
                <Checkbox size="small" v-model="product_pairData[index].isChecked"></Checkbox>
                <span>￥{{ item.price }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="right">
          <div class="top">
            <span>组合价：</span>
            <span>￥{{ priceAll }}</span>
          </div>
          <div class="bottom">
            <Poptip confirm title="确认加入购物车?" @on-ok="toCar(2)" ok-text="确定" cancel-text="取消">
              <button>加入购物车</button>
            </Poptip>

            <button @click="toBuy(2)">立即购买</button>
          </div>
        </div>
      </div>
    </div>
    <div class="produceDetail">
      <div class="title">
        <span>产品详情</span>
      </div>
      <div class v-html="detailData.content"></div>
    </div>
  </div>
</template>

<script>
import { udet, product_pair, paycar_add } from "@/api/api";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      id: 1,
      detailData: {},
      product_pairData: [],
      bigImgUrl: "",
      currentImg: 0,
      marginLeft: 0,
      imgData: [
        // { img: require("@/assets/newImg/product.jpg") },
        // { img: require("@/assets/newImg/product2.jpg") },
        // { img: require("@/assets/newImg/product3.jpg") }
      ],
      cellHeight: "",
      carQuery: {
        pid: "", //5	是	string	产品id
        num: 1 //是	string	数量
      }
    };
  },
  mounted() {
    // window.scrollTo(0,0)
  },
  activated() {
    this.carQuery.pid = this.$route.query.id;
    this.id = this.$route.query.id;
    this.getudet();
    this.getproduct_pair();
  },
  computed: {
    ...mapState(["carLen"]),
    priceAll() {
      let num = 0;
      let that = this;
      if (that.product_pairData.length > 0) {
        for (let i = 0; i < that.product_pairData.length; i++) {
          if (that.product_pairData[i].isChecked) {
            num += parseFloat(that.product_pairData[i].price);
          }
        }
      }

      return num;
    }
  },
  methods: {
    ...mapMutations(["setCarLen"]),
    //获取详情
    getudet() {
      let that = this;
      udet(that.id).then(res => {
        that.bigImgUrl = res.data.all_img[0];
        that.imgData = res.data.all_img;
        that.detailData = res.data;
      });
    },
    //获取产品搭配
    getproduct_pair() {
      let that = this;
      product_pair(that.id).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].isChecked = false;
        }
        that.product_pairData = res.data;
      });
    },
    toBuy(num) {
      let that = this;
      let data = [];
      switch (num) {
        case 1:
          data.push({
            car_id: 0,
            pid: that.carQuery.pid,
            num: that.carQuery.num
          });
          break;
        case 2:
          data.push({
            car_id: 0,
            pid: that.carQuery.pid,
            num: that.carQuery.num
          });
          if (that.product_pairData.length > 0) {
            for (let i = 0; i < that.product_pairData.length; i++) {
              if (that.product_pairData[i].isChecked) {
                data.push({
                  car_id: 0,
                  pid: that.product_pairData[i].id,
                  num: 1
                });
              }
            }
          }
          break;
      }

      that.$router.push({
        path: "/createOrder",
        query: { buy_str: JSON.stringify(data) }
      });
    },
    //加入购物车
    toCar(num) {
      let that = this;
      let data = [];
      switch (num) {
        case 1:
          data.push({
            car_id: 0,
            pid: that.carQuery.pid,
            num: that.carQuery.num
          });
          break;
        case 2:
          data.push({
            car_id: 0,
            pid: that.carQuery.pid,
            num: that.carQuery.num
          });
          if (that.product_pairData.length > 0) {
            for (let i = 0; i < that.product_pairData.length; i++) {
              if (that.product_pairData[i].isChecked) {
                data.push({
                  car_id: 0,
                  pid: that.product_pairData[i].id,
                  num: 1
                });
              }
            }
          }
          break;
      }
      paycar_add({ str_arr: JSON.stringify(data) })
        .then(res => {
          if (res.code == 200) {
            that.$Message.success(res.message);
            that.$store.commit("setCarLen", that.carLen + 1);
          } else {
            that.$Message.error(res.message);
          }
        })
        .catch(err => {
          that.$Message.error(err);
        });
    },
    changeImg(index) {
      let height = this.$refs.cell[0].offsetHeight; //不包括溢出的部分
      this.cellHeight = height;
      this.bigImgUrl = this.imgData[index];
      this.currentImg = index;
    },
    toLeft() {
      let offsetWidth = this.$refs.cellbox.offsetWidth; //不包括溢出的部分F
      let cell = this.$refs.cell[0].offsetWidth; //不包括溢出的部分

      if (-this.marginLeft > 1) {
        this.marginLeft = this.marginLeft + offsetWidth;
      }
      // this.currentImg = this.currentImg - 1;
    },
    toRight() {
      let scrollwidth = this.$refs.cellbox.scrollWidth; //包括溢出的部分
      let offsetWidth = this.$refs.cellbox.offsetWidth; //不包括溢出的部分
      let cell = this.$refs.cell[0].offsetWidth; //不包括溢出的部分
      // let boxscrollwidth = this.$refs["box"][0].scrollWidth; //包括溢出的部分
      // console.log("scrollwidth", scrollwidth);
      // console.log("offsetWidth", offsetWidth);
      // console.log("boxscrollwidth",boxscrollwidth);

      if (scrollwidth - offsetWidth > -this.marginLeft) {
        this.marginLeft = this.marginLeft - offsetWidth;
      }
      // this.currentImg = this.currentImg + 1;
    },
    edd() {
      if (this.carQuery.num > 1) {
        this.carQuery.num = this.carQuery.num - 1;
      }
    },
    add() {
      this.carQuery.num = this.carQuery.num + 1;
    }
  }
};
</script>

<style lang="less" scoped>
.productDetailpage {
  width: 100%;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  .container {
    width: 60%;
    display: flex;
    justify-content: space-between;

    .left {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .imgbox {
        width: 80%;

        img {
          width: 100%;
          display: block;
        }
      }

      .bannerbox {
        position: relative;
        width: 100%;
        margin-top: 20px;

        .leftImg {
          position: absolute;
          left: -35px;
          top: calc(50% - 15px);
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }

        .rightImg {
          position: absolute;
          right: -35px;
          top: calc(50% - 15px);
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }

        .container-imgs {
          display: flex;
          flex-wrap: nowrap;
          overflow: hidden;
          width: 100%;

          .cell {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            img {
              width: 80%;
              display: block;
              background: #f1f1f1;
              transition: all 0.5s;
            }

            .checkClass {
              width: 100% !important;
              border: 1px solid #1c4492;
            }
          }
        }
      }
    }

    .right {
      width: 55%;

      .title {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #3a3a3a;
      }

      .desc {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3a3a3a;
        margin: 16px 0 36px 0;
      }

      .price {
        padding: 10px;
        height: 79px;
        display: flex;
        align-items: center;
        background: #f5f5f5;
        margin-bottom: 49px;

        .label {
          display: inline-block;
          width: 50px;
          text-align: justify;
          text-align-last: justify;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3a3a3a;
          margin-right: 26px;
        }

        .desc {
          display: inline-block;
          font-size: 23px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #1c4492;
          margin: 0;
        }
      }

      .loca {
        display: flex;
        margin-bottom: 26px;

        .label {
          flex-shrink: 0;
          display: inline-block;
          width: 140px;
          // text-align: justify;
          // text-align-last: justify;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3a3a3a;
          margin-right: 26px;
        }

        .desc {
          display: inline-block;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3a3a3a;
          margin: 0;
        }
      }

      .num {
        display: flex;
        align-items: center;

        .label {
          flex-shrink: 0;
          display: inline-block;
          width: 50px;
          text-align: justify;
          text-align-last: justify;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3a3a3a;
          margin-right: 26px;
        }

        .edd,
        .add {
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33px;
          height: 31px;
          background: #c6c6c6;
        }

        .input {
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 31px;
          background: #ffffff;
          border-top: 1px solid #c6c6c6;
          border-bottom: 1px solid #c6c6c6;
        }
      }

      .buyBtn {
        margin-top: 50px;

        .buy {
          width: 122px;
          height: 41px;
          background: #345eb9;
          border-radius: 2px;
          margin-right: 19px;
          border: none;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }

        .car {
          border: none;
          width: 132px;
          height: 41px;
          background: #ff790d;
          border-radius: 2px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: #dddddd;
    margin: 70px 0 15px 0;
  }

  .product-car {
    width: 60%;

    .title {
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #345eb9;

      span {
        display: inline-block;
        padding: 2px 10px;
        border-bottom: 1px solid #345eb9;
      }
    }

    .produce-tuijian {
      padding: 40px 0;
      display: flex;
      border-bottom: 1px solid #dddddd;

      .left {
        width: 80%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: scroll;

        .cell {
          flex-shrink: 0;
          width: 140px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .topimg {
            width: 80%;
          }

          .name {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3a3a3a;
            margin: 8px 0 5px 0;
          }

          .priceBox {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ca151d;
          }
        }
      }

      .right {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
          span:nth-child(1) {
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3a3a3a;
          }

          span:nth-child(2) {
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ca151d;
          }
        }

        .bottom {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          button:nth-child(1) {
            width: 120px;
            height: 30px;
            background: #ff790d;
            border-radius: 2px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            border: none;
            margin: 15px 0;
          }

          button:nth-child(2) {
            width: 120px;
            height: 30px;
            background: #345eb9;
            border-radius: 2px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            border: none;
          }
        }
      }
    }
  }

  .produceDetail {
    width: 60%;
    margin-top: 5px;

    .title {
      padding-left: 14px;
      height: 54px;
      line-height: 54px;
      background: #f5f5f5;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #3a3a3a;
    }
  }
}
</style>