<template>
  <div class="loginpages">
    <div class="container-box">
      <div style="height:70px;width:100%;"></div>
      <div class="log-img">
        <img src="../../assets/loginlog.png" alt />
      </div>
      <div class="log-text">
        <span>重置密码</span>
      </div>

      <div class="login-form">
        <div style="margin-bottom: 30px">
          <Input size="large" v-model="formItem.phone" placeholder="请输入手机号" maxlength="11" />
        </div>
        <div style="margin-bottom: 30px">
          <Input size="large" v-model="formItem.pwd" placeholder="新密码" />
        </div>
        <div style="margin-bottom: 30px">
          <Input size="large" v-model="formItem.pwd_two" placeholder="确认新密码" />
        </div>
        <div style="display: flex;">
          <Input size="large" v-model="formItem.msg_code" placeholder="验证码" />
          <Button
            size="large"
            type="primary"
            style="margin-left: 16px"
            :style="
              countDownText == '获取验证码' || countDownText == '重新获取'
                ? 'background-color: #1d4493'
                : 'background-color:#DCDCDC;color:#FFFFFF;border:0;'
            "
            @click="get_codes"
          >{{ countDownText }}</Button>
        </div>
      </div>

      <div style="width: 465px">
        <Button
          size="large"
          type="primary"
          style="background-color: #1d4493"
          long
          @click="reset_pwd"
        >确认重置</Button>
      </div>
      <!-- <div class="more">
        <div>
          <span class="hovers" @click="toAdd">立即注册</span>
        </div>
        <div>
          <span style="margin-right:26px" class="hovers" @click="toQuery">修改密码</span>
          <span class="hovers">忘记密码</span>
        </div>
      </div>-->
      <!-- <div class="desc">
        <span>注册或点击登录代表您同意《易尤特官网网络服务使用协议》</span>
      </div>-->
    </div>
  </div>
</template>

<script>
import { get_code, reset_pwd } from "@/api/api";
export default {
  data() {
    return {
      current: 1,
      formItem: {
        phone: "",
        msg_code: "",
        pwd: "",
        pwd_two: ""
      },
      countDownText: "获取验证码"
    };
  },
  created() {},
  mounted() {
    let that = this;
    document.onkeydown = function(e) {
      if (e.keyCode == 13) {
        that.toLogin();
      }
    };
  },

  methods: {
    //获取验证码
    get_codes() {
      let that = this;
      if (!that.formItem.phone) {
        that.$Message.error("未输入手机号码");
        return false;
      }
      if (
        that.countDownText == "重新获取" ||
        that.countDownText == "获取验证码"
      ) {
        get_code({ phone: that.formItem.phone, type: 3 }).then(res => {
          if (res.code == 200) {
            that.$Message.success(res.message);
          } else {
            that.$Message.error(res.message);
          }
        });
        that.countDownText = 60;
        let time = setInterval(function() {
          that.countDownText = that.countDownText - 1;
          if (that.countDownText < 1) {
            clearInterval(time);
            that.countDownText = "重新获取";
          }
        }, 1000);
      }
    },
    reset_pwd() {
      let that = this;
      if (!that.formItem.phone) {
        that.$Message.error("未输入手机号码");
        return false;
      }
      if (!that.formItem.pwd) {
        that.$Message.error("请输入新密码");
        return false;
      }
      if (!that.formItem.pwd_two) {
        that.$Message.error("请确认新密码");
        return false;
      }
      if (!that.formItem.msg_code) {
        that.$Message.error("未输入验证码");
        return false;
      }

      reset_pwd(that.formItem).then(res => {
        if (res.code == 200) {
          that.$Message.success("修改成功");
          setTimeout(() => {
            that.$router.back();
          }, 2000);
        } else if (res.code == 400) {
          that.$Message.error(res.message);
        }
      });
    },
    //跳转到个人信息
    toMine() {
      let that = this;
      that.$parent.isShow = false;
      that.$parent.getBanner_list("", "", ""); //
      that.$router.push({ path: "/mine" });
    }
  }
};
</script>

<style scoped>
.hovers:hover {
  cursor: pointer;
}
.loginpages {
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
}

.headerbox {
  width: 100%;
  display: flex;
  background: #1c4492;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 9999;
}
.tabChecked {
  border-bottom: 1px solid #ffffff;
  color: #2f4373;
}
.header-bar {
  /* background: #ffffff; */
  /* box-shadow: 0 0 5px #adadad; */
  overflow: hidden;
  color: #494949;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-not-box-right {
  width: 70%;
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-bar-span {
  display: flex;
  align-items: center;
  font-family: sanNormal;
  color: #ffffff;
  font-size: 1.21rem;
}
.header-bar-span:hover {
  cursor: pointer;
}
.goin {
  display: inline-block;
  text-align: center;

  color: #ffffff;
  transition: all 0.5s;

  width: 152px;
  height: 55px;
  display: flex;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 5px;
}
.container-box {
  margin-top: 80px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.log-img {
}
.log-img img {
  display: block;
  width: 130px;
}
.log-text {
  text-align: center;
  font-size: 35px;
  font-family: sanMedium;
  color: black;
  margin: 30px 0;
}

.login-tabs span {
  display: inline-block;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  font-family: sanRegular;
  color: rgb(134, 134, 134);
  font-size: 16px;
  border-bottom: 3px solid #cecece;
}
.checkedTab {
  color: black !important;
  border-bottom: 3px solid #104c8b !important;
}
.login-form {
  width: 465px;
  padding: 40px 0;
}
.more {
  margin: 30px 0;
  width: 465px;
  font-size: 18px;
  font-weight: 400;
  color: #328fff;
  display: flex;
  justify-content: space-between;
}
.desc {
  font-size: 16px;
  font-weight: 400;
  color: #b1b1b1;
}
</style>