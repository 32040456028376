<template>
  <div class="aboutUs-detail">
    <div class="aboutUs-detail-container" v-html="container"></div>
  </div>
</template>

<script>
import { ring_det } from "@/api/api";
export default {
  data() {
    return {
      id: "",
      container: "",
    };
  },
  mounted() {},
  activated() {
    let id = this.$route.query.id;
    this.id = id;
    this.getring_det();
  },
  methods: {
    //获取头部分类
    getring_det() {
      let that = this;
      ring_det(this.id).then((res) => {
        // console.log("container", res);
        that.container = res.data.content;
      });
    },
  },
};
</script>

<style>
.aboutUs-detail {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.aboutUs-detail-container {
  width: 80%;
}
</style>