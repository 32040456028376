<template>
  <div class="myOrder">
    <div class="container">
      <div style="height:70px;"></div>
      <div class="title">
        <span>我的订单</span>
      </div>
      <div class="tabs">
        <span @click="changeTab(index)" class="cell" :style="tabCurrent==index?'color:#ffffff;background:#1C4492':''"
          v-for="(item,index) in tabsData" :key="index">{{ item.text }}</span>
      </div>
      <div class="table-title">
        <span class="cell1">商品</span>

        <span class="cell2">单价/元</span>

        <span class="cell3">数量</span>

        <span class="cell4">状态</span>

        <span class="cell5">实付款/元</span>

        <span class="cell6">订单状态及操作</span>
      </div>
      <div class="order-list" v-if="orderList.length > 0">
        <div class="cells" @click="toDetail(item)" v-for="(item, index) in orderList" :key="index">
          <div class="top">
            <div class="left">
              <span>{{ item.order_time }}</span>
              <span>订单号：</span>
              <span>{{ item.order_code }}</span>
            </div>
            <div class="right">
              <span v-if="item.order_state == 1">待支付</span>
              <span v-else-if="item.order_state == 2">待发货</span>
              <span v-else-if="item.order_state == 3">配送中</span>
              <span v-else-if="item.order_state == 4">已收货</span>
              <span v-else-if="item.order_state == 5">已取消</span>
              <span v-else-if="item.order_state == 6">已过期</span>
              <span v-else-if="item.order_state == 7">已退款</span>
            </div>
          </div>
          <div class="bottom">
            <div class="left">
              <div class="box" v-for="(itemSon, indexSon) in item.son_order" :key="indexSon">
                <div class="cell1">
                  <img :src="itemSon.imgs" alt />
                  <span>{{ itemSon.title }}</span>
                </div>
                <div class="cell2">
                  <span>￥{{ itemSon.order_pay_money }}</span>
                </div>
                <div class="cell3">
                  <span>{{ itemSon.order_num }}</span>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="cell4">
                <span v-if="item.order_state == 1">待支付</span>
                <span v-else-if="item.order_state == 2">待发货</span>
                <span v-else-if="item.order_state == 3">配送中</span>
                <span v-else-if="item.order_state == 4">已收货</span>
                <span v-else-if="item.order_state == 5">已取消</span>
                <span v-else-if="item.order_state == 6">已过期</span>
                <span v-else-if="item.order_state == 7">已退款</span>
              </div>
              <div class="cell5">
                <span>￥{{ item.order_price }}</span>
                <span>{{ "微信支付" }}</span>
              </div>
              <div class="cell6">
                <!-- <span
                  v-if="item.order_state==4||item.order_state==5"
                  @click="deleteOrder(item)"
                >删除订单</span>-->
                <span @click.stop="deleteOrder(item)">删除订单</span>
                <span v-if="item.order_state == 1" @click.stop="cancelOrder(item)">订单取消</span>
                <span v-if="item.order_state == 1" @click.stop="payOrder(item)">订单支付</span>
                <!-- -->
                <span v-if="item.order_state == 2" @click.stop="tuiKOrder(item)">申请退款</span>
                <!-- @click.stop="tuiKOrderDetail(item)" -->
                <span v-if="item.order_state == 7" @click.stop="toDetail(item)">退款详情</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-list" style="text-align:center;" v-else>
        <span>暂无订单..</span>
      </div>
      <div style="margin-top: 20px; display: flex; justify-content: right">
        <Page :total="total" :current="pages.page" show-total @on-change="pagesChange" />
      </div>
    </div>
    <Modal title="申请退款" v-model="modal8" width="400" :mask-closable="false" @on-ok="cancelOK">
      <Input v-model="tuikQuery.retrun_why" placeholder="请填写退款事由.." />
    </Modal>
  </div>
</template>

<script>
import { my_order_list, del_order, esc_order, return_money } from "@/api/api";
export default {
  data() {
    return {
      tabCurrent: 0,
      tabsData: [
        {
          text: "全部订单"
        },
        {
          text: "待付款"
        },
        {
          text: "待收货"
        },
        {
          text: "已完成"
        },
        {
          text: "已取消"
        }
      ],
      orderList: [],
      pages: {
        page: 1,
        type: 0,
        pz: 10
      },
      total: 0,
      modal8: false,
      tuikQuery: {
        order_id: "", //		是	string	订单id
        retrun_why: "" //		是	string	退款原因
      }
    };
  },
  activated() {
    this.getmy_order_list();
  },
  methods: {
    //删除订单
    deleteOrder(item) {
      let that = this;
      that.$Modal.confirm({
        title: "提示!",
        content: "确认删除该订单？",
        onOk: () => {
          del_order(item.order_id).then(res => {
            // console.log("dede", res);
            if (res.code == 200) {
              that.$message.success(res.message);
            }
            that.getmy_order_list();
          });
        },
        onCancel: () => {
          // this.$Message.info("Clicked cancel");
        }
      });
    },
    //取消订单
    cancelOrder(item) {
      let that = this;
      that.$Modal.confirm({
        title: "提示!",
        content: "确认取消该订单？",
        onOk: () => {
          esc_order(item.order_id).then(res => {
            // console.log("dede", res);
            if (res.code == 200) {
              that.$message.success(res.message);
            }
            that.getmy_order_list();
          });
        },
        onCancel: () => {
          // this.$Message.info("Clicked cancel");
        }
      });
    },
    //支付订单
    payOrder(item) {
      this.$router.push({ path: "/pay", query: { order_id: item.order_id } });
    },
    //申请退款
    tuiKOrder(item) {
      let that = this;
      // console.log("item", item)
      that.tuikQuery.order_id = item.order_id;
      that.modal8 = true;
    },
    //确认申请退款
    cancelOK() {
      let that = this;
      // console.log("that.tuikQuery", that.tuikQuery)
      if (that.tuikQuery.retrun_why == "") {
        that.$Message.error("请填写退款理由");
        return false;
      }
      // console.log("that.tuikQuery", that.tuikQuery)
      return_money(that.tuikQuery).then(res => {
        // console.log("dede", res);

        if (res.code == 200) {
          that.$Message.success(res.message);
          that.getmy_order_list();
          that.modal8 = false;
        }
      });
    },
    //退款详情
    tuiKOrderDetail(item) { },
    getmy_order_list() {
      let that = this;
      my_order_list(that.pages).then(res => {
        // console.log(res);
        that.orderList = res.data.list;
        that.total = res.data.total;
      });
    },
    toDetail(item) {
      this.$router.push({
        path: "/orderDetail",
        query: { order_id: item.order_id }
      });
    },
    changeTab(index) {
      this.tabCurrent = index;
      this.pages.type = index;
      this.getmy_order_list();
    },
    pagesChange(num) {
      this.pages.page = num;
    }
  }
};
</script>

<style lang="less" scoped>
.myOrder {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;

  .container {
    width: 60%;

    .title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #282828;
      padding: 17px 0;
      border-bottom: 1px solid #dedede;
    }

    .tabs {
      padding: 20px 0;

      .cell {
        display: inline-block;
        padding: 5px 16px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        background: #f2f2f2;
        margin-right: 13px;
      }
    }

    .table-title {
      margin-bottom: 24px;
      padding: 11px 0;
      background: #fafafa;

      span {
        height: 18px;
        line-height: 18px;
        display: inline-block;
        text-align: center;
        border-right: 1px solid #dedede;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }

      .cell1 {
        width: 40%;
      }

      .cell2 {
        width: 10%;
      }

      .cell3 {
        width: 10%;
      }

      .cell4 {
        width: 10%;
      }

      .cell5 {
        width: 10%;
      }

      .cell6 {
        width: 20%;
        border-right: none;
      }
    }

    .order-list {
      .cells {
        margin-bottom: 20px;

        .top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 13px;
          padding-right: 15px;
        }

        .bottom {
          display: flex;
          border-top: 1px solid #dedede;
          border-bottom: 1px solid #dedede;

          .left {
            width: 60%;

            .box {
              width: 100%;
              display: flex;
              border-bottom: 1px solid #dedede;

              .cell1 {
                display: flex;
                align-items: center;
                height: 120px;
                width: 66.7%;
                border-right: 1px solid #dedede;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #3a3a3a;

                img {
                  display: block;
                  margin-right: 10px;
                  width: 100px;
                  height: 100px;
                }
              }

              .cell2 {
                height: 120px;
                width: 16.65%;
                border-right: 1px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #aaaaaa;
              }

              .cell3 {
                width: 16.65%;
                height: 120px;
                border-right: 1px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #aaaaaa;
              }
            }

            .box:last-child {
              border-bottom: none;
            }
          }

          .right {
            display: flex;
            width: 40%;

            .cell4 {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25%;
              border-right: 1px solid #dedede;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
            }

            .cell5 {
              width: 25%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #dedede;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #aaaaaa;
            }

            .cell6 {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
            }

            .cell6 span:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>